import './reDynamicPath'
import '../assets/sass/web/_snb.scss'
import lozad from 'lozad'
import adImpressionService from './services/AdImpressionService';
import ViewInApp from '@quikr/view-in-app';
import { isSet, getterSetter, showSpinner, hideSpinner, capitalizeFirstLetter, setCity, resumeAd, intentType, connectionCheck, cloneObj, currencyFilter, scrollElmVert, headerDropDown, getTinyMoneyFormat, detectLocations, _vapTplRenderPromiss, delegate, quickLinks } from './utils/common'
import { searchActionService } from './services/searchActionService'
import { getLoggedInUser } from './loginService'
import { setCookie, getCookie } from './services/cookieService'
import snbFiltersConstants from './snb/constants/snbFilterConstants'
import { gtmSnbfilter, snbGtm, eventTriggerQH, eventTriggeranalatyic } from './services/gtmService'
import { addEvent, addPageView } from './utils/gaService'
import { getSnbTabPath, getUrlSearchObj, setSnbUrl, setH1, setitleH1, getQueryVariables } from './snb/services/snbUrlService'
import { getFilterFromPath } from './snb/services/snbInitService'
import { MultiAutosuggest } from './snb/services/multiAutosuggest'
import { getLocalityAutoSuggest, trackPremiumAds } from './snb/services/snbDataService'
import { mobileapplyGtm } from './services/msiteGtmService'
import { setRecentSearch, resentSearch, searchSuggest } from './services/recentSearchService'
import { POIFilterComponent } from './snb/components';
import { popup, submitLeadFn } from './services/qhmrSnBPopUpService';
import appDnlModalController from './controller/appDnlModalController';
import MarketTalkPopupController from './controller/MarketTalkPopupController';

getLoggedInUser.then((result) => {
    if (result && result.data && result.data.user) {
        getterSetter.set('loginData', result);
        getterSetter.set('loginstatus', true);
    } else {
        getterSetter.set('loginstatus', false);
    }
})
window.snbFilterController = function () {
    var snbFilterController = {};
    var locality_names = [];
    var pageLoadGa = false;
    snbFilterController.geoLocation = false;
    var budgetFlagGa = 0, areaFlagGa = 0;
    var defaultViewType = snbFiltersConstants.defaultView;
    snbFilterController.apiReloadMap = {};
    snbFilterController.filterTemplateRendered = false;
    snbFilterController.toppoi = [];
    snbFilterController.tileViewType = defaultViewType;
    snbFilterController.searchAction = searchActionService.getAction();
    snbFilterController.filters_all = snbFiltersConstants;
    snbFilterController.filtersLabel = {};
    snbFilterController.size = 32;
    snbFilterController.mapViewLocalities = {};
    snbFilterController.mapViewLocalities.ad_locality = [];
    snbFilterController.mapViewLocalities.ad_locids = [];
    snbFilterController.autoFitMap = false;
    // snbFilterController.filter.data.latitude=0;
    // snbFilterController.filter.data.longitude=0;
    var queryString;
    snbFilterController.zoomChange = false;
    snbFilterController.autoFitMap = false;
    // Hide Header on on scroll down
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var counter = 1;
    var isCovidCity;
    function updateMoreLabel() {
        var moreCategories = [{ amenities: 'Amenities' }, { posted_date: 'Posted On' }, { furnishing: 'Furnishing' }, {occupancy: 'Occupancy'}];
        var str = '';
        for (var i = 0; i < moreCategories.length; i++) {
            for (var key in moreCategories[i]) {
                var filterObj = snbFilterController.filter.data[key];
                if (filterObj && filterObj.length > 0) {
                    str = (str === '') ? (moreCategories[i][key]) : (str + ', ' + moreCategories[i][key]);
                }
            }
        }
        snbFilterController.filtersLabel.moreFiltersLabel = str;
    };

    function updateFiltersLabel(filterType) {
        var filterTypeForFilter = filterType === 'no_of_rooms' ? 'bedrooms' : filterType;
        var filterObj = snbFilterController.filter.data[filterTypeForFilter];
        if (filterObj.length) {
            var filterAllTypeObj = (snbFilterController.filter.data.type != 'brokers') ? snbFilterController.filters_all[snbFilterController.filter.data.cat][filterType] : snbFilterController.filters_all[snbFilterController.filter.data.type][filterType];
            var str = '';
            var len = typeof filterObj === "string" ? 1 : filterObj.length;
            for (var i = 0; i < len; i++) {
                for (var j = 0; j < filterAllTypeObj.length; j++) {
                    var selectedValue = typeof filterObj === "string" ? filterObj : filterObj[i];
                    if (filterAllTypeObj[j].api_value === selectedValue || filterAllTypeObj[j].value.toLowerCase() === selectedValue) {
                        str = (str === '') ? (filterAllTypeObj[j].value) : (str + ', ' + filterAllTypeObj[j].value);
                    }
                }
            }
            snbFilterController.filtersLabel[filterType] = str;
        }
        else {
            snbFilterController.filtersLabel[filterType] = '';
        }
    }

    snbFilterController.setFilter = function () {
        var query_obj = getUrlSearchObj('q');
        var ampredirect = getUrlSearchObj('ampredirect');

        snbFilterController.filter = {
            data: getFilterFromPath()
        }
        if (!snbFilterController.filter.data) {
            snbFilterController.filter.data = {};
        }
        if (ampredirect && ampredirect === 'filter') {
            snbFilterController.showHideFilterMobile()
        }
        if (query_obj && query_obj.posted_date) {
            snbFilterController.filter.data.posted_date = query_obj.posted_date;
        }
        if (query_obj && query_obj.amenities) {
            snbFilterController.filter.data.amenities = query_obj.amenities;
        }
        if (query_obj && query_obj.furnishingStatus) {
            snbFilterController.filter.data.furnishingStatus = query_obj.furnishingStatus;
        }
        if (query_obj && query_obj.clusterCity) {
            snbFilterController.filter.data.clusterCity = query_obj.clusterCity;
        }
        if (query_obj && query_obj.sortby) {
            snbFilterController.filter.data.sortby = query_obj.sortby;
        }
        if (query_obj && query_obj.furnishing) {
            snbFilterController.filter.data.furnishing = query_obj.furnishing;
        }
        if (query_obj && query_obj.possession) {
            snbFilterController.filter.data.possession = query_obj.possession;
        }
        if (query_obj && query_obj.occupancy) {
            snbFilterController.filter.data.occupancy = query_obj.occupancy;
        }
        if (query_obj && query_obj.ad_locality) {
            snbFilterController.filter.data.ad_locality = query_obj.ad_locality;
            snbFilterController.filter.data.ad_locids = query_obj.ad_locids;
            snbFilterController.filter.data.ad_loccitynames = query_obj.ad_loccitynames;
            snbFilterController.mapViewLocalities.ad_locality = [];
            snbFilterController.mapViewLocalities.ad_locids = [];
        }

        // Places of interest
        if (query_obj && query_obj.poiIds) {
            snbFilterController.filter.data.poiIds = query_obj.poiIds;
            snbFilterController.filter.data.poiNames = query_obj.poiNames;
        }

        if (query_obj && query_obj.available_from) {
            snbFilterController.filter.data.available_from = query_obj.available_from;
        } else if (query_obj && query_obj.possession && query_obj.possession == "Ready to move") {
            snbFilterController.filter.data.available_from = 0;
        } else {
            snbFilterController.filter.data.available_from = '';
        }

        if (locality_names.length > 0) {
            if (!snbFilterController.filter.data.ad_locality) {
                snbFilterController.filter.data.ad_locality = [];
                snbFilterController.filter.data.ad_locids = [];
                snbFilterController.filter.data.ad_loccitynames = [];
            }
            locality_names.forEach(function (locality_name) {
                if (snbFilterController.filter.data.ad_locality.indexOf(locality_name) === -1) {
                    snbFilterController.filter.data.ad_locality.push(locality_name);
                }
            });
            if (snbFilterController.filter.data.loc_id) {
                snbFilterController.filter.data.ad_locids.push(snbFilterController.filter.data.loc_id);
            }
            snbFilterController.mapViewLocalities.ad_locality = [];
            snbFilterController.mapViewLocalities.ad_locids = [];
            snbFilterController.mapViewLocalities.ad_loccitynames = [];
            snbFilterController.filter.data.loc = '';
            snbFilterController.filter.data.loc_id = '';
            locality_names = [];
        }
        if (query_obj && query_obj.gender) {
            snbFilterController.filter.data.gender = query_obj.gender;
        }
        if (query_obj && query_obj.isReraApproved) {
            snbFilterController.filter.data.isReraApproved = query_obj.isReraApproved;
        }
        if (query_obj && query_obj.hasCovidMeasures) {
            snbFilterController.filter.data.hasCovidMeasures = query_obj.hasCovidMeasures;
        }
        if (!snbFilterController.filter.data.amenities || !snbFilterController.filter.data.amenities.length) {
            snbFilterController.filter.data.amenities = [];
        }
        if (!snbFilterController.filter.data.occupancy || !snbFilterController.filter.data.occupancy.length) {
            snbFilterController.filter.data.occupancy = [];
        }
        if (!snbFilterController.filter.data.furnishingStatus || !snbFilterController.filter.data.furnishingStatus.length) {
            snbFilterController.filter.data.furnishingStatus = [];
        }
        if (!snbFilterController.filter.data.clusterCity || !snbFilterController.filter.data.clusterCity.length) {
            snbFilterController.filter.data.clusterCity = [];
        }
        if (snbFilterController.filter.data.clusterCity && !snbFilterController.filter.data.clusterCity.length > 0) {
            var clusterCityList = (isSet(globals.clusterCities)) ? JSON.parse(atob(globals.clusterCities)) : {};
            if (clusterCityList && Object.keys(clusterCityList).length > 0) {
                var isClusterSet = getCookie('isCluster');
                for (var x in clusterCityList) {
                    if (isSet(isClusterSet) && isClusterSet == 'true') {
                        snbFilterController.filter.data.clusterCity.push(x);
                    } else {
                        if (snbFilterController.filter.data.c_id == x) {
                            snbFilterController.filter.data.clusterCity.push(x);
                            break;
                        }
                    }
                }
            }
            else {
                snbFilterController.filter.data.clusterCity = [];
            }
        }
        if (!snbFilterController.filter.data.sortby || !snbFilterController.filter.data.sortby.length) {
            snbFilterController.filter.data.sortby = [];
        }
        if (!snbFilterController.filter.data.projectstatus || !snbFilterController.filter.data.projectstatus.length) {
            snbFilterController.filter.data.projectstatus = '';
        }
        if (!snbFilterController.filter.data.furnishing || !snbFilterController.filter.data.furnishing.length) {
            snbFilterController.filter.data.furnishing = [];
        }
        if (!snbFilterController.filter.data.bedrooms || !snbFilterController.filter.data.bedrooms.length) {
            snbFilterController.filter.data.bedrooms = [];
        }
        snbFilterController.filter.data.property_type = getPropertyType();
        if (!snbFilterController.filter.data.gender || !snbFilterController.filter.data.gender.length) {
            snbFilterController.filter.data.gender = [];
        }
        if (!snbFilterController.filter.data.posted_by || !snbFilterController.filter.data.posted_by.length) {
            snbFilterController.filter.data.posted_by = [];
        }
        if (query_obj && query_obj.property_type) {
            snbFilterController.filter.data.property_type = query_obj.property_type;
        }
        if (query_obj && query_obj.bedrooms) {
            snbFilterController.filter.data.bedrooms = query_obj.bedrooms;
        }
        if (query_obj && query_obj.posted_by) {
            snbFilterController.filter.data.posted_by = query_obj.posted_by;
        }
        if (!snbFilterController.filter.data.ad_type || !snbFilterController.filter.data.ad_type.length) {
            snbFilterController.filter.data.ad_type = [];
        }
        if (query_obj && query_obj.ad_type) {
            snbFilterController.filter.data.ad_type = query_obj.ad_type;
        }
        if (query_obj && query_obj.isQAP) {
            snbFilterController.filter.data.isQAP = query_obj.isQAP;
        }
        if (query_obj && query_obj.isGrabHouse) {
            snbFilterController.filter.data.isGrabHouse = query_obj.isGrabHouse;
        }

        if (!snbFilterController.filter.data.posted_date || !snbFilterController.filter.data.posted_date.length) {
            snbFilterController.filter.data.posted_date = [];
        }
        if (!snbFilterController.filter.data.price) {
            snbFilterController.filter.data.price = {};
            snbFilterController.filter.data.price.min = '-1';
            snbFilterController.filter.data.price.max = '-1';
        }
        if (!snbFilterController.filter.data.area) {
            snbFilterController.filter.data.area = {};
            snbFilterController.filter.data.area.min = '-1';
            snbFilterController.filter.data.area.max = '-1';
        }

        if (snbFilterController.filter.data.type === 'projects') {
            if (!snbFilterController.filter.data.collection_id) {
                snbFilterController.filter.data.collection_id = '';
                snbFilterController.filter.data.collection_name = '';
            }
        }

        snbFilterController.filter.data.text = getQueryVariables("text");
        snbFilterController.filter.data.pics = false;

        let filterTmplArray = snbFilterController.filters_all.filtersList[snbFilterController.filter.data.type];
        if (filterTmplArray.length > 0) {
            filterTmplArray.forEach((v, k) => {
                snbFilterController.apiReloadMap[v] = true;
            });
        }

        if(snbFilterController.filter.data.type != 'brokers') {
            updateFiltersLabel('property_type');
            updateFiltersLabel('posted_by');
            updateFiltersLabel('no_of_rooms');
            updateFiltersLabel('amenities');
            updateFiltersLabel('sortby');
            updateFiltersLabel('furnishing');
            updateFiltersLabel('posted_date');
            updateFiltersLabel('gender');
            updateFiltersLabel('projectstatus');
            updateMoreLabel();
        } else {
            updateFiltersLabel('ad_type');
        }
        updateCollectionsFiltersLabel(snbFilterController.filter.data.collection_id);
        if (snbFilterController.filter.data.ad_locality)
            snbFilterController.filtersLabel.locality = snbFilterController.filter.data.ad_locality.toString();

        if (!pageLoadGa) {
            firePageLoadGa(snbFilterController.filter);
            pageLoadGa = true;
        }
    };

    snbFilterController.getLocalityData = function (key) {
        var cityId = (snbFilterController.filter.data.clusterCity && snbFilterController.filter.data.clusterCity.length >= 1) ? snbFilterController.filter.data.clusterCity[0] : snbFilterController.filter.data.c_id;
        addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type + '_filters', 'QH_snb_' + snbFilterController.filter.data.type + '_filters_localities_search_click');
        // returns a promise
        return getLocalityAutoSuggest({
            'query': key,
            'cityId': cityId,
            'limit': 8,
            'cityIds': snbFilterController.filter.data.clusterCity.toString() || []
        });
    }

    snbFilterController.openBrowserLocation = function (filter = 'static') {
        if (snbFilterController.geoLocation) {
            $('#nearByIcon').removeClass('active');
            snbFilterController.geoLocation = false;
            delete snbFilterController.filter.data.latitude;
            delete snbFilterController.filter.data.longitude;
            callFilterChange(filter);
            return;
        }
        $('#nearByIcon').addClass('active');
        snbFilterController.geoLocation = false;
        if (!navigator.geolocation || !navigator.permissions) {
            showSnackBar();
        }
        snbFilterController.geoLocation = true;
        $('#nearByIcon').addClass('active');
        detectLocations((lat, lng) => {
            snbFilterController.filter.data.latitude = lat,
                snbFilterController.filter.data.longitude = lng,
                callFilterChange(filter, () => {
                    snbFilterController.geoLocation = true;
                    $('#nearByIcon').addClass('active');
                });
        }, () => {
            showSnackBar();
        })
    }

    function showSnackBar() {
        snbFilterController.geoLocation = false;
        $('#nearByIcon').removeClass('active');
        $("#locSnackBar").addClass('show');
        setTimeout(() => {
            $("#locSnackBar").removeClass('show');
        }, 5000);
        return false;
    }

    function getPropertyType() {
        var property_type = [];
        if (snbFilterController.filter.data.type == "pg") {
            return property_type;
        }
        if (!snbFilterController.filter.data.property_type || !snbFilterController.filter.data.property_type.length) {
            return property_type;
        }
        updateFiltersLabel('property_type');
        return snbFilterController.filter.data.property_type;
    }

    var updateCollectionsFiltersLabel = function (collectionId) {
        if (!collectionId) return;
        snbFilterController.collections = isSet(listingData.collections) ? JSON.parse(atob(listingData.collections)) : [];
        for (var i = (snbFilterController.collections).length - 1; i >= 0; i--) {
            var collection = snbFilterController.collections[i] || {};
            if (collection.collectionId == collectionId) {
                snbFilterController.filtersLabel.projectCollections = collection.displayName;
                break;
            }
        }
    }

    snbFilterController.expandCollapseFilter = function () {
        var d = document.getElementById("snb-filters-wrap");
        addEvent('button-RealestateResponsiveUI', 'SnB_' + snbFilterController.searchAction + '_Filter', 'click_more_filters');
        if (!snbFilterController.filters_all.meta.expanded) {
            d.className = "snb-filters-wrap snb-filters-wrap-expand-toggle";
        }
        else {
            d.className = "snb-filters-wrap";
        }
        snbFilterController.filters_all.meta.expanded = !snbFilterController.filters_all.meta.expanded;
    };

    snbFilterController.selectPropertyType = function ({ target }, type, value, filter = 'nonstatic') {
        var index = snbFilterController.filter.data.property_type.indexOf(type);
        var propertyLabel = "";
        if (index == -1) {
            var gtmData = {
                'event': 'Filter_select',
                'Filter_name': 'Property_type',
                'Filter_value': value
            };
            gtmData['user_db_id'] = getCookie('__u');
            if (!snbFilterController.isMobile()) {
                gtmSnbEvent(gtmData);
            }
            snbFilterController.filter.data.property_type.push(type);
            propertyLabel = "_select_click";
            localStorage.setItem('resentSearchPropType', type);
            localStorage.setItem('resentSearchPropVal', value);
        }
        else {
            snbFilterController.filter.data.property_type.splice(index, 1);
            propertyLabel = "_deselect_click";
        }
        if ((snbFilterController.filter.data.property_type.length === 1 && snbFilterController.filter.data.property_type.indexOf('rp') > -1)) {
            snbFilterController.filter.data.bedrooms = [];
            updateFiltersLabel('no_of_rooms');
        }
        updateFiltersLabel('property_type');
        addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type + '_filters', 'QH_snb_' + snbFilterController.filter.data.type + '_filters_propertyType_' + value.replace(" ", "_").toLowerCase() + propertyLabel);

        // Highlight the selected option
        if (filter === 'nonstatic') {
            if (target.classList.contains('selected')) {
                target.classList.remove('selected');
            } else {
                target.classList.add('selected');
            }
        }

        // For filter check and uncheck
        [...document.getElementById('collapseTwo').getElementsByTagName('li')].forEach((el) => {
            if (el.dataset.idnval === type) {
                if (index > -1) {
                    el.classList.remove('selected');
                } else {
                    el.classList.add('selected');
                }
            }
        });

        let elmFltr = document.getElementById('headingTwo').querySelector('.quikr-dropdown-link');
        if (index > -1) {
            if (snbFilterController.filter.data.property_type.length === 0) {
                elmFltr.classList.remove('active');
            }
        } else {
            elmFltr.classList.add('active');
        }


        // Trigger filter change
        callFilterChange(filter);
    };

    snbFilterController.selectClusterCity = function ({ target }, id, value, filter = 'nonstatic') {
        if (!snbFilterController.filter.data.clusterCity) {
            snbFilterController.filter.data.clusterCity = [];
        }
        var index = snbFilterController.filter.data.clusterCity.indexOf(id);
        if (index == -1) {
            snbFilterController.filter.data.clusterCity.push(id);
        }
        else {
            if (snbFilterController.filter.data.clusterCity.length > 1) {
                snbFilterController.filter.data.clusterCity.splice(index, 1);
            }
        }

        snbFilterController.apiReloadMap['snbLocalityFilter'] = true;
        snbFilterController.apiReloadMap['snbPOIFilter'] = true;

        // Highlight the selected option
        if (filter === 'nonstatic') {
            if (target.classList.contains('selected')) {
                target.classList.remove('selected');
            } else {
                target.classList.add('selected')
            }
        }

        // Trigger filter change
        callFilterChange(filter);
    }

    snbFilterController.selectProjectStatus = function ({ target }, type, value, filter = 'nonstatic') {
        snbFilterController.filter.data.projectstatus = type;
        updateFiltersLabel('projectstatus');
        type = (type == 'completed') ? 'ready_to_move' : type;
        addEvent('RealEstate', 'QH_snb_projects_filters', 'QH_snb_projects_filters_projectStatus_' + type + '_select_click');

        // Highlight the selected option
        if (filter === 'nonstatic') {
            // Remove the already selected options
            [...target.parentElement.querySelectorAll('.selected')]
                .forEach((el) => el.classList.remove('selected'));

            // Highlight the selected one
            target.classList.add('selected');
        }

        // Trigger filter change
        callFilterChange(filter);
    };

    snbFilterController.selectRera = function ({ target }, filter = 'nonstatic') {
        var reraLabel
        if (snbFilterController.filter.data.isReraApproved && snbFilterController.filter.data.isReraApproved == 1) {
            snbFilterController.filter.data.isReraApproved = '';
            reraLabel = 'deselect_click';
        } else {
            snbFilterController.filter.data.isReraApproved = 1;
            reraLabel = 'select_click';
        }
        addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type + '_filters', 'QH_snb_' + snbFilterController.filter.data.type + '_filters_rera_' + reraLabel);

        // Highlight the selected option
        if (filter === 'nonstatic') {
            if (target.classList.contains('selected')) {
                target.classList.remove('selected');
            } else {
                target.classList.add('selected');
            }
        }

        callFilterChange(filter);
    }

    snbFilterController.selectSaleTypeFilter = function ({ target }, filter = 'nonstatic') {
        const { c, c_id, saleType } = snbFilterController.filter.data;
        resetFilterData();
        resetFilterUI();

        // Defaults for Auction
        snbFilterController.filter.data.c = c;
        snbFilterController.filter.data.c_id = c_id;
        snbFilterController.filter.data.property_type = [];

        // Toggle
        snbFilterController.filter.data.saleType = (saleType == 2) ? '' : 2;

        if (filter === 'nonstatic') {
            const { classList } = target;
            // Highlight the selected option
            classList.contains('selected') ? classList.remove('selected') : classList.add('selected')
        }

        // GA
        const gaLabel = (saleType == 2) ? '_deselect_click' : '_select_click';
        addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type + '_filters', 'QH_snb_' + snbFilterController.filter.data.type + '_filters_auction_' + gaLabel);

        callFilterChange(filter);
    };

    snbFilterController.selectSanitized = function ({ target }, filter = 'nonstatic') {
       let staticSanitise = document.getElementById("staticSanitisedFilter");
        if( staticSanitise && staticSanitise.getAttribute("disabled") !== 'true'){
            staticSanitise.setAttribute("disabled",true);
            staticSanitise.style.opacity = 0.5;
            document.getElementById("headingSanitised")?document.getElementById("headingSanitised").style.opacity = 0.5 : '';
            var sanitizeLabel
            if (snbFilterController.filter.data.hasCovidMeasures && snbFilterController.filter.data.hasCovidMeasures == 1) {
                snbFilterController.filter.data.hasCovidMeasures = '';
                sanitizeLabel = '_deselect_click';
            } else {
                snbFilterController.filter.data.hasCovidMeasures = 1;
                sanitizeLabel = '_select_click';
            }
            addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type + '_filters', 'QH_snb_' + snbFilterController.filter.data.type + '_filters_sanitize_' + sanitizeLabel);

            // Highlight the selected option
            if (filter === 'nonstatic') {
                if (target.classList.contains('selected')) {
                    target.classList.remove('selected');
                } else {
                    target.classList.add('selected');
                }
            }

            if(filter === 'static'){
                [...document.getElementById('collapseTwelve').getElementsByTagName('li')].forEach((el) => {
                if (el.classList.contains('selected')) {
                    el.classList.remove('selected');
                } else {
                    el.classList.add('selected');
                }
            });
            }
            callFilterChange(filter);
        }
        
    }

    function onFilterChange(type) {
        var active = $("#QHaccordion .in").attr('id');
        snbFilterController.filter.data.accordionGroup = active;
        snbFilterController.currentPage = 1;
        if(snbFilterController.filter.data.type === 'projects'){
            checkCollectionData();
        }
        loadFilterData(undefined, type);
        resetPageUrl();
        setSnbUrl(snbFilterController.filter);
        firePageLoadGa(snbFilterController.filter);
        var gtmData = snbFilterController.filter.data;
        gtmSnbfilter('SNB_FILTER', gtmData);
    };

    function checkCollectionData() {
        var snbfilterDataCheck = snbFilterController.filter.data;
        if (snbfilterDataCheck.ad_locids.length || snbfilterDataCheck.property_type.length || snbfilterDataCheck.isReraApproved || snbfilterDataCheck.possession || snbfilterDataCheck.property_type.length || snbfilterDataCheck.price.min > 0 || snbfilterDataCheck.price.max > 0 || snbfilterDataCheck.area.min > 0 || snbfilterDataCheck.area.max > 0 || snbfilterDataCheck.bedrooms.length || snbfilterDataCheck.amenities.length) {
            snbFilterController.filter.data.collection_id = "";
            snbFilterController.filter.data.collection_name = "";
            snbFilterController.filtersLabel.projectCollections = "";
        }
    }

    function setPageUrl(pageNumber) {
        var rtn = window.location.href.split("?")[0],
            param,
            params_arr = [],
            paramFound = false;
        queryString = (window.location.href.indexOf("?") !== -1) ? window.location.href.split("?")[1] : "";

        if (queryString !== "") {
            params_arr = queryString.split("&");
            for (var i = params_arr.length - 1; i >= 0; i -= 1) {
                param = params_arr[i].split("=")[0];
                if (param === "page") {
                    params_arr.splice(i, 1);
                    params_arr.push("page=" + pageNumber);
                    paramFound = true;
                }
            }
            if (!paramFound) {
                history.pushState({}, "", window.location.pathname + window.location.search + '&' + "page=" + pageNumber);
                return;
            }
            if (pageNumber == 1) {
                history.pushState({}, "", rtn);
                return;
            }
            rtn = rtn + "?" + params_arr.join("&");
            history.pushState({}, "", rtn);
        }
        else {
            history.pushState({}, "", window.location.pathname + '?' + "page=" + pageNumber);
        }
    }

    function resetPageUrl() {
        var rtn = window.location.href.split("?")[0],
            param,
            params_arr = [],
            queryString = (window.location.href.indexOf("?") !== -1) ? window.location.href.split("?")[1] : "";
        if (queryString !== "") {
            params_arr = queryString.split("&");
            for (var i = params_arr.length - 1; i >= 0; i -= 1) {
                param = params_arr[i].split("=")[0];
                if (param === "page") {
                    params_arr.splice(i, 1);
                }
            }
            rtn = rtn + "?" + params_arr.join("&");
        }
        history.pushState({}, "", rtn);
    }


    function firePageLoadGa(filter) {
        if (filter.data.type == 'projects') {
            if (snbFilterController.filter.data.collection_id && snbFilterController.currentPage == 1) {
                addPageView('SnB_collections', 'RealEstate', 'Browse_' + capitalizeFirstLetter(filter.data.c), snbFilterController.filter.data.collection_id);
            } else if (snbFilterController.filter.data.collection_id && snbFilterController.currentPage > 1) {
                addPageView('SnB_collections_nextpage', 'RealEstate', 'Browse_' + capitalizeFirstLetter(filter.data.c), snbFilterController.filter.data.collection_id, snbFilterController.currentPage);
            } else {
                addPageView('SnB_Project', 'RealEstate',`${capitalizeFirstLetter(filter.data.cat)}_${capitalizeFirstLetter(filter.data.c)}`, `${capitalizeFirstLetter(intentType(filter.data.type))}_${snbFilterController.searchAction}`);
            }
        } else {
            addPageView('SnB', 'RealEstate',`${capitalizeFirstLetter(filter.data.cat)}_${capitalizeFirstLetter(filter.data.c)}`, `${capitalizeFirstLetter(intentType(filter.data.type))}_${snbFilterController.searchAction}`);
        }
    }

    snbFilterController.inArray = function (array, value) {
        return array.indexOf(value) !== -1;
    };

    snbFilterController.isMobile = function () {
        var condition = false;
        return window.innerWidth < 768 ? !condition : condition;
    };

    snbFilterController.callApply = function () {
        document.body.classList.remove("modal-open");
        var msiteGtmData = mobileapplyGtm();
        gtmSnbEvent(msiteGtmData);
        onFilterChange();
    }

    function callFilterChange(filter = 'nonstatic', callFunc = null) {
        if (!snbFilterController.isMobile() || filter === 'static') {
            onFilterChange();
            if (typeof callFunc === 'function') {
                callFunc();
            }
        }
         
    }

    function gtmSnbEvent(data) {
        //if(connectionCheck()){
        //    eventTriggerQH(data);
        //} else {
        eventTriggeranalatyic(data);
        //}
    }


    snbFilterController.selectPrice = function ({ target }, type, value) {
        snbFilterController.filter.data.price[type] = value;
        var priceLabel = "";
        if (type == 'min' && (snbFilterController.filter.data.price.min * 1 >= snbFilterController.filter.data.price.max * 1)) {
            snbFilterController.filter.data.price.max = '-1';
            document.getElementById('maxpricedrop').innerHTML = 'Max' + '<i class="icon icon-down-open-mini pull-right"></i>';
        }
        var gtmData = {
            'event': 'Filter_select',
            'Filter_value': value
        };
        if (type == 'min') {
            document.getElementById('minpricedrop').innerHTML = getTinyMoneyFormat(value) + '<i class="icon icon-down-open-mini pull-right"></i>';
            document.querySelectorAll('#budgetMaxList li').forEach((el) => {
                el.classList.remove('disabled');
                if ((parseInt(el.dataset.opt, 10) <= parseInt(value, 10)) && !el.classList.contains('disabled')) {
                    el.classList.add('disabled');
                }
            });
            gtmData['Filter_name'] = 'Price_min';
            priceLabel = "budget_min_click";
        } else {
            document.getElementById('maxpricedrop').innerHTML = getTinyMoneyFormat(value) + '<i class="icon icon-down-open-mini pull-right"></i>';
            gtmData['Filter_name'] = 'Price_max';
            priceLabel = "budget_max_click";
        }
        gtmData['user_db_id'] = getCookie('__u');

        document.querySelector('#budgetMinList').style.display = "none";
        document.querySelector('#budgetMaxList').style.display = "none";

        addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type + '_filters', 'QH_snb_' + snbFilterController.filter.data.type + '_filters_' + priceLabel);

        if (!snbFilterController.isMobile()) {
            gtmSnbEvent(gtmData);
        } else {
            // Remove the already selected options
            [...target.parentElement.querySelectorAll('.selected')]
                .forEach((el) => el.classList.remove('selected'));

            // Highlight the selected one
            target.classList.add('selected');
        }

        callFilterChange();
    };

    snbFilterController.selectPriceMobile = function ({ target }, minvalue, maxvalue) {
        if (snbFilterController.filter.data.price.min == minvalue) {
            delete snbFilterController.filter.data.price.min;
            delete snbFilterController.filter.data.price.max;
        } else {
            snbFilterController.filter.data.price.min = minvalue;
            snbFilterController.filter.data.price.max = maxvalue;
        }

        // Remove the already selected options
        [...target.parentElement.querySelectorAll('.selected')]
            .forEach((el) => el.classList.remove('selected'));

        // Highlight the selected one
        target.classList.add('selected');

        callFilterChange();
    };

    snbFilterController.selectAreaMobile = function ({ target }, minvalue, maxvalue) {
        if (snbFilterController.filter.data.area.min == minvalue) {
            delete snbFilterController.filter.data.area.min;
            delete snbFilterController.filter.data.area.max;
        } else {
            snbFilterController.filter.data.area.min = minvalue;
            snbFilterController.filter.data.area.max = maxvalue;
        }

        // Highlight the selected option
        if (target.classList.contains('selected')) {
            target.classList.remove('selected');
        } else {
            target.classList.add('selected');
        }

        callFilterChange();
    };

    snbFilterController.showPriceSqftDropDown = function (elem) {
        let dropdwn = document.getElementById(elem).nextSibling.nextElementSibling;
        let newelem = elem.includes("min") ? elem.replace("min", "max") : elem.replace("max", "min");
        let clsdropdwn = document.getElementById(newelem).nextSibling.nextElementSibling;
        if (clsdropdwn.style.display === "block") {
            clsdropdwn.style.display = "none";
        }
        if (dropdwn.style.display === "none") {
            dropdwn.style.display = "block";
        } else {
            dropdwn.style.display = "none";
        }
    };

    snbFilterController.addRemoveBeds = function ({ target }, bed, bedValue) {
        var index = snbFilterController.filter.data.bedrooms.indexOf(bed);
        var label = bed == '4+' ? '4_plus' : bedValue;
        var bhkLabel = "";
        if (index == -1) {
            var gtmData = {
                'event': 'Filter_select',
                'Filter_name': 'Bhk',
                'Filter_value': bedValue
            };
            gtmData['user_db_id'] = getCookie('__u');
            if (!snbFilterController.isMobile()) {
                gtmSnbEvent(gtmData);
            }
            snbFilterController.filter.data.bedrooms.push(bed);
            bhkLabel = "_select_click";
            localStorage.setItem('resentSearchBhk', bedValue);
        }
        else {
            snbFilterController.filter.data.bedrooms.splice(index, 1);
            bhkLabel = "_deselect_click";
        }
        snbFilterController.filter.data.bedrooms.sort();
        updateFiltersLabel('no_of_rooms');
        addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type + '_filters', 'QH_snb_' + snbFilterController.filter.data.type + '_filters_bhk_' + label + bhkLabel);

        // Highlight the selected option
        if (target.classList.contains('selected')) {
            target.classList.remove('selected');
        } else {
            target.classList.add('selected');
        }

        callFilterChange();
    };

    snbFilterController.selectArea = function ({ target }, type, value) {
        snbFilterController.filter.data.area[type] = value;
        var areaLabel = "";
        if (type == 'min' &&
            (snbFilterController.filter.data.area.min * 1 >= snbFilterController.filter.data.area.max * 1)) {
            snbFilterController.filter.data.area.max = '-1';
            document.getElementById('maxsqftdrop').innerHTML = 'Max' + '<i class="icon icon-down-open-mini pull-right"></i>';
        }
        var gtmData = {
            'event': 'Filter_select',
            'Filter_value': value
        };
        if (type == 'min') {
            document.getElementById('minsqftdrop').innerHTML = getTinyMoneyFormat(value) + '<i class="icon icon-down-open-mini pull-right"></i>';
            document.querySelectorAll('#areaMaxList li').forEach((el) => {
                el.classList.remove('disabled');
                if ((parseInt(el.dataset.opt, 10) <= parseInt(value, 10)) && !el.classList.contains('disabled')) {
                    el.classList.add('disabled');
                }
            });
            gtmData['Filter_name'] = 'Area_min';
            areaLabel = "area_min_click";
        } else {
            document.getElementById('maxsqftdrop').innerHTML = getTinyMoneyFormat(value) + '<i class="icon icon-down-open-mini pull-right"></i>';
            gtmData['Filter_name'] = 'Area_max';
            areaLabel = "area_max_click";
        }

        gtmData['user_db_id'] = getCookie('__u');

        document.querySelector('#areaMinList').style.display = "none";
        document.querySelector('#areaMaxList').style.display = "none";

        if (!snbFilterController.isMobile()) {
            gtmSnbEvent(gtmData);
        } else {
            // Remove the already selected options
            [...target.parentElement.querySelectorAll('.selected')]
                .forEach((el) => el.classList.remove('selected'));

            // Highlight the selected one
            target.classList.add('selected');
        }

        callFilterChange();
        addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type + '_filters', 'QH_snb_' + snbFilterController.filter.data.type + '_filters_' + areaLabel);
    };

    snbFilterController.selectPostedBy = function ({ target }, type, value, filter = 'nonstatic') {
        var index = snbFilterController.filter.data.posted_by.indexOf(type);
        var postedLabel = '';
        if (index == -1) {
            var gtmData = {
                'event': 'Filter_select',
                'Filter_name': 'Posted_by',
                'Filter_value': value
            };
            gtmData['user_db_id'] = getCookie('__u');
            if (!snbFilterController.isMobile()) {
                gtmSnbEvent(gtmData);
            }
            snbFilterController.filter.data.posted_by.push(type);
            postedLabel = "_select_click";
        }
        else {
            snbFilterController.filter.data.posted_by.splice(index, 1);
            postedLabel = "_deselect_click";
        }
        updateFiltersLabel('posted_by');

        // Highlight the selected option
        if (filter === 'nonstatic') {
            if (target.classList.contains('selected')) {
                target.classList.remove('selected');
            } else {
                target.classList.add('selected');
            }
        }

        // For filter check and uncheck
        if (
            document.getElementById('collapseEight') &&
            document.getElementById('headingEight')) {
            [...document.getElementById('collapseEight').getElementsByTagName('li')].forEach((el) => {
                if (el.dataset.idnval === type) {
                    if (index > -1) {
                        el.classList.remove('selected');
                    } else {
                        el.classList.add('selected');
                    }
                }
            });
    
            let elmFltr = document.getElementById('headingEight').querySelector('.quikr-dropdown-link');
            if (index > -1) {
                if (snbFilterController.filter.data.posted_by.length === 0) {
                    elmFltr.classList.remove('active');
                }
            } else {
                elmFltr.classList.add('active');
            }
        }

        callFilterChange(filter);
        addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type + '_filters', 'QH_snb_' + snbFilterController.filter.data.type + '_filters_postedBy_' + value.toLowerCase() + postedLabel);
    };
    snbFilterController.selectAdType = function ({ target }, type, value) {
        var index = snbFilterController.filter.data.ad_type.indexOf(type);
        var adtypeLabel = '';
        if (index == -1) {
            var gtmData = {
                'event': 'Filter_select',
                'Filter_name': 'AdType',
                'Filter_value': value
            };
            gtmData['user_db_id'] = getCookie('__u');
            if (!snbFilterController.isMobile()) {
                gtmSnbEvent(gtmData);
            }
            snbFilterController.filter.data.ad_type.push(type);
        }
        else {
            snbFilterController.filter.data.ad_type.splice(index, 1);
        }
        updateFiltersLabel('ad_type');

        // Highlight the selected option
        if (target.classList.contains('selected')) {
            target.classList.remove('selected');
        } else {
            target.classList.add('selected');
        }

        callFilterChange();
    };

    snbFilterController.selectGender = function ({ target }, type, filter = 'nonstatic') {
        var index = snbFilterController.filter.data.gender.indexOf(type);
        var genderLabel = '';
        if (index == -1) {
            var gtmData = {
                'event': 'Filter_select',
                'Filter_name': 'Gender',
                'Filter_value': type
            };
            gtmData['user_db_id'] = getCookie('__u');
            if (!snbFilterController.isMobile()) {
                gtmSnbEvent(gtmData);
            }
            snbFilterController.filter.data.gender.push(type);
            genderLabel = "_select_click";
            target.classList.add('selected');
        }
        else {
            snbFilterController.filter.data.gender.splice(index, 1);
            genderLabel = "_deselect_click";
            target.classList.remove('selected');
        }
        updateFiltersLabel('gender');

        [...document.getElementById('collapseSeven').getElementsByTagName('li')].forEach((el) => {
            if (el.dataset.idnval === type) {
                if (index > -1) {
                    el.classList.remove('selected');
                } else {
                    el.classList.add('selected');
                }
            }
        });

        let elmFltr = document.getElementById('headingSeven').querySelector('.quikr-dropdown-link');
        if (index > -1) {
            if (snbFilterController.filter.data.gender.length === 0) {
                elmFltr.classList.remove('active');
            }
        } else {
            elmFltr.classList.add('active');
        }

        // Highlight the selected option
        callFilterChange(filter);
        addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type + '_filters', 'QH_snb_' + snbFilterController.filter.data.type + '_filters_gender_' + type + genderLabel);
    };

    snbFilterController.selectCollection = function ({ target }, id, name) {
        snbFilterController.filter.data.collection_id = id;
        snbFilterController.filter.data.collection_name = name;
        updateCollectionsFiltersLabel(id);

        // Remove the already selected options
        [...target.parentElement.querySelectorAll('.selected')]
            .forEach((el) => el.classList.remove('selected'));

        // Highlight the selected one
        target.classList.add('selected');

        callFilterChange();
        addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type + '_filters', 'QH_snb_' + snbFilterController.filter.data.type + '_filters_collections_' + snbFilterController.filtersLabel.projectCollections + '_select_click');
    };

    snbFilterController.selectAmenity = function ({ target }, type, value) {
        var index = snbFilterController.filter.data.amenities.indexOf(type);
        var amenityLabel = "";
        if (index == -1) {
            var gtmData = {
                'event': 'Filter_select',
                'Filter_name': 'Amenity',
                'Filter_value': value
            };
            gtmData['user_db_id'] = getCookie('__u');
            if (!snbFilterController.isMobile()) {
                gtmSnbEvent(gtmData);
            }
            snbFilterController.filter.data.amenities.push(unescape(type));
            amenityLabel = "_select_click";
        }
        else {
            snbFilterController.filter.data.amenities.splice(index, 1);
            amenityLabel = "_deselect_click";
        }
        updateMoreLabel();
        updateFiltersLabel('amenities');

        // Highlight the selected option
        if (target.classList.contains('selected')) {
            target.classList.remove('selected');
        } else {
            target.classList.add('selected');
        }

        callFilterChange();
        addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type + '_filters', 'QH_snb_' + snbFilterController.filter.data.type + '_filters_amenties_' + value.replace(" ", "_").toLowerCase() + amenityLabel);
    };

    snbFilterController.selectFurnishingStatus = function ({ target }, labeledText, value) {
        var index = snbFilterController.filter.data.furnishingStatus.indexOf(value);
        var filterStatusLabel = "";
        if (index == -1) {
            var gtmData = {
                'event': 'Filter_select',
                'Filter_name': 'Amenity',
                'Filter_value': value
            };
            gtmData['user_db_id'] = getCookie('__u');
            if (!snbFilterController.isMobile()) {
                gtmSnbEvent(gtmData);
            }
            snbFilterController.filter.data.furnishingStatus.push(value);
            filterStatusLabel = "_select_click";
        }
        else {
            snbFilterController.filter.data.furnishingStatus.splice(index, 1);
            filterStatusLabel = "_deselect_click";
        }

        // Highlight the selected option
        if (target.classList.contains('selected')) {
            target.classList.remove('selected');
        } else {
            target.classList.add('selected');
        }

        callFilterChange();
        addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type + '_filters', 'QH_snb_' + snbFilterController.filter.data.type + '_filters_furnishingStatus_' + value.replace(" ", "_").toLowerCase() + filterStatusLabel);
    };

    snbFilterController.selectFurnishing = function ({ target }, type, value) {
        var index = snbFilterController.filter.data.furnishing.indexOf(type);
        var furnishingLabel = "";
        if (index == -1) {
            var gtmData = {
                'event': 'Filter_select',
                'Filter_name': 'Furnishing',
                'Filter_value': value
            };
            gtmData['user_db_id'] = getCookie('__u');
            if (!snbFilterController.isMobile()) {
                gtmSnbEvent(gtmData);
            }
            snbFilterController.filter.data.furnishing.push(type);
            furnishingLabel = "_select_click";
        }
        else {
            snbFilterController.filter.data.furnishing.splice(index, 1);
            furnishingLabel = "_deselect_click";
        }
        updateMoreLabel();
        updateFiltersLabel('furnishing');

        // Highlight the selected option
        if (target.classList.contains('selected')) {
            target.classList.remove('selected');
        } else {
            target.classList.add('selected');
        }

        callFilterChange();
        addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type + '_filters', 'QH_snb_' + snbFilterController.filter.data.type + '_filters_items_' + value + furnishingLabel);
    };

    snbFilterController.selectOccupancy = function ({ target }, type, value) {
        var idx = snbFilterController.filter.data.occupancy.indexOf(type);
        var occupancyLabel = "";
        if (idx == -1) {
            var gtmData = {
                'event': 'Occupancy_select',
                'Filter_name': 'Occupancy',
                'Filter_value': value
            };
            gtmData['user_db_id'] = getCookie('__u');
            if (!snbFilterController.isMobile()) {
                gtmSnbEvent(gtmData);
            }
            snbFilterController.filter.data.occupancy.push(type);
            occupancyLabel = "_select_click";
        }
        else {
            snbFilterController.filter.data.occupancy.splice(idx, 1);
            occupancyLabel = "_deselect_click";
        }
        updateMoreLabel();
        updateFiltersLabel('occupancy');

        // Highlight the selected option
        if (target.classList.contains('selected')) {
            target.classList.remove('selected');
        } else {
            target.classList.add('selected');
        }

        callFilterChange();
        addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type + '_filters', 'QH_snb_' + snbFilterController.filter.data.type + '_filters_items_' + value + occupancyLabel);
    };

    snbFilterController.addRemovePossession = function ({ target }, value, filter = 'nonstatic') {
        (snbFilterController.filter.data.possession == value) ? snbFilterController.filter.data.possession = '' : snbFilterController.filter.data.possession = value;
        var possessionLabel;
        if (snbFilterController.filter.data.possession) {
            possessionLabel = '_select_click';
        } else {
            possessionLabel = '_deselect_click';
        }
        addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type + '_filters', 'QH_snb_' + snbFilterController.filter.data.type + '_filters_propertyStatus_' + value + possessionLabel);

        // Highlight the selected option
        if (filter === 'nonstatic') {
            // Remove the already selected options
            [...target.parentElement.querySelectorAll('.selected')]
                .forEach((el) => el.classList.remove('selected'));

            // Highlight the selected one
            target.classList.add('selected');
        }

        // For filter check and uncheck
        if (filter === 'static') {
            if (document.getElementById('collapseThirteen') && document.getElementById('headingThirteen')) {
                [...document.getElementById('collapseThirteen').getElementsByTagName('li')].forEach((el) => {
                    el.classList.remove('selected');
                });
                [...document.getElementById('collapseThirteen').getElementsByTagName('li')].forEach((el) => {
                    if (el.dataset.idnval === value) {
                        if (el.classList.contains('selected')) {
                            el.classList.remove('selected');
                        } else {
                            el.classList.add('selected');
                        }
                    }
                });
        
                let elmFltr = document.getElementById('headingThirteen').querySelector('.quikr-dropdown-link');
                if (possessionLabel === '_deselect_click') {
                    elmFltr.classList.remove('active');
                } else {
                    elmFltr.classList.add('active');
                }
            }
        }

        callFilterChange(filter);
    };


    snbFilterController.addRemoveAvailableFrom = function ({ target }, value, filter = 'nonstatic') {
        (snbFilterController.filter.data.available_from == value) ? snbFilterController.filter.data.available_from = '' : snbFilterController.filter.data.available_from = value;
        var availableFromLabel;
        if (snbFilterController.filter.data.available_from) {
            availableFromLabel = '_select_click';
        } else {
            availableFromLabel = '_deselect_click';
        }
        addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type + '_filters', 'QH_snb_' + snbFilterController.filter.data.type + '_filters_availableFrom_' + value + availableFromLabel);

        // Highlight the selected option
        if (filter === 'nonstatic') {
            // Remove the already selected options
            [...target.parentElement.querySelectorAll('.selected')]
                .forEach((el) => el.classList.remove('selected'));

            // Highlight the selected one
            target.classList.add('selected');
        }


        // For filter check and uncheck
        if (filter === 'static') {
            [...document.getElementById('collapsefrom').getElementsByTagName('li')].forEach((el) => {
                el.classList.remove('selected');
            });
            [...document.getElementById('collapsefrom').getElementsByTagName('li')].forEach((el) => {
                if (el.dataset.idnval === value) {
                    if (el.classList.contains('selected')) {
                        el.classList.remove('selected');
                    } else {
                        el.classList.add('selected');
                    }
                }
            });
    
            let elmFltr = document.getElementById('avfrom').querySelector('.quikr-dropdown-link');
            if (availableFromLabel === '_deselect_click') {
                elmFltr.classList.remove('active');
            } else {
                elmFltr.classList.add('active');
            }
        }

        callFilterChange(filter);
    };


    snbFilterController.selectPostedDate = function ({ target }, type, value, filter = 'nonstatic') {
        var index = snbFilterController.filter.data.posted_date.indexOf(type);
        var postedDateLabel = "";
        var postedDateLabel = "";
        if (index == -1) {
            var gtmData = {
                'event': 'Filter_select',
                'Filter_name': 'PostedDate',
                'Filter_value': value
            };
            gtmData['user_db_id'] = getCookie('__u');
            if (!snbFilterController.isMobile()) {
                gtmSnbEvent(gtmData);
            }
            snbFilterController.filter.data.posted_date.push(type);
            postedDateLabel = "_select_click";
        }
        else {
            snbFilterController.filter.data.posted_date.splice(index, 1);
            postedDateLabel = "_deselect_click";
        }
        updateMoreLabel();
        updateFiltersLabel('posted_date');

        // Highlight the selected option
        if (filter === 'nonstatic') {
            if (target.classList.contains('selected')) {
                target.classList.remove('selected');
            } else {
                target.classList.add('selected');
            }
        }

        // Highlight the selected option
        if (filter === 'nonstatic') {
            if (target.classList.contains('selected')) {
                target.classList.remove('selected');
            } else {
                target.classList.add('selected');
            }
        }

        // For filter check and uncheck
        [...document.getElementById('collapseEleven').getElementsByTagName('li')].forEach((el) => {
            if (el.dataset.idnval === type) {
                if (index > -1) {
                    el.classList.remove('selected');
                } else {
                    el.classList.add('selected');
                }
            }
        });

        let elmFltr = document.getElementById('headingEleven').querySelector('.quikr-dropdown-link');
        if (index > -1) {
            if (snbFilterController.filter.data.posted_date.length === 0) {
                elmFltr.classList.remove('active');
            }
        } else {
            elmFltr.classList.add('active');
        }

        callFilterChange(filter);
        addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type + '_filters', 'QH_snb_' + snbFilterController.filter.data.type + '_filters_postedDate_' + value.replace(" ", "_").toLowerCase() + postedDateLabel);
    };
    snbFilterController.resetResionZone = () => {
        snbFilterController.filter.data.ad_regionid = [];
        snbFilterController.filter.data.ad_regionName = [];
        snbFilterController.filter.data.region_id = [];
        snbFilterController.filter.data.ad_zoneid = [];
        snbFilterController.filter.data.zone_id = [];
        snbFilterController.filter.data.ad_zoneName = [];
    };
    snbFilterController.selectLocality = function (data, operationType) {
        searchActionService.setAction('Search');
        snbFilterController.searchAction = searchActionService.getAction();
        var localities = [];
        var localitieIds = [];
        var localitieCityNames = [];
        for (var i = 0; i < data.length; i++) {
            localities.push(data[i].title);
            localitieIds.push(data[i].id);
            localitieCityNames.push(data[i].city);
        }
        snbFilterController.filter.data.ad_locality = localities;
        snbFilterController.filter.data.ad_locids = localitieIds;
        snbFilterController.filter.data.ad_loccitynames = localitieCityNames;
        snbFilterController.filtersLabel.locality = localities.toString();
        var label = operationType && operationType === 'select' ? 'select_locality' : 'deselect_locality';
        snbFilterController.resetResionZone()
        callFilterChange();

        var localityLabel = operationType && operationType === 'select' ? 'select_click' : 'deselect_click';
        addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type + '_filters', 'QH_snb_' + snbFilterController.filter.data.type + '_filters_localities_' + localityLabel);
    };

    snbFilterController.setQuikrAssured = function (isAssured, type, filterSource = 'nonstatic') {
        switch (type) {
            case 'rent': {
                var filterType = (filterSource == 'nonstatic') ? 'qapToggle' : 'qapFilter';
                if (isAssured) {
                    snbFilterController.filter.data.isQAP = 1;
                    addEvent('RealEstate', 'QH_snb_rent', 'QH_snb_rent_' + filterType + '_select_click');
                } else {
                    delete snbFilterController.filter.data.isQAP;
                    addEvent('RealEstate', 'QH_snb_rent', 'QH_snb_rent_' + filterType + '_deselect_click');
                }
                break;
            }

            case 'pg': {
                if (isAssured) {
                    snbFilterController.filter.data.isGrabHouse = 1;
                } else {
                    delete snbFilterController.filter.data.isGrabHouse;
                }
                break;
            }
        }
        callFilterChange(filterSource);
    };

    snbFilterController.quikrAssuredGA = function (filterType, filterData) {
        addEvent('RealEstate', 'QH_snb_rent', 'QH_snb_rent_' + filterType + '_' + filterData + '_click');
    };

    snbFilterController.resetFilter = function () {
        document.body.classList.remove("modal-open");
        snbFilterController.reset = !snbFilterController.reset; // just a reset flag used for locality reset
        resetFilterData();
        resetFilterUI();

        // Call filter API
        onFilterChange();

        addEvent('button-RealestateResponsiveUI', 'SnB_' + snbFilterController.searchAction + '_Filter', 'click_reset');
        addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type, 'QH_snb_' + snbFilterController.filter.data.type + '_header3_clearAll_click');

        if (snbFilterController.isMobile()) {
            snbFilterController.showHideFilterMobile();
        }
    };

    function resetFilterData () {
        let clusterCityFilter = snbFilterController.filter.data.clusterCity;
        snbFilterController.filter = {
            data: getFilterFromPath()
        }
        if (!snbFilterController.filter.data) {
            snbFilterController.filter.data = {};
        }
        // not sure why this was done. It was appending locality name in URl.
        /*        if (locality_names.length > 0) {
         snbFilterController.filter.data.loc = locality_names.toString();
         }*/
        snbFilterController.filter.data.amenities = [];
        snbFilterController.filter.data.furnishingStatus = [];
        snbFilterController.filter.data.sortby = [];
        snbFilterController.filter.data.ad_locality = [];
        snbFilterController.filter.data.ad_locids = [];
        snbFilterController.filter.data.ad_loccitynames = [];
        snbFilterController.filter.data.poiIds = [];
        snbFilterController.filter.data.poiNames = [];
        snbFilterController.filter.data.furnishing = [];
        snbFilterController.filter.data.bedrooms = [];
        snbFilterController.filter.data.property_type = [];
        snbFilterController.filter.data.clusterCity = [];
        snbFilterController.filter.data.projectstatus = '';
        snbFilterController.filter.data.gender = [];
        snbFilterController.filter.data.occupancy = [];
        snbFilterController.filter.data.posted_by = [];
        snbFilterController.filter.data.ad_type = [];
        snbFilterController.filter.data.posted_date = [];
        snbFilterController.filter.data.price = {};
        snbFilterController.filter.data.price.min = '-1';
        snbFilterController.filter.data.price.max = '-1';
        snbFilterController.filter.data.area = {};
        snbFilterController.filter.data.area.min = '-1';
        snbFilterController.filter.data.area.max = '-1';
        snbFilterController.filter.data.possession = '';
        snbFilterController.filter.data.available_from = '';
        snbFilterController.filter.data.isReraApproved = '';
        snbFilterController.filter.data.text = getQueryVariables("text");
        //$location.search('text',null);
        snbFilterController.filter.data.pics = false;
        snbFilterController.filter.data.collection_id = '';
        snbFilterController.filter.data.collection_name = '';
        snbFilterController.filter.data.isQAP = 0;
        snbFilterController.filter.data.isGrabHouse = 0;
        snbFilterController.filtersLabel = {};
        snbFilterController.elIdLastAppliedFilter = null;
        snbFilterController.filter.data.clusterCity = clusterCityFilter;
        snbFilterController.filter.data.hasCovidMeasures = '';
        snbFilterController.filter.data.saleType = '';
    }

    function resetFilterUI () {
        const selectedCheckbox = [...document.querySelectorAll('#snbFiltersList li.selected')];
        const highlightedTabs = [...document.querySelectorAll('#snbFiltersList .quikr-dropdown-link.active')];
        if (selectedCheckbox.length > 0) {
            selectedCheckbox.forEach((el) => {
                if (el.dataset.filtername !== 'cities') {
                    el.classList.remove('selected');
                }
            });
        }
        if (highlightedTabs.length > 0) {
            highlightedTabs.forEach((el) => {
                el.classList.remove('active');
            });
        }

        if (document.getElementById('minsqftdrop')) {
            document.getElementById('minsqftdrop').innerHTML = 'Min' + '<i class="icon icon-down-open-mini pull-right"></i>';
        }
        if (document.getElementById('maxsqftdrop')) {
            document.getElementById('maxsqftdrop').innerHTML = 'Max' + '<i class="icon icon-down-open-mini pull-right"></i>';
        }
        if (document.getElementById('minpricedrop')) {
            document.getElementById('minpricedrop').innerHTML = 'Min' + '<i class="icon icon-down-open-mini pull-right"></i>';
        }
        if (document.getElementById('maxpricedrop')) {
            document.getElementById('maxpricedrop').innerHTML = 'Max' + '<i class="icon icon-down-open-mini pull-right"></i>';
        }

        MultiAutosuggest.resetAutosuggest();
    };

    function reRenderFilter() {
        let filterTmplArray = snbFilterController.filters_all.filtersList[snbFilterController.filter.data.type];
        templateRender(filterTmplArray);
        renderTabs();
        //initMA();
        if (snbFilterController.isMobile()) {
            renderTabsMsite();
        }
        renderSort();

        snbFilterController.attachFilterSelectionAutoScroll();
    }

    function templateRender(templateArray) {
        snbFilterController.collections = isSet(listingData.collections) ? JSON.parse(atob(listingData.collections)) : '';
        let jsonFilterData = {
            'filters_all': snbFilterController.filters_all,
            'filter': snbFilterController.filter,
            'filtersLabel': snbFilterController.filtersLabel,
            'collections': snbFilterController.collections || '',
            'isMobile': snbFilterController.isMobile(),
            'isCluster': getCookie('isCluster') || '',
            'clusterCities': globals.clusterCities ? JSON.parse(atob(globals.clusterCities)) : '',
            'isCovidCity' : isCovidCity,
            getTinyMoneyFormat: getTinyMoneyFormat
        }

        // Experimental: POI filter is only applicable for Bangalore and Delhi
        /*
        if (![23, 27].includes(+snbFilterController.filter.data.c_id)) {
            console.log('inside');
            templateArray = templateArray.filter(name => name !== 'snbPOIFilter');
        }
        */

        if (!snbFilterController.filter.data.clusterCity.includes("23") && !snbFilterController.filter.data.clusterCity.includes("27")) {
            templateArray = templateArray.filter(name => name !== 'snbPOIFilter');
        }
        // Render the filter templates
       
        if (!snbFilterController.filterTemplateRendered) {
            templateArray.forEach(function(item, index) {
                const elTemplate = document.getElementById(item);
                if (elTemplate) {
                    var htmlData = _.template(elTemplate.innerHTML);
                    if(index == 0) { 
                        document.getElementById("snb-filters-wrap").innerHTML = htmlData(jsonFilterData);
                    } else {
                        document.getElementById("snbFiltersList").innerHTML += htmlData(jsonFilterData);
                    }
                }
            });
        }
        snbFilterController.filterTemplateRendered = true;

        var covidToolTip = document.getElementsByClassName('covid-19-tooltip')[0];
        var isShow = localStorage.getItem('isShow');
        if (isShow == null ) {
            if( covidToolTip && snbFilterController.filter.data.type === 'projects')
            {
                covidToolTip.style.setProperty("visibility", "visible", "important"); 
                localStorage.setItem('isShow', 1);
                setTimeout(function () {covidToolTip.style.setProperty("visibility", "hidden", "important")}, 10000);
            }
        }

        // Initialize the required components fro filters
        if (templateArray.indexOf('snbPOIFilter') > -1 && snbFilterController.apiReloadMap['snbPOIFilter']) {
            snbFilterController.initializePOIFilter();
        }

        // Fit the height of SNB filters
        snbFilterController.fitSearchFiltersIntoView();
    }

    // Initializes the POI filter component
    snbFilterController.initializePOIFilter = () => {
        let selectedPois = [];

        // Build the array of selected POI ids for POIFilterComponent
        if (snbFilterController.filter.data.poiIds instanceof Array) {
            selectedPois = snbFilterController.filter.data.poiIds.map((id, idx) => {
                return { id, poiName: snbFilterController.filter.data.poiNames[idx], selected: true };
            });
        }
        let cityidval = (snbFilterController.filter.data.clusterCity && snbFilterController.filter.data.clusterCity.length>=1) ? snbFilterController.filter.data.clusterCity[0] : snbFilterController.filter.data.c_id;

        let filterTmplArray = snbFilterController.filters_all.filtersList[snbFilterController.filter.data.type];
        // Initialize the POI filter component
        const poiFilterComponent = new POIFilterComponent(null, {
            selectedPois,
            cityId: cityidval,
            type: snbFilterController.filter.data.type,
            callApi: (filterTmplArray.indexOf('snbPOIFilter') > -1 && snbFilterController.apiReloadMap['snbPOIFilter']),
            topSuggestions: snbFilterController.toppoi,
            cityIds: snbFilterController.filter.data.clusterCity || []
        }); 

        poiFilterComponent.on('poi.topsuggestion', (toppoi = []) => {
            snbFilterController.toppoi = toppoi
        });
        
        snbFilterController.apiReloadMap['snbPOIFilter'] = false;
        // Watch for the POI selected event and load the search results on POI selection
        poiFilterComponent.on('poi.selected', (pois = []) => {
            snbFilterController.filter.data.poiIds = pois.map((poi) => poi.id);
            snbFilterController.filter.data.poiNames = pois.map((poi) => poi.poiName);
            callFilterChange();
        });
    };

    snbFilterController.initMA = function () {
        var initialSelect = [];
        if (isSet(snbFilterController.filter.data.ad_locality) && isSet(snbFilterController.filter.data.ad_locids)) {
            for (var i = 0; i < snbFilterController.filter.data.ad_locids.length; i++) {

                if (snbFilterController.filter.data.ad_locality[i].indexOf('.') > -1) {
                    var newlocArr = snbFilterController.filter.data.ad_locality[i].split('.');
                    var newArr = [];
                    for (var j = 0; j < newlocArr.length; j++) {
                        newArr[j] = newlocArr[j].charAt(0).toUpperCase() + newlocArr[j].slice(1);
                    }
                    snbFilterController.filter.data.ad_locality[i] = newArr.join('.');
                }
                let ad_loccityname = '';
                if (isSet(snbFilterController.filter.data.ad_loccitynames)) {
                    ad_loccityname = snbFilterController.filter.data.ad_loccitynames[i];
                } else {
                    ad_loccityname = snbFilterController.filter.data.c;
                }
                initialSelect.push({
                    title: snbFilterController.filter.data.ad_locality[i],
                    id: snbFilterController.filter.data.ad_locids[i],
                    city: ad_loccityname
                });
            }
        }
        MultiAutosuggest.selectInitial(initialSelect);
        MultiAutosuggest.allLocality();
    }

    function renderTabs() {
        var tabsData = _.template(document.getElementById("snbTabsTemplate").innerHTML);
        document.getElementById("snbTabs").innerHTML = tabsData({
            'filter': snbFilterController.filter,
            'totalCount': listingData.totalListings,
            'isMobile': snbFilterController.isMobile()
        });
    }

    function renderTabsMsite() {
        var tabsData = _.template(document.getElementById("snbfiltermsite").innerHTML);
        document.getElementById("snbtabmsite") && (document.getElementById("snbtabmsite").innerHTML = tabsData({
            'filter': snbFilterController.filter,
            'totalCount': listingData.totalListings,
            'isMobile': snbFilterController.isMobile(),
            'filtersLabel': snbFilterController.filtersLabel
        }))

        var snb_msite_tabcheck = document.getElementById("snbtabmsite");
        if (snbFilterController.filter.data.type == 'projects' || snbFilterController.filter.data.type == 'brokers') {
            if (snb_msite_tabcheck && !snb_msite_tabcheck.classList.contains('prject_v')) {
                snb_msite_tabcheck.classList.add('prject_v');
            }
        } else {
            if (snb_msite_tabcheck.classList.contains('prject_v')) {
                snb_msite_tabcheck.classList.remove('prject_v');
            }
        }

    }
    snbFilterController.covidPopUpClickHandler = (ev) => {
        ev.stopPropagation();
        const tmplStr = document.getElementById('snbCovidPopup').innerHTML;
        const tmpl = _.template(tmplStr);
        const measures = JSON.parse(atob(ev.target.dataset.measures));
        document.getElementById('covidPopupDoc').innerHTML = tmpl({'measures':measures});
        document.getElementById('covidPopupLink').click();
    }

    snbFilterController.TcClickHandler = (ev) => {
        ev.stopPropagation();
    }

    function getCovidCities() {
        const covidCities = globals.qhCovidCities ? globals.qhCovidCities.split(','): [];
        for(var x in covidCities)
        {
            covidCities[x] = covidCities[x].trim();
            covidCities[x]=  covidCities[x].substring(1,covidCities[x].length-1).toLowerCase();
        }
        return covidCities;
    }

    snbFilterController.init = function () {
        snbFilterController.gtmData = JSON.parse(atob(listingData.data));
        snbFilterController.currentPage = listingData.currentPage;
        snbFilterController.totalPages = listingData.totalPages;
        snbFilterController.tileViewType = listingData.viewType;
        snbFilterController.lazyLoadImages();
        snbFilterController.setFilter();
        if (snbFilterController.filter.data.type == 'brokers') {
            import('../assets/sass/web/modules/_broker-tile.scss')
        }
        if (snbFilterController.filter.data.type == 'pg') {
            import('../assets/sass/web/modules/_pg-tile.scss')
            import('../assets/sass/web/modules/_pg-grid-tile.scss')
            if (snbFilterController.isMobile()) {
                showWhatsappWidget();
            }
        }
        
        const covidCities = getCovidCities();
        if(covidCities.includes(snbFilterController.filter.data.c.toLowerCase())){
            isCovidCity = true;
        }
        else{
            isCovidCity = false;
        }
       
        snbFilterController.filterCheck();
        reRenderFilter();
        searchActionService.setCatIntent(snbFilterController.filter.data)
        if (listingData.viewType === 'grid' && isLargeScreen()) {
            if (document.getElementById('gridview_wrap') && document.getElementById('gridview_wrap').classList.contains('hide')) {
                document.getElementById('gridview_wrap').classList.remove('hide');
            }
        }

        visibilityForViewTypeForm();

        if (listingData.totalListings == 0) {
            addEvent('noAction-RealestateResponsiveUI', 'SnB_' + snbFilterController.searchAction + '_Real-Estate', 'zero_results_found');
        }
        else {
            addEvent('listing-RealestateResponsiveUI', 'SnB_' + snbFilterController.searchAction, "");
        }
        multi_auto.addEventListener('keyup', (event) => {
            MultiAutosuggest.handleKeypress()
        })

        // Viewport resize watcher
        snbFilterController.watchForViewportResize();
    };

    function isLargeScreen() {
        return (window.innerWidth > 1440);
    }

    function renderSort() {
        var sortHtmlData = _.template(document.getElementById("snbSortFilters").innerHTML);
        if (document.getElementsByClassName("sortline")[0]) {
            document.getElementsByClassName("sortline")[0].innerHTML = sortHtmlData({
                'filters_all': snbFilterController.filters_all,
                'filter': snbFilterController.filter,
                'filtersLabel': snbFilterController.filtersLabel
            })
        }

        if (document.getElementById("Popularity")) {
            document.getElementById("Popularity").classList.add("active");
        }
    }

    snbFilterController.renderBreadCrumbsTpl = function (listingData) {
        const snbBreadCrumbs = JSON.parse(atob(listingData.snbBreadCrumbs));
        const breadCrumbTemplate = document.getElementById('snbBreadCrumbTpl');
        var htmlData = _.template(breadCrumbTemplate.innerHTML);
        document.getElementById("snb-seo-breadcrumb").innerHTML = htmlData({snbBreadCrumbs});
    }

    function loadFilterData(scrollFlag = 'reload', type, options = {}) {
        if (scrollFlag === 'reload') {
            snbFilterController.filter.data.isFilterChanged = true;
            $(window).scrollTop(0);
            showSpinner();
        } else {
            snbFilterController.filter.data.isFilterChanged = false;
            $(".scroll-page").remove();
            $(".loader").removeClass('hide');
        }
        if (snbFilterController.filter.data.type == 'brokers') {
            import('../assets/sass/web/modules/_broker-tile.scss')
        }
        if (snbFilterController.filter.data.type == 'pg') {
            import('../assets/sass/web/modules/_pg-tile.scss');
            import('../assets/sass/web/modules/_pg-grid-tile.scss');
        }
        let staticSanitise = document.getElementById("staticSanitisedFilter");
        let headingSanitised = document.getElementById("headingSanitised");
        $.ajax({
            url: '/homes/re2/property/snbFilterResultData?aj=1',
            type: "POST",
            data: {
                'filter': snbFilterController.filter.data,
                'page': snbFilterController.currentPage,
                'clusterCity': (isSet(globals.clusterCities)) ? JSON.parse(atob(globals.clusterCities)) : {},
                'size': snbFilterController.size,
                'scrollFlag': scrollFlag,
                'locationPath': location.origin + location.pathname
            },
            beforeSend: function (request) { request.setRequestHeader("Accept", isWebpSupport); },
            success: function (data) {
                staticSanitise?staticSanitise.removeAttribute("disabled"):'';
                headingSanitised?headingSanitised.style.opacity = 1:'';
                snbFilterController.filters_all.meta.expanded_mobile = false;
                if (scrollFlag === 'reload') {
                    hideSpinner();
                    $("#resultContent").html(data);
                    if(snbFilterController.filter.data.type === 'projects' && getterSetter.get('snbProjBannerData')) {
                        $("#snb_project_banner").html(getterSetter.get('snbProjBannerData'));
                    }
                    if (snbFilterController.filter.data.type != 'brokers' && !snbFilterController.isMobile()) {
                        getSnbListingCount();
                    }
                    snbFilterController.setMetaDescription(globals.seoMetaDescription);
                    snbFilterController.setMetaTitle(globals.seoTitle);
                    if (!snbFilterController.isMobile()) {
                        snbFilterController.renderBreadCrumbsTpl(listingData);
                    }

                    let viewType = listingData.viewType;

                    if (viewType === 'grid') {
                        if (document.getElementById('listingViewMode').classList.contains('listviewtile')) {
                            document.getElementById('listingViewMode').classList.remove('listviewtile')
                        }
        
                        if (!document.getElementById('listingViewMode').classList.contains('gridviewtile')) {
                            document.getElementById('listingViewMode').classList.add('gridviewtile')
                        }
                    } else if(viewType === 'list'){
                        if (!document.getElementById('listingViewMode').classList.contains('listviewtile')) {
                            document.getElementById('listingViewMode').classList.add('listviewtile')
                        }
        
                        if (document.getElementById('listingViewMode').classList.contains('gridviewtile')) {
                            document.getElementById('listingViewMode').classList.remove('gridviewtile')
                        }
                    }

                    searchActionService.setCatIntent(snbFilterController.filter.data)
                    headerDropDown()
                    snbFilterController.filterCheck();
                    reRenderFilter();
                    if(snbFilterController.filter.data.type != 'brokers') {                
                        if ([...document.getElementsByClassName('quikr-sort-link')].length > 0) {
                            let sortLink = document.getElementsByClassName('quikr-sort-link')[0];
                            sortLink.classList.add('active');
                        }
                        let ulChildren = '';
                        if(document.getElementsByClassName("quikr-sort-menu").length > 0){
                            ulChildren = document.getElementsByClassName("quikr-sort-menu")[0].children;
                            for(let i=0;i<ulChildren.length; i++){
                                let li = document.getElementById(ulChildren[i].id);
                                if(ulChildren[i].id === type && !li.classList.contains("active"))
                                {
                                    li.classList.add("active");
                                }
                                switch(type){
                                    case "pa": type = "Price: Low to High";
                                        break;
                                    case "pd": type = "Price: High to Low";
                                        break;
                                    case "dd": type = "Recently Added";
                                        break;
                                    default: type = "Popularity";
                                        break;      
                                }
                                for(let i=0;i<ulChildren.length; i++){
                                    let li = document.getElementById(ulChildren[i].id);
                                    if(ulChildren[i].id === type && !li.classList.contains("active"))
                                    {
                                        li.classList.add("active");
                                    }
                                    else{
                                        li.classList.remove("active");
                                    }
                                };
                            }
                        }
                        
                    }
                    snbFilterController.initMA();
                    // setH1(snbFilterController.filter);
                } else {
                    $(".paginationTabs").hide();
                    $(".loader").addClass('hide');
                    $("#scroll-main-div").append(data);
                }

                if (snbFilterController.filter.data.type === 'brokers') {
                    if (!document.getElementById('snbMainContent').classList.contains('broker')) {
                        document.getElementById('snbMainContent').classList.add('broker');
                    }
                }

                if (snbFilterController.filter.data.type !== 'brokers' && document.getElementById('snbMainContent').classList.contains('broker')) {
                    document.getElementById('snbMainContent').classList.remove('broker');
                }

                if (parseInt(snbFilterController.filter.data.c_id) === 33) {
                    snbFilterController.showPremium();
                }
                multi_auto.addEventListener('keyup', (event) => {
                    MultiAutosuggest.handleKeypress()
                })
                let listingDatas = (JSON.parse(atob(listingData.data)));
                var TotalListingLength = isSet(listingDatas) ? listingDatas.length : 0;
                if (isSet(listingDatas)) {
                    if (connectionCheck()) {
                        var bannerServices = import('./snb/services/bannerServices.js')
                        var projectLocalityRandom = ['Project', 'Locality'];
                        var currentRandom = projectLocalityRandom[Math.floor(Math.random() * projectLocalityRandom.length)];
                        if (snbFilterController.tileViewType == 'list') {
                            if (TotalListingLength > 10) {
                                bannerServices.then(someModule => someModule.snbTopLocalityBanner(snbFilterController));
                                bannerServices.then(someModule => someModule.snbLocalityBanner(snbFilterController));
                            }
                        } else {
                            bannerServices.then(someModule => someModule.snbTopLocalityBanner(snbFilterController));
                            if (currentRandom === 'Locality' && snbFilterController.filter.data.type !== "pg") {
                                bannerServices.then(someModule => someModule.snbLocalityBanner(snbFilterController));
                            } else {
                                bannerServices.then(someModule => someModule.snbProjectBanner(snbFilterController));
                            }
                        }
                        import('./services/adServices').then(module => module.adServicesInit());
                        showBottomBanner();
                    }
                    import('./snb/services/snbBannerService').then(module => module.getSnbBanners(lozad, snbFilterController));
                }
                snbFilterController.currentPage = listingData.currentPage;
                snbFilterController.totalPages = listingData.totalPages;
                snbFilterController.filter.listingCount = listingData.totalListings;
                trackPremiumAds();
                snbFilterController.lazyLoadImages();
                snbFilterController.setViewType();

                // To avoid soft 404, store the URL only if there are any listings  
                if (TotalListingLength > 0) {
                    snbFilterController.storeURLAsSitemapResource();
                }

                if (TotalListingLength > 10) {
                    snbFilterController.topCollections();
                }
                if (listingData.totalListings == 0) {
                    addEvent('noAction-RealestateResponsiveUI', 'SnB_' + snbFilterController.searchAction + '_Real-Estate', 'zero_results_found');
                } else {
                    // Initialize adImpression observer on new listings
                    adImpressionService.initObserver('.ad-imprsn-js');

                    if (snbFilterController.filter.data.type != 'projects') {
                        addEvent('listing-RealestateResponsiveUI', 'SnB_' + snbFilterController.searchAction, "");
                    }
                    //Reload Ads
                    if (connectionCheck() && QDFP) {
                        var elements = $('[dfp]');
                        if (isSet(elements) && snbFilterController.tileViewType == 'list') {
                            QDFP && QDFP.getAdForDivId('Snb_aside_2', 1);
                            $.each(elements, function (index, value) {
                                var viewType = $(this).attr("list")
                                var index = $(this).attr("index")
                                if (index) {
                                    QDFP && QDFP.getAdForDivId($(this).attr("id"), index)
                                }
                                if (viewType == 'list') {
                                    window.QDFP.getAdForDivId($(this).attr("id"), 5)
                                }
                            });
                        } else {
                            $.each(elements, function (index, value) {
                                var index = $(this).attr("index")
                                if (index) {
                                    QDFP.getAdForDivId($(this).attr("id"), index)
                                } else {
                                    QDFP.getAdForDivId($(this).attr("id"))
                                }
                            });
                        }
                    }
                }

                globals.H1 = setitleH1(snbFilterController.filter);
                snbFilterController.lazyLoadSnbPage();
                // var last = snbFilterController.filter.data.accordionGroup;
                // if (last != null) {
                //     $("#QHaccordion .collapse").removeClass('in');
                //     $("#" + last).addClass('in');
                // }
                snbFilterController.getSnbAdImpression(globals.cL);
                resumeAd();
                resentSearch();
            },
            error: function (data) {
                staticSanitise.removeAttribute("disabled");
                headingSanitised.style.opacity = 1;
            }
        });
    };

    snbFilterController.isSEOApplicableFilterApplied = (filters) => {
        // const isLocalityApplicable = !isSet(filters.ad_locids) || 1 === filters.ad_locids.length;
        // const isReraFilterApplied = isSet(filters.isReraApproved);
        // const isBudgetFilterApplied = isSet(filters.price) && ((isSet(filters.price.min) && +filters.price.min > 0) || (isSet(filters.price.max) && +filters.price.max > 0));
        // const isAreaFilterApplied = isSet(filters.area) && ((isSet(filters.area.min) && +filters.area.min > 0) || (isSet(filters.area.max) && +filters.area.max > 0));
        // const isPostedByFilterApplied = isSet(filters.posted_by) && 1 === filters.posted_by.length;
        return true;
        // return isLocalityApplicable && (isReraFilterApplied || isBudgetFilterApplied || isAreaFilterApplied || isPostedByFilterApplied);
    };

    snbFilterController.storeURLAsSitemapResource = () => {
        // Check if the SEO applicable filters are applied
        if (snbFilterController.isSEOApplicableFilterApplied(snbFilterController.filter.data)) {
            // SEO url must not include any query params
            const url = new URL(window.location.href);
            const seoUrl = url.origin + url.pathname;

            $.post('/homes/re2/sm/resources', { url: seoUrl });
        }
    };

    snbFilterController.setPageNo = function (pageNo) {
        $(window).scrollTop(0);
        snbFilterController.currentPage = pageNo;
        loadFilterData();
        setPageUrl(pageNo);
        fireScrollDataLoadGa(snbFilterController.filter, pageNo);
    };


    snbFilterController.changeCat = function (type) {
        var prevType = snbFilterController.filter.data.type;
        if (!isSet(type)) {
            return;
        }
        snbFilterController.filterTemplateRendered = false;
        if(type!='pg' && snbFilterController.filter.data.isGrabHouse){
            snbFilterController.filter.data.isGrabHouse='';
        }
        
        snbFilterController.filter.data.type = type;
        snbFilterController.filter.data.price = {};
        snbFilterController.filter.data.price.min = '-1';
        snbFilterController.filter.data.price.max = '-1';

        if(type !== 'projects'){
            snbFilterController.filter.data.hasCovidMeasures = '';
        }

        if (type == 'pg' || type == 'brokers') {
            snbFilterController.filter.data.property_type = [];
        }
        if (type == 'rent' && snbFilterController.filter.data.property_type.indexOf('rp') > -1) {
            snbFilterController.filter.data.property_type.splice(snbFilterController.filter.data.property_type.indexOf('rp'), 1);
        }
        if (type === 'brokers' || type === 'projects') {
            snbFilterController.filter.data.available_from = '';
        }

        if ((prevType == 'rent' || prevType == 'pg') && type == 'buy') {
            snbFilterController.filter.data.available_from = '';
        }
        if ((type == 'rent' || type == 'pg') && prevType == 'buy') {
            snbFilterController.filter.data.available_from = '';
        }

        updateFiltersLabel('property_type');
        //snbFilterController.changeViewType(cookieService.getCookie('viewType'));
        var tab = 'click_' + type + '_tab';
        addEvent('button-RealestateResponsiveUI', 'SnB_' + snbFilterController.searchAction + '_Real-Estate', tab);
        onFilterChange();

        if(type === 'buy' || type === 'projects'){
            $("#whatsappInit").removeClass('hide');
        } else {
            $("#whatsappInit").addClass('hide');
        }
        snbFilterController.hideShowGender(type);
        visibilityForViewTypeForm();
        if (type === 'projects') {
            if (snbFilterController.tileViewType == 'grid') {
                setCookie('viewType', 'list');
                snbFilterController.tileViewType = 'list';
            }
        }
        setRecentSearch();
        addEvent('RealEstate', 'QH_snb_' + prevType, 'QH_snb_' + prevType + '_header3_' + snbFilterController.filter.data.type + '_click');
       
    };

    function visibilityForViewTypeForm() {
        var type = snbFilterController.filter.data.type || listingData.type
        const elViewToggle = document.getElementById('gridviewbtn');
        const elResultsView = document.getElementsByClassName('view-type-form');

        if (elViewToggle && elResultsView.length > 0) {
            if (snbFilterController.isMobile()) {
                elResultsView[0].style.display = 'none';
            }
            else {
                elResultsView[0].style.display = 'block';
            }

            elViewToggle.style.display = 'block'
        }

    }
    snbFilterController.changeViewType = function (viewType) {
        snbFilterController.currentPage = snbFilterController.currentPage;
        viewType = viewType || defaultViewType;
        if (document.getElementById('listingViewMode')) {
            if (viewType === 'grid') {
                if (document.getElementById('listingViewMode').classList.contains('listviewtile')) {
                    document.getElementById('listingViewMode').classList.remove('listviewtile')
                }

                if (!document.getElementById('listingViewMode').classList.contains('gridviewtile')) {
                    document.getElementById('listingViewMode').classList.add('gridviewtile')
                }
            } else {
                if (!document.getElementById('listingViewMode').classList.contains('listviewtile')) {
                    document.getElementById('listingViewMode').classList.add('listviewtile')
                }

                if (document.getElementById('listingViewMode').classList.contains('gridviewtile')) {
                    document.getElementById('listingViewMode').classList.remove('gridviewtile')
                }
            }
        }

        addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type , 'QH_snb_'+snbFilterController.filter.data.type+'_header3_'+viewType+'_click');
        setCookie('viewType', viewType.toLowerCase());
        snbFilterController.tileViewType = viewType;
        loadFilterData();
    };

    snbFilterController.setViewType = function () {
        if (snbFilterController.filter.data.type == 'brokers' || snbFilterController.filter.data.type == 'projects') {
            $("#viewTypes").addClass('hide');
            $('#map_wrapper').css('display', 'none');
        } else {
            $("#viewTypes").removeClass('hide');
        }
    };

    snbFilterController.topCollections = function () {
        if (snbFilterController.filter.data.type != 'projects' && listingData.viewType === 'list' && listingData.viewType != 'grid' && snbFilterController.filter.data.type != 'brokers' && !snbFilterController.isMobile()) {
            $.ajax({
                url: "/homes/re2/topCollections/" + globals.pageType + "?aj=1",
                method: "GET",
                success: function (data) {
                    if (snbFilterController.currentPage <= 1) {
                        $("#snbCollection").html(data);
                    } else {
                        $("#snbCollectionv2").html(data);
                    }
                }
            });
        }
    };

    function initView(initialRender, viewType) {
        var elem = $('.snb-listings');
        if (!snbFilterController.isMobile()) {
            viewType = getCookie('viewType') || defaultViewType;
        }
    }

    snbFilterController.hideShowGender = function (type) {
        var currentElement = $('#pgGenderDiv');
        'pg' == type.toLowerCase() ? (currentElement.hasClass('hidden') ? currentElement.removeClass('hidden') : '') : currentElement.addClass('hidden');
    };

    snbFilterController.selectSort = function (type) {
        if (snbFilterController.filter.data.sortby === type) {
            snbFilterController.filter.data.sortby = '';
        } else {
            snbFilterController.filter.data.sortby = type;
        }
        updateFiltersLabel('sortby');
        onFilterChange(type);
        addEvent('checkbox-RealestateResponsiveUI', 'SnB_Filter', 'select_sort_' + snbFilterController.filtersLabel.sortby);
        var sort_value = getSortValue(type);
        addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type, 'QH_snb_' + snbFilterController.filter.data.type + '_header3_sort_' + sort_value + '_click');
    };
    snbFilterController.getSnbTabPath = function (type) {
        var tempFilter = cloneObj(snbFilterController.filter, true);
        tempFilter.data.type = type;
        if (tempFilter.data.type != 'projects') {
            if (tempFilter.data.collection_id) {
                tempFilter.data.collection_id = '';
                tempFilter.data.collection_name = '';
            }
        }
        return getSnbTabPath(tempFilter);
    };

    snbFilterController.closedFilter = function () {
        document.body.classList.remove("modal-open");
        var d = document.getElementById("snb-filters-wrap");
        d.className = "snb-filters-wrap mfilterhide";
        snbFilterController.filters_all.meta.expanded_mobile = false;
    }

    snbFilterController.showHideFilterMobile = function (id) {
        document.body.classList.add("modal-open");
        var d = document.getElementById("snb-filters-wrap");
        if (snbFilterController.filters_all.meta.expanded_mobile) {
            d.className = "snb-filters-wrap mfilterhide";
        }
        else {
            d.className = "snb-filters-wrap";
        }
        snbFilterController.filters_all.meta.expanded_mobile = !snbFilterController.filters_all.meta.expanded_mobile;

        if (id) {
            document.getElementById(id).scrollIntoView(true);
            scrollElmVert(document.getElementById(id), -50);
        }
    };

    snbFilterController.toggleMobileView = function () {
        $('#map_wrapper').css('display', 'block');
        $('#map_wrapper').addClass("mapviewbox");
        if (snbFilterController.mapLoad) {
            loadGoogleMaps();
        }
        snbFilterController.mapLoad = false;
        $('#mobileListView').toggle();
        $('#mobileMapView').toggle();
        if ($('#snbListingWrap').hasClass("mapshow")) {
            $('#snbListingWrap').removeClass("mapshow");
            $('#map_wrapper').removeClass('rightzero');
        } else {
            $('#snbListingWrap').addClass("mapshow");
            $('#map_wrapper').addClass('rightzero');
        }
        if ($(".module-listing-tiles").scrollTop()) {
            $('.goto-top:hidden').stop(true, true).fadeIn();
        } else {
            $('.goto-top').stop(true, true).fadeOut();
        }
    };

    snbFilterController.closeAdPopup = function () {
        $('.dfpadpopup').attr('style', 'display: none');
    };

    snbFilterController.tileGa = function (page, pos, adStyle) {
        var isPremium = (typeof adStyle === 'undefined'
            || (typeof adStyle !== 'undefined'
                && ((typeof adStyle === 'string' && adStyle === "B")
                    || (Array.isArray(adStyle) && adStyle.indexOf("B") >= 0)
                ))) ? false : true
        var premiuimLabel = isPremium ? '_premium' : '';
        var label = 'pg' + (parseInt(page)) + premiuimLabel + '_position' + (parseInt(pos) + 1);
        addEvent('listing-RealestateResponsiveUI', 'SnB_' + capitalizeFirstLetter(snbFilterController.searchAction), label);
    };

    function fireScrollDataLoadGa(filter, page) {
        addPageView(
          "SnB_nextpage",
          "RealEstate",
          `${capitalizeFirstLetter(filter.data.cat)}_${capitalizeFirstLetter(
            filter.data.c
          )}`,
          capitalizeFirstLetter(filter.data.type),
          page
        );
    }

    snbFilterController.fireGAForPagination = function (label) {
        addEvent('button-RealestateResponsiveUI', 'SnB_' + snbFilterController.searchAction + '_Real-Estate', label);
    };

    window.snbPremiumGA = (label) => {
        addEvent('RealEstate', 'premiumAd_click', label + '_' + snbFilterController.tileViewType + '_' + snbFilterController.filter.
            data.type + '_snb');
        addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type, 'QH_snb_' + snbFilterController.filter.data.type + '_sl_ad_click');
    }

    snbFilterController.lazyLoadImages = function () {
        lozad('.lozad', {
            root: void 0,
            rootMargin: "0px 0px 350px 0px",
            threshold: .01,
            load: function (el) {
                el.src = el.dataset.src;
            }
        }).observe()
    };

    snbFilterController.lazyLoadSnbPage = function () {
        lozad('.scroll-page', {
            load: function (el) {
                let pageNo = el.id;
                snbFilterController.currentPage = pageNo;
                loadFilterData('scrollPage');
                fireScrollDataLoadGa(snbFilterController.filter, pageNo);
            }
        }).observe()
    };

    snbFilterController.setMetaTitle = function (val) {
        let title = atob(val);
        var tag = document.getElementById("meta_title");
        if (tag) {
            tag.setAttribute("content", title);
        }
        if (listingData) {
            if (listingData.type === 'projects' || listingData.type === 'buy') {
                title = listingData.totalListings + ' ' + title;
            }
        }

        document.title = title;
    };

    snbFilterController.setMetaDescription = function (val) {
        let desc = atob(val);
        let tag = document.getElementById("meta_description");
        if (tag) {
            tag.setAttribute("content", desc);
        }
    };
    snbFilterController.showPremium = function () {
        if (getterSetter.get('loginstatus')) {
            var user_Details = getterSetter.get('loginData');
            let loginUserId = user_Details.data.user.id;
            if ((loginUserId && snbFilterController.tileViewType === 'list' && snbFilterController.filter.data.type != 'projects' && snbFilterController.filter.data.type != 'brokers') || (loginUserId && snbFilterController.tileViewType == 'map' && snbFilterController.isMobile() && snbFilterController.filter.data.type != 'projects' && snbFilterController.filter.data.type != 'brokers')) {
                var data = {
                    "id": loginUserId
                };

                $.ajax({
                    url: "/homes/re2/showsnbPremium?aj=1",
                    method: "POST",
                    data: data,
                    success: function (data) {
                        $("#snbPremium").html(data);
                        document.querySelector(".closebox").addEventListener('click', togglePremiumSNB);
                    }
                });
            }
        }

    }

    snbFilterController.translateR = 0;
    snbFilterController.carouselControl =(angle)=>{
        var offsetWidth = document.getElementsByClassName("loaclity_slider")[0].offsetWidth + 15;
        let length = snbFilterController.topLocalityBanner;
        if(angle == 'right' && counter<=length-3){
            snbFilterController.translateR = snbFilterController.translateR + -offsetWidth;
            document.getElementById('slider_row_top_locality').style.transform = "translateX("+snbFilterController.translateR+"px)";
            counter = counter+1
        }
        if(angle == 'left' && counter>1){
            snbFilterController.translateR = snbFilterController.translateR + offsetWidth;
            document.getElementById('slider_row_top_locality').style.transform = "translateX("+snbFilterController.translateR+"px)";
            counter = counter-1
        }

    }

    snbFilterController.filterCheck = () => {
        snbFilterController.filterSelect = false;
        if (snbFilterController.filter.data.ad_locality.length > 0) {
            snbFilterController.filterSelect = true;
        }
        if (snbFilterController.filter.data.property_type.length > 0) {
            snbFilterController.filterSelect = true;
        }
        if (snbFilterController.filter.data.price.min > 0 || snbFilterController.filter.data.price.max > 0) {
            snbFilterController.filterSelect = true;
        }
        if (snbFilterController.filter.data.area.min > 0 || snbFilterController.filter.data.area.max > 0) {
            snbFilterController.filterSelect = true;
        }
        if (snbFilterController.filter.data.bedrooms.length > 0) {
            snbFilterController.filterSelect = true;
        }
        if (snbFilterController.filter.data.posted_by.length > 0) {
            snbFilterController.filterSelect = true;
        }

    }
    snbFilterController.getSnbAdImpression = function (listingId) {
        if (snbFilterController.filter.data.type !== 'projects') {
            var adId = { ids: [] }
            var count = 18;
            if (snbFilterController.currentPage != 1) {
                var count = 6;
            }
            var adList = JSON.parse(atob(listingId));
            for (var i = 0; i < count; i++) {
                if (adList[i] && adList[i][0]) {
                    adId.ids.push(adList[i][0]);
                }
            }
            $.ajax({
                url: "/homes/re2/premiumAdImpression?aj=1",
                method: "POST",
                data: adId,
                success: function (e) {
                    for (var i = 0; i < Object.keys(e).length; i++) {
                        $('#premiumimpression_' + Object.keys(e)[i]).html(e[Object.keys(e)[i]])
                    }
                }
            });
        }
    }

    snbFilterController.selectSuggestion = (e, id) => {
        // Highlight the selected option
        MultiAutosuggest.selectSuggestion(e, id);
    }
    snbFilterController.unselectSuggestion = (e, id) => {
        // Highlight the selected option
        MultiAutosuggest.unselectSuggestion(id);
    }

    snbFilterController.exactPrice = function (price) {
        return currencyFilter(price);
    }

    snbFilterController.fitSearchFiltersIntoView = function () {
        const elSNBNavbar = document.querySelector('#snb-navbar');
        const elSNBListingCount = document.querySelector('#snblistingCount');
        const elSNBFiltersRail = document.querySelector('#QHaccordion');
        elSNBFiltersRail.style.height = (window.innerHeight - elSNBNavbar.clientHeight) - (elSNBListingCount ? elSNBListingCount.clientHeight : 0) + 'px';
    };

    snbFilterController.watchForViewportResize = function () {
        window.addEventListener('resize', this.fitSearchFiltersIntoView);
    };

    snbFilterController.filterSelectionAutoScrollHandler = function ({ target }) {
        // Add handler to scroll the filter into view
        const elSNBNavbar = document.querySelector('#snb-navbar');
        const elSNBListingCount = document.querySelector('#snblistingCount');
        const elSNBFilters = document.querySelector('#QHaccordion');

        if (target.classList.contains('quikr-dropdown-link')) {
            // Since .quikr-dropdown-link doesn't have a unique id, use aria-controls attribute
            snbFilterController.elIdLastAppliedFilter = target.getAttribute('aria-controls');
            if (target.offsetTop > 0) {
                $(elSNBFilters).animate({
                    scrollTop: (target.offsetTop - elSNBNavbar.clientHeight) - (elSNBListingCount ? elSNBListingCount.clientHeight : 0)
                });
            }
        }
    };

    snbFilterController.attachFilterSelectionAutoScroll = function () {
        // Add handler to scroll the filter into view
        const elSNBNavbar = document.querySelector('#snb-navbar');
        const elSNBFilters = document.querySelector('#QHaccordion');
        const elLastFilterTarget = snbFilterController.elIdLastAppliedFilter &&
            elSNBFilters.querySelector(`[aria-controls="${snbFilterController.elIdLastAppliedFilter}"]`);

        elSNBFilters.removeEventListener('click', snbFilterController.filterSelectionAutoScrollHandler);
        elSNBFilters.addEventListener('click', snbFilterController.filterSelectionAutoScrollHandler, false);

        if (elLastFilterTarget && elLastFilterTarget.offsetTop > 0) {
            const $lastAppliedFilterAccordion = $(elLastFilterTarget);

            // setTimeout(() => {
            //     // Expand the last applied filter
            //     $lastAppliedFilterAccordion.collapse('show');
            //     $lastAppliedFilterAccordion.parent().siblings('.collapse').addClass('in');

            //     // Scroll to the last applied filter
            //     $(elSNBFilters).animate({
            //         scrollTop: (elLastFilterTarget.offsetTop - elSNBNavbar.clientHeight)
            //     }, 200);
            // }, 100);
        }
    };

    snbFilterController.hideWhatsappWidget = function () {
        $('#whatsappwidget').addClass('hide');
    }

    snbFilterController.adImpressionClickHandler = (el) => {
        const data = adImpressionService.getData(el);
        data.event_name = 'AD_CLICK_IMPRESSIONS';
        adImpressionService.add(data).then(isAdded => {
            adImpressionService.publish();
        });
    };

    window.addEventListener('popstate', function (event) {
        if (event.state) {
            snbFilterController.setFilter();
            onFilterChange();
        }
    }, false);

    window.gaEventSportLight = () => {
        snbPremiumGA('spotlight')
    }

    return snbFilterController;
}();


function togglePremiumSNB(event) {
    $(".premiumbox").toggleClass("preopen");
    $(".adbox").slideToggle(300);
}

function showBottomBanner() {
    if (snbFilterController.currentPage > 1) {
        $("#dfpadAside").removeClass('hide');
        setTimeout(function () {
            $('.banner_add').addClass('data_show');
        }, 6000);

        setTimeout(function () {
            $('.banner_add').removeClass('data_show');
        }, 12000);
    }
}

function showWhatsappWidget() {
    setTimeout(function () {
        $('#whatsappwidget').removeClass('hide');
        addEvent('SNB_Real-QuikrPG_Assured', 'SNB_Real-QuikrPG_Assured_whatsapp-notification', 'SNB_Real-QuikrPG_Assured_whatsapp-notification_init');
    }, 5000);

    setTimeout(function () {
        $('#whatsappwidget').addClass('hide');
    }, 10000);

}

function getSnbListingCount() {
    let countType = (snbFilterController.filter.data.type == 'projects') ? 'listings' : 'projects';
    $.ajax({
        url: '/homes/re2/getSnbListingCount?aj=1',
        type: "POST",
        data: {
            'filter': snbFilterController.filter.data,
            'page': snbFilterController.currentPage,
            'locationPath': location.origin + location.pathname,
            'countType': countType
        },
        success: function (data) {
            if (isSet(data)) {
                let typeText = (snbFilterController.filter.data.type == 'projects') ? 'Properties' : 'Projects';
                let linkval = (snbFilterController.filter.data.type == 'projects') ? snbFilterController.getSnbTabPath('buy') : snbFilterController.getSnbTabPath('projects');
                $("#snblistingCount").removeClass('hide');
                $("#snblistingCount").html(`<a href="${linkval}" class="switchtopr">Switch to ${data} ${typeText} <i class="icon icon-right"></i></a>`);
            } else {
                $("#snblistingCount").addClass('hide');
            }
            snbFilterController.fitSearchFiltersIntoView();
        }
    });
}

function getCCR() {
    setTimeout(() => {
        if (getterSetter.get('loginstatus') && snbFilterController.filter.data.type != 'brokers' && snbFilterController.isMobile() && getterSetter.get('loginstatus')) {
            $.ajax({
                url: '/homes/re2/getCCR?aj=1',
                type: "GET",
                success: function (data) {
                    if (isSet(data)) {
                        $("#snb_ccr").html(data);
                    }
                }
            });
        }
    }, 3000);
}

(function () {
    snbFilterController.init();
    window.addEventListener("load", () => {
        var jqLib = tpapi.getAttribute('data-src')
        var externalLib = document.createElement('script');
        externalLib.async = true;
        externalLib.type = 'text/javascript';
        externalLib.src = jqLib;
        document.body.appendChild(externalLib);
        externalLib.onload = function () {
            require('./controller/header.js')
            let listingDatas = (JSON.parse(atob(listingData.data)));
            var TotalListingLength = isSet(listingDatas) ? listingDatas.length : 0;
            if (connectionCheck() && isSet(listingDatas)) {
                snbFilterController.setViewType();
                var bannerServices = import('./snb/services/bannerServices.js')
                var projectLocalityRandom = ['Project', 'Locality'];
                var currentRandom = projectLocalityRandom[Math.floor(Math.random() * projectLocalityRandom.length)];
                if (snbFilterController.tileViewType == 'list') {
                    if (TotalListingLength > 10) {
                        bannerServices.then(someModule => someModule.snbTopLocalityBanner(snbFilterController));
                        bannerServices.then(someModule => someModule.snbProjectBanner(snbFilterController));
                    }
                } else {
                    if (currentRandom === 'Locality') {
                        bannerServices.then(someModule => someModule.snbTopLocalityBanner(snbFilterController));
                    } else if (currentRandom === 'Locality' && snbFilterController.filter.data.type !== "pg") {
                        bannerServices.then(someModule => someModule.snbLocalityBanner(snbFilterController));
                    } else {
                        bannerServices.then(someModule => someModule.snbProjectBanner(snbFilterController));
                    }
                }
                showBottomBanner();
            }
            import('./snb/services/snbBannerService').then(module => module.getSnbBanners(lozad, snbFilterController));
            snbFilterController.initMA();
            import('./controller/shortlistController').then(module => module.getAllShortlistedItems());
            snbFilterController.getSnbAdImpression(globals.cL);
            trackPremiumAds();
            snbFilterController.lazyLoadSnbPage();
            import('./reply/snbReplyservice');
            import('./reply/whatsappReplyService');
            snbFilterController.setViewType();
            import('./services/adServices').then(module => module.adServicesInit());
            if (parseInt(snbFilterController.filter.data.c_id) === 33) {
                snbFilterController.showPremium();
            }
            if (TotalListingLength > 10) {
                snbFilterController.topCollections();
            }
            resumeAd();
            setRecentSearch();
            setTimeout(function () {
                if ($("#snb_project_banner").length) {
                    getterSetter.set('snbProjBannerData', $("#snb_project_banner").html());
                }
            }, 2000);
            if (isSet(snbFilterController.gtmData)) {
                snbGtm(snbFilterController.gtmData);
            }
            const qhmrCities = ['bangalore', 'noida', 'gurgaon', 'hyderabad', 'delhi', 'www'];
            if (qhmrCities.includes(snbFilterController.filter.data.c.toLowerCase()) && snbFilterController.filter.data.type === 'pg' && !isSet(sessionStorage.getItem('snbPopUp'))) {
                setTimeout(() => {
                    if (!$('#myModal, #commonpopup, #whatsAppFormChange').hasClass('in')) {
                        popup();
                    }
                }, 10000);
            }
            let snbLeadSubmit = '#submitSnbLead';
            delegate(document, 'click', snbLeadSubmit, () => {
                submitLeadFn(snbLeadSubmit);
            });
            searchSuggest();
            let targetNode = document.getElementById('Snb_topbanner');
            let dfpAdNode = document.getElementById('Snb_top_1');
            if (!targetNode && dfpAdNode) {
                var observer = new MutationObserver(() => {
                    if (dfpAdNode.children) {
                        if (document.getElementById('dHeaderAPI')) {
                            document.getElementById('dHeaderAPI').classList.add('vap_slot')
                        }
                    }
                });
                observer.observe(dfpAdNode, { attributes: true });
            }
            if (snbFilterController.filter.data.type != 'brokers' && !snbFilterController.isMobile()) {
                getSnbListingCount();
            }
            getCCR();
            // quickLinks.renderInitialQuickLinks();
            // Initialize Ad Impression observer
            adImpressionService.initObserver('.ad-imprsn-js');

            // View in App
            ViewInApp({
                city: snbFilterController.filter.data.c,
                pageType: 'SnB',
                category: 'realestate',
                subcategory: snbFilterController.filter.data.cat
            });

            appDnlModalController.init();
            // Market talk popup
            if (MarketTalkPopupController.isEnabled() && snbFilterController.isMobile()) {
                MarketTalkPopupController.incrementSessionCount('home');
                MarketTalkPopupController.init();
            }
        }
        /**
        //QPUSH NOTOFICATION
        WebPush.prototype.showGenericPreRequestModal = showGenericPreRequestModal;
        const config = { vertical: 'homes', context: 'notification' };
        const x = new WebPush(config);

        // showGenericPreRequestModal prototype function has to be imported. (See above)
        x.initialize()
            .then(subscription => {
                if (!subscription) {
                    // You could either choose to display your own permission UX or directly display the native popup, so trigger requestPermission accordingly
                    x.canTriggerCustomDialog()
                        .then(() => x.showGenericPreRequestModal())
                        .catch(() => { });
                }
            })
            .catch(() => { });
            */
        const btnCloseDownload = document.getElementById("closeDownload");
        btnCloseDownload && btnCloseDownload.addEventListener("click", addClassDownload);
        btnCloseDownload && addEvent('SNB_QuikrHomes',  snbFilterController.filter.data.cat+"_"+snbFilterController.filter.data.c, 'appDownload_banner_shown');
        const downloadAppLink = document.getElementById("downloadAppLink");
        downloadAppLink && downloadAppLink.addEventListener("click", downloadAppClicked);
    });
})();
function downloadAppClicked(e) {
    addEvent('SNB_QuikrHomes', snbFilterController.filter.data.cat+"_"+snbFilterController.filter.data.c, 'appDownload_banner_clicked');
    return true;
}
function addClassDownload(e) {
    const v = document.getElementById("downloadButton");
    v.classList.add("hideC");
    addEvent('SNB_QuikrHomes', snbFilterController.filter.data.cat+"_"+snbFilterController.filter.data.c, 'appDownload_banner_closed');
}

function getSortValue(type) {
    let sorvtVal;
    switch (type.toLowerCase()) {
        case "pa":
            sorvtVal = 'low2high';
            break;
        case "pd":
            sorvtVal = "high2low";
            break;
        case "dd":
            sorvtVal = "recentlyAdded";
            break;
    }
    return sorvtVal;
}

window.gaResumeAd = function () {
    addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type, 'QH_snb_' + snbFilterController.filter.data.type + '_resumeAd_click');
}
window.hotInvestGa = function (locality) {
    addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type, 'QH_snb_' + snbFilterController.filter.data.type + '_hotInvest_' + locality + '_click');
}
window.topProjectGa = function (projectname) {
    addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type, 'QH_snb_' + snbFilterController.filter.data.type + '_topProjects_' + projectname + '_click');
}
window.projectLocalityGa = function (name) {
    if (snbFilterController.filter.data.isQAP) {
        addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type + '_qap', 'QH_snb_' + snbFilterController.filter.data.type + '_qap_ads_' + name + '_click');
    } else {
        addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type, 'QH_snb_' + snbFilterController.filter.data.type + '_ads_' + name + '_click');
    }
}
window.tileGa = function () {
    if (snbFilterController.filter.data.isQAP) {
        addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type + '_qap', 'QH_snb_' + snbFilterController.filter.data.type + '_qap_ads_click');
    } else {
        addEvent('RealEstate', 'QH_snb_' + snbFilterController.filter.data.type, 'QH_snb_' + snbFilterController.filter.data.type + '_ads_click');
    }
}

window.hideShowSeoTab = function () {
    $('.HeaderSeofeatured').toggleClass('hide');
}
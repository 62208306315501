import {getCookie} from '../../services/cookieService'
import {isSet} from '../../utils/common'
    let snbInit = {};
    export const getFilterFromPath = function () {
        var filter = {};
        if(location.host.indexOf('googleusercontent')>0){
            var pathArray = location.search.split('www.quikr.com')
            if(pathArray.length>1){
                var path = pathArray[1].split('+')
                if(path.length>1){
                    path = path[0];
                }
            }

        }else{
            var path = window.location.pathname;
        }
       
        if (!path) {
            snbInit.errorHandler();
            return;
        }
        path = path.toLowerCase();
        filter.cat = snbInit.getCat(path);
        if (!filter.cat) {
            snbInit.errorHandler();
            return;
        }
        filter.type = snbInit.getType(path);
        if (!filter.type) {
            snbInit.errorHandler();
            return;
        }
    
        if(path.indexOf('co_')>0){
            var newPath = path.split("/");
            newPath.pop();
            var newPaths = newPath.join("/");
            var splited_paths = newPaths.split("-");
        } else{
            var splited_paths = path.split("-");
        }

        filter.c_id = snbInit.getLocCityId("cid_", splited_paths);
        if (!filter.c_id) {
            snbInit.errorHandler();
            return;
        }
        filter.c = getCookie('new_prefer_city');
        if (!filter.c) {
            snbInit.errorHandler();
            return;
        }
        filter.property_type = snbInit.getPropertyTypes(filter.cat, path);
        filter.ad_locids = [];
        var loc = snbInit.getLocCityId("lid_", splited_paths);
        if (isSet(loc)) {
            filter.ad_locids.push(loc);
            filter.loc_id = loc;
        }
        filter.ad_locality = [];
        if (filter.loc_id) {
            var loc_name = snbInit.getLocalityName(path);
            if (isSet(loc_name))
                filter.ad_locality.push(loc_name);
        }
        filter.ad_regionid = [];
        var region = snbInit.getRegZoneId("rid_", splited_paths);
        if (isSet(region)) {
            filter.ad_regionid.push(region);
            filter.region_id = region;
        }
        filter.ad_regionName = [];
        if (filter.region_id) {
            var region_name = snbInit.getLocalityName(path);
            if (isSet(region_name))
                filter.ad_regionName.push(region_name);
        }

        filter.ad_zoneid = [];
        var zone = snbInit.getRegZoneId("zid_", splited_paths);
        if (isSet(zone)) {
            filter.ad_zoneid.push(zone);
            filter.zone_id = zone;
        }
        filter.ad_zoneName = [];
        if (filter.zone_id) {
            var zone_name = snbInit.getLocalityName(path);
            if (isSet(zone_name))
                filter.ad_zoneName.push(zone_name);
        }

        // POI
        filter.poiIds = [];
        filter.poiNames = [];
        var poiId = snbInit.getLocCityId("pid_", splited_paths);

        if (isSet(poiId)) {
            filter.poiIds.push(poiId);
            filter.poiId = poiId;
        }

        if (filter.poiId) {
            var poiName = snbInit.getPoiName(path);
            if (isSet(poiName))
                filter.poiNames.push(poiName);
        }

        filter.bedrooms = snbInit.getBedrooms(filter.cat, splited_paths);
        filter.furnishing = snbInit.getFurnishings(filter.cat, path);
        filter.area = snbInit.getArea(splited_paths);
        filter.price = snbInit.getPrice(splited_paths);
        filter.gender = snbInit.getGender(splited_paths);
        filter.posted_by = snbInit.getPostedBy(path);
        filter.ad_type = snbInit.getAdType(path);
        filter.collection_id = snbInit.getLocCityId("co_", splited_paths);
        filter.collection_name = snbInit.getLocCityId("co_", splited_paths);
        filter.isReraApproved = snbInit.getReraRegistered(path);
        filter.projectstatus = snbInit.getProjectstatus(path);
        filter.saleType = snbInit.getSaleType(path);
        return filter;
    };

    snbInit.getLocalityName = function (path) {
        if (path) {
            var reraIndex = snbInit.getReraRegistered(path);
            var endindex = (reraIndex)? path.lastIndexOf('/') : path.indexOf('-cid');
            var str = path.substring(path.indexOf('in-') + 3, endindex);
            str = str.split("-");
            if (str.length > 1) {
                var loc_name = str[0].split("_");
                var result = [];
                for (var i = 0; i < loc_name.length; i++) {
                    result.push(capitalizeFirstLetter(loc_name[i]));
                }
                return result.join(" ");
            }
        }
        return "";
    };

    snbInit.getPoiName = function (path) {
        if (path) {
            var reraIndex = snbInit.getReraRegistered(path);
            var endindex = (reraIndex)? path.lastIndexOf('/') : path.indexOf('-cid');
            var str = path.substring(path.indexOf('near-') + 5, endindex);
            str = str.split("-");
            if (str.length > 0) {
                var loc_name = str[0].split("_");
                var result = [];
                for (var i = 0; i < loc_name.length; i++) {
                    result.push(capitalizeFirstLetter(loc_name[i]));
                }
                return result.join(" ");
            }
        }
        return "";
    };

    function capitalizeFirstLetter(str) {
       return str.charAt(0).toUpperCase() + str.substring(1, str.length)
    }

    snbInit.getPostedBy = function (path) {
        var posted_by = [];
        if (path.indexOf("broker") >= 0) {
            posted_by.push("br");
        }

        if (path.lastIndexOf("builder") >= 0
            && (path.lastIndexOf("builder") != path.lastIndexOf("builderfloor"))) {
            posted_by.push("bu");
        }
        if (path.indexOf("owner") >= 0) {
            posted_by.push("ow");
        }
        return posted_by;
    };
    snbInit.getProjectstatus = function (path) {
        if(path.indexOf("upcoming-projects") >= 0) {
            return "upcoming";
        } else if(path.indexOf("ongoing-projects") >= 0) {
            return "ongoing";
        } else if(path.indexOf("ready-to-move") >= 0) {
            return "completed";
        } else {
            return "";
        }
        return "";
    };

    snbInit.getSaleType = function (path) {
        if(path.indexOf("-bank-auction-") >= 0) {
            return 2;
        }
        return '';
    };

    snbInit.getAdType = function (path) {
        var ad_type = [];
        if (path.indexOf("brokers") >= 0 && path.indexOf("buy") >= 0) {
            ad_type.push("sell");
        } 
        if (path.indexOf("brokers") >= 0 && path.indexOf("rent") >= 0) {
            ad_type.push("rent");
        } 
        return ad_type;
    };

    snbInit.getLocCityId = function (area_type, paths) {
        for (var i = 0; i < paths.length; i++) {
            if (paths[i].indexOf(area_type) >= 0) {
                var id_string = paths[i];
                var splited_string = id_string.split("_");
                if (splited_string.length) {
                    var id = splited_string[splited_string.length - 1];
                    if (!isNaN(id)) {
                        return id * 1;
                    }
                    return;
                }
                return;
            }
        }
        return;
    };

    snbInit.getPrice = function (paths) {
        var price = {
            min: "-1",
            max: "-1"
        }
        for (var i = 0; i < paths.length; i++) {
            var index = paths[i].indexOf("rs_");
            if (index >= 0) {
                var price_string = paths[i].slice(3);
                var price_range = price_string.split("_");
                if (price_range.length == 2) {
                    price.min = snbInit.convertToRupee(price_range[0]);
                    price.max = snbInit.convertToRupee(price_range[1]);
                }
                return price;
            }
        }
        return price;
    }

     snbInit.getGender = function (paths) {
        var genderArray = [];
        for (var i = 0; i < paths.length; i++) {
            if (paths[i].indexOf("boys") >= 0) {
                genderArray.push('male');
            }
            if (paths[i].indexOf("girls") >= 0) {
                genderArray.push('female');
            }
        }
        return genderArray;
    }

    snbInit.getRegZoneId = function (area_type, paths) {
        for (var i = 0; i < paths.length; i++) {
            if (paths[i].indexOf(area_type) >= 0) {
                var id_string = paths[i];
                var splited_string = id_string.split("_");
                if (splited_string.length) {
                    var id = splited_string[splited_string.length - 1];
                    return id;
                }
            }
        }
    };

    snbInit.convertToRupee = function (price) {
        var num_price = parseInt(price);
        if (!num_price || isNaN(num_price)) {
            return "-1";
        }
        if (price.indexOf("lakh") >= 0) {
            return (num_price * 100000) + "";
        }
        if (price.indexOf("crore") >= 0) {
            return (num_price * 10000000) + "";
        }
        return num_price;
    }

    snbInit.getArea = function (paths) {
        var area = {
            min: "-1",
            max: "-1"
        }
        for (var i = 0; i < paths.length; i++) {
            var index = paths[i].indexOf("sqft");
            if (index >= 0) {
                var area_string = paths[i].slice(0, index);
                var area_range = area_string.split("_");
                if (area_range.length == 2) {
                    area.min = area_range[0];
                    area.max = area_range[1];
                    if (area.min == 'min') {
                        area.min = "-1";
                    }
                    if (area.max == 'max') {
                        area.max = "-1";
                    }
                }
                return area;
            }
        }
        return area;
    }

    snbInit.getFurnishings = function (cat, path) {
        var furnishings = [];
        if (cat != "residential") {
            return furnishings;
        }
        if (path.indexOf("semifurnished") >= 0) {
            furnishings.push("wr");
            return furnishings;
        }
        if (path.indexOf("furnished") >= 0) {
            furnishings.push("wr");
            furnishings.push("bd");
            return furnishings;
        }
        return furnishings;
    }

    snbInit.getBedrooms = function (cat, paths) {
        var beds = [], beds_pre = ["1", "2", "3"];
        if (cat != "residential") {
            return beds;
        }
        for (var i = 0; i < paths.length; i++) {
            if (paths[i].indexOf("bhk") >= 0) {
                var beds_string = paths[i];
                for (var j = 0; j < beds_pre.length; j++) {
                    if (beds_string.indexOf(beds_pre[j]) >= 0) {
                        beds.push(beds_pre[j]);
                    }
                }
                if (beds_string.indexOf("4+") >= 0) {
                    beds.push("5");
                }
                if (beds_string.indexOf("4") >= 0) {
                    if (beds_string.indexOf("4+") != beds_string.indexOf("4")) {
                        beds.push("4");
                    }
                }
                return beds;
            }
        }
        return beds;
    }

    snbInit.getCat = function (path) {
        if (path.indexOf("residential") >= 0) {
            return 'residential';
        }
        if (path.indexOf("commercial") >= 0) {
            return "commercial";
        }
        if (path.indexOf("agricultural") >= 0) {
            return "agricultural";
        }
        if (path.indexOf("real-estate") >= 0) {
            return "residential";
        }
        return;
    }

    snbInit.getPropertyTypes = function (cat, path) {
        var property_type = [];
        if (cat == "agricultural") {
            return [];
        }
        if (cat == "residential") {
            if (path.indexOf("apartments") >= 0) {
                property_type.push("ap");
            }
            if (path.indexOf("builderfloor") >= 0) {
                property_type.push("bf")
            }
            if (path.indexOf("villa") >= 0) {
                property_type.push("vi")
            }
            if (path.indexOf("plots") >= 0) {
                property_type.push("rp")
            }
            return property_type;
        }
        if (cat == "commercial") {
            if (path.indexOf("offices") >= 0) {
                property_type.push("of");
            }
            if (path.indexOf("shops") >= 0) {
                property_type.push("sh")
            }
            if (path.indexOf("buildingcomplex") >= 0) {
                property_type.push("bu")
            }
            if (path.indexOf("plots") >= 0) {
                property_type.push("cp")
            }
            return property_type;
        }
        return [];
    }

    snbInit.getType = function (path) {
        if (path.indexOf("-projects-") >= 0) {
            return "projects";
        }
        if (path.indexOf("pg_flatmates") >= 0) {
            return "pg";
        }
        if (path.indexOf("-sale-") >= 0 && path.indexOf("-brokers-") == -1) {
            return "buy";
        }
        if (path.indexOf("-rent-") >= 0 && path.indexOf("-brokers-") == -1) {
            return "rent";
        }
        if (path.indexOf("-brokers-") >= 0) {
            return "brokers";
        }
        return;
    }

    snbInit.getReraRegistered = function(path) {
        if(path.indexOf("/rera-registered-") >= 0) {
            return 1;
        }
        return;
    } 

    snbInit.getPossession = function(path) {
        var pathLength = path.split("/");
        if(pathLength.length > 4) {
            var possessionArray = [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025];
            var getPossession = path.split('/');
            var getPossessionPath = getPossession[getPossession.length-1];
            if(getPossessionPath.indexOf("ready-to-move") >= 0) {
                return 'Ready to move';
            } else if(getPossessionPath.indexOf('projects-completing-in-')>=0){
                var findPossessionPathyear = getPossessionPath.split('projects-completing-in-');
                var getPossessionYear = findPossessionPathyear[1].split('-');
                if(possessionArray.indexOf(getPossessionYear[0])) {
                    return getPossessionYear[0];
                }
                return "";
            } else {
                return "";
            }
        }
        return;
    }

    snbInit.getProjectStatus = function(path) {
        var pathLength = path.split("/");
        if(pathLength.length > 4) {
            var getProjectstatus = path.split('/');
            var getProjectstatusPath = getProjectstatus[getProjectstatus.length-1];
            if(getProjectstatusPath.indexOf("ready-to-move") >= 0) {
                return 'completed';
            } else if(getPossessionPath.indexOf('upcoming-')>=0){
                return "upcoming";
            } else if(getPossessionPath.indexOf('ongoing-')>=0){
                return "ongoing";
            } else {
                return "";
            }
        }
        return;
    }


    snbInit.errorHandler = function () {
        var path = window.location.pathname;
        if (path.indexOf('-cid_') == -1)
            return;
        else
            window.location.href = "/homes";
    }

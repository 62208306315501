import { Component } from "./component";

/**
 * Resolves components against the selector
 * 
 * @author Suman Kumar Das
 */
export class ComponentResolver {

    /**
     * The component that is to be resolved
     * 
     * @param {Component} type 
     */
    constructor(type) {
        if (!(type instanceof Function) || !(type.prototype instanceof Component)) {
            throw new TypeError(`Component cannot be of type ${type}. It must extend the Component class.`);
        }

        if (!type.alias || 'string' !== typeof type.alias) {
            throw new Error(`Component alias must be defined. Invalid alias ${type.alias}.`);
        }

        this.type = type;
        this.selector = `[${this.type.attrComponent}="${this.type.alias}"]`;
    }

    /**
     * Resolves the placeholders and the components
     * 
     * Note: Components with templates must use {@link ComponentInflater}
     */
    resolve() {
        this.elements = [...document.querySelectorAll(this.selector)];
        this.components = this.elements.map((el) => new this.type(el));
    }

    /**
     * Mount the given component into their placeholders
     */
    mount() {
        if (0 === this.components.length) {
            this.resolve();
        }

        // Mount all the components
        this.components.forEach((component) => {
            component.resolveRefs();
            component.onComponentLoaded();
        });
    }

}
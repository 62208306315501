import "../../assets/sass/web/modules/_app-dnl-modal.scss";
import { addEvent } from "../utils/gaService";
import { isAndroid } from "../utils/common";

const fireGa = (label) => {
  addEvent("RealEstate", globals.pageType, label);
};

const controller = {
  action: "",
  init: () => {
    const btnAppDnlCloseEl = document.querySelector(
      "#appDnlModal #btnAppDnlClose"
    );
    const btnAppDnlDownloadEl = document.querySelector(
      "#appDnlModal #btnAppDnlDownload"
    );
    const appDnlModal = document.getElementById("appDnlModal");

    if (!appDnlModal) {
      return;
    }

    btnAppDnlCloseEl.addEventListener("click", controller.handleCloseClick);

    if (btnAppDnlDownloadEl) {
      btnAppDnlDownloadEl.addEventListener(
        "click",
        controller.handleDownloadClick
      );
    }

    $("#appDnlModal").on("shown.bs.modal", controller.handleOpen);
    $("#appDnlModal").on("hidden.bs.modal", controller.onClose);
  },
  onClose: () => {
    fireGa(`QH_redirect_banner_${globals.pageType}_close`);
  },
  handleOpen: ({ relatedTarget }) => {
    controller.action = relatedTarget.dataset.btnType;
    fireGa(`QH_redirect_banner_${globals.pageType}_open`);
  },
  handleCloseClick: () => {
    if (isAndroid()) {
      controller.handleDownloadClick();
    } else {
      $("#appDnlModal").modal("hide");
    }
  },
  handleDownloadClick: () => {
    fireGa(`QH_${controller.action}_app_redirect`);
    const url = `https://play.google.com/store/apps/details?id=com.quikr&utm_source=QH_action_click&utm_campaign=QH_app_redirect&utm_medium=${controller.action}`;
    window.open(url, "_blank");
  },
};

export default controller;

var possessionVar = function () {
    var dt = new Date();
    var y = dt.getFullYear();
    var poss=[];
    poss[0] ='Ready to move';
    for(var i=0;i<=4;i++){
        poss[i+1] = y + i;
    }
    return poss
}

var getMonthYear = function (addMonth = 0){
    var months = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
    var now = new Date();
    var newDate = now.setMonth(now.getMonth() + addMonth);
    return months[ new Date(newDate).getMonth()] +" "+ new Date(newDate).getFullYear();
};

var available_from_buy = [
    {
        'value':'Ready to Move',
        'api_value':'0'
    },
    {
        'value':'Within 3 months',
        'api_value':'3'
    },
    {
        'value':'Within 6 months',
        'api_value':'6'
    }
    ,
    {
        'value':'Within 1 year',
        'api_value':'12'
    },
    {
        'value':'1 - 3 Years',
        'api_value':'12_36'
    },
    {
        'value':'3 - 5 Years',
        'api_value':'36_60'
    },
    {
        'value':'> 5 Years',
        'api_value':'60_120'
    }
];

var available_from_rent_pg = [
    {
        'value':'Immediately',
        'api_value':'ready'
    },
    {
        'value':'Current Month ( '+getMonthYear() +' )',
        'api_value':'0'
    },
    {
        'value': getMonthYear(1),
        'api_value':'1'
    },
    {
        'value': getMonthYear(2),
        'api_value':'2'
    },
    {
        'value': 'After 3 Months',
        'api_value':'3'
    }
];


var available_from = {
    'buy': available_from_buy,
    'rent':available_from_rent_pg,
    'pg': available_from_rent_pg
};

var furnishingStatus = [
    { label: 'Fully Furnished', value: 'Fully Furnished', seoText: 'fullyFurnished'},
    { label: 'Semi Furnished', value: 'Semi-Furnished', seoText: 'semiFurnished'},
    { label: 'Unfurnished', value: 'Unfurnished', seoText: 'unFurnished'}
];

var furnishingStatusGroupObj = {
    buy: furnishingStatus,
    rent: furnishingStatus,
    pg: furnishingStatus
};

var filters = {
    'meta': {
        'show_map': false,
        'expanded': false,
        'expanded_mobile': false
    },
    'residential': {
        'posted_by': [{
            'value': 'Builder',
            'api_value': 'bu'
        },{
            'value': 'Broker',
            'api_value': 'br'
        },{
            'value': 'Owner',
            'api_value': 'ow'
        }],
        // Don't use this property_type object because it's not a key value pair, we will remove it shortly. Next object(property_types) is doing the same thing with key value pair.
        'property_type': [{
            'value': 'Apartments',
            'ga_value' : 'apartment',
            'api_value': 'ap'
        },{
            'value': 'Villa',
            'ga_value' : 'villa',
            'api_value': 'vi'
        },{
            'value': 'Plots',
            'ga_value' : 'plot',
            'api_value': 'rp'
        },{
            'value': 'Builder Floors',
            'ga_value' : 'builder_floors',
            'api_value': 'bf'
        }],
        // Use this property_types object because it's a key value pair.
        'property_types': {
            'ap' : {
                'value': 'Apartments',
                'ga_value' : 'apartment'
            },
            'vi' : {
                'value': 'Villa',
                'ga_value' : 'villa',
            },
            'rp' : {
                'value': 'Plots',
                'ga_value' : 'plot',
            },
            'bf' : {
                'value': 'Builder Floors',
                'ga_value' : 'builder_floors',
            }
        },
        'ad_type': [{
            'value': 'Buy',
            'api_value': 'b'
        },{
            'value': 'Rent',
            'api_value': 'r'
        },{
            'value': 'Flatmates/PG/Hostels',
            'api_value': 'p'
        }],
        'possession': possessionVar(),
        'price': {
            'rent': {
                'range': [
                    { 
                        'label' : '< 10K',
                        'api_value_min' : '0',
                        'api_value_max' : '10000',
                    },
                    { 
                        'label' : '10K - 40K',
                        'api_value_min' : '10000',
                        'api_value_max' : '40000',
                    },
                    { 
                        'label' : '40K - 80K',
                        'api_value_min' : '40000',
                        'api_value_max' : '80000',
                    },
                    { 
                        'label' : '80K - 1.6L',
                        'api_value_min' : '80000',
                        'api_value_max' : '160000',
                    },
                    { 
                        'label' : '1.6L - 2L',
                        'api_value_min' : '160000',
                        'api_value_max' : '200000',
                    },
                    { 
                        'label' : '> 2L',
                        'api_value_min' : '200000',
                        'api_value_max' : '1000000',
                    }
                ],
                'min': [
                {
                    'value': '0+',
                    'api_value': '0'
                },
                {
                    'value': '1000',
                    'api_value': '1000'
                },{
                    'value': '5000',
                    'api_value': '5000'
                },{
                    'value': '10000',
                    'api_value': '10000'
                },{
                    'value': '20000',
                    'api_value': '20000'
                },{
                    'value': '40000',
                    'api_value': '40000'
                },{
                    'value': '60000',
                    'api_value': '60000'
                },{
                    'value': '80000',
                    'api_value': '80000'
                },{
                    'value': '1 Lakh',
                    'api_value': '100000'
                },{
                    'value': '>1 Lakh',
                    'api_value': '200000'
                }],
                'max': [{
                    'value': '1000',
                    'api_value': '1000'
                },{
                    'value': '5000',
                    'api_value': '5000'
                },{
                    'value': '10000',
                    'api_value': '10000'
                },{
                    'value': '20000',
                    'api_value': '20000'
                },{
                    'value': '40000',
                    'api_value': '40000'
                },{
                    'value': '60000',
                    'api_value': '60000'
                },{
                    'value': '80000',
                    'api_value': '80000'
                },{
                    'value': '1 Lakh',
                    'api_value': '100000'
                },{
                    'value': '>1 Lakh',
                    'api_value': '200000'
                }]
            },
            'buy': {
                'range': [
                    { 
                        'label' : '< 10L',
                        'api_value_min' : '0',
                        'api_value_max' : '1000000',
                    },
                    { 
                        'label' : '10L - 40L',
                        'api_value_min' : '1000000',
                        'api_value_max' : '4000000',
                    },
                    { 
                        'label' : '40L - 80L',
                        'api_value_min' : '4000000',
                        'api_value_max' : '8000000',
                    },
                    { 
                        'label' : '80L - 1.6Cr',
                        'api_value_min' : '8000000',
                        'api_value_max' : '16000000',
                    },
                    { 
                        'label' : '1.6Cr - 2Cr',
                        'api_value_min' : '16000000',
                        'api_value_max' : '20000000',
                    },
                    { 
                        'label' : '> 2Cr',
                        'api_value_min' : '20000000',
                        'api_value_max' : '100000000',
                    }
                ],
                'min': [{
                    'value': '0+',
                    'api_value': '0'
                },
                {
                    'value': '5 Lakh',
                    'api_value': '500000'
                },{
                    'value': '10 Lakh',
                    'api_value': '1000000'
                },{
                    'value': '20 Lakh',
                    'api_value': '2000000'
                },{
                    'value': '40 Lakh',
                    'api_value': '4000000'
                },{
                    'value': '60 Lakh',
                    'api_value': '6000000'
                },{
                    'value': '80 Lakh',
                    'api_value': '8000000'
                },{
                    'value': '1 Cr',
                    'api_value': '10000000'
                },{
                    'value': '1.3 Cr',
                    'api_value': '13000000'
                },{
                    'value': '1.6 Cr',
                    'api_value': '16000000'
                },{
                    'value': '2 Cr',
                    'api_value': '20000000'
                },{
                    'value': '>2 Cr',
                    'api_value': '50000000'
                }],
                'max': [{
                    'value': '5 Lakh',
                    'api_value': '500000'
                },{
                    'value': '10 Lakh',
                    'api_value': '1000000'
                },{
                    'value': '20 Lakh',
                    'api_value': '2000000'
                },{
                    'value': '40 Lakh',
                    'api_value': '4000000'
                },{
                    'value': '60 Lakh',
                    'api_value': '6000000'
                },{
                    'value': '80 Lakh',
                    'api_value': '8000000'
                },{
                    'value': '1 Cr',
                    'api_value': '10000000'
                },{
                    'value': '1.3 Cr',
                    'api_value': '13000000'
                },{
                    'value': '1.6 Cr',
                    'api_value': '16000000'
                },{
                    'value': '2 Cr',
                    'api_value': '20000000'
                },{
                    'value': '>2 Cr',
                    'api_value': '50000000'
                }]
            },
            'pg': {
                'range': [
                    { 
                        'label' : '< 10K',
                        'api_value_min' : '0',
                        'api_value_max' : '10000',
                    },
                    { 
                        'label' : '10K - 20K',
                        'api_value_min' : '10000',
                        'api_value_max' : '20000',
                    },
                    { 
                        'label' : '20K - 40K',
                        'api_value_min' : '20000',
                        'api_value_max' : '40000',
                    },
                    { 
                        'label' : '40K - 60K',
                        'api_value_min' : '40000',
                        'api_value_max' : '60000',
                    },
                    { 
                        'label' : '60K - 80K',
                        'api_value_min' : '60000',
                        'api_value_max' : '80000',
                    },
                    { 
                        'label' : '> 80K',
                        'api_value_min' : '80000',
                        'api_value_max' : '500000',
                    }
                ],
                'min': [{
                    'value': '0+',
                    'api_value': '0'
                },
                {
                    'value': '1000',
                    'api_value': '1000'
                },{
                    'value': '5000',
                    'api_value': '5000'
                },{
                    'value': '10000',
                    'api_value': '10000'
                },{
                    'value': '20000',
                    'api_value': '20000'
                },{
                    'value': '40000',
                    'api_value': '40000'
                },{
                    'value': '60000',
                    'api_value': '60000'
                },{
                    'value': '80000',
                    'api_value': '80000'
                },{
                    'value': '1 Lakh',
                    'api_value': '100000'
                },{
                    'value': '>1 Lakh',
                    'api_value': '200000'
                }],
                'max': [{
                    'value': '1000',
                    'api_value': '1000'
                },{
                    'value': '5000',
                    'api_value': '5000'
                },{
                    'value': '10000',
                    'api_value': '10000'
                },{
                    'value': '20000',
                    'api_value': '20000'
                },{
                    'value': '40000',
                    'api_value': '40000'
                },{
                    'value': '60000',
                    'api_value': '60000'
                },{
                    'value': '80000',
                    'api_value': '80000'
                },{
                    'value': '1 Lakh',
                    'api_value': '100000'
                },{
                    'value': '>1 Lakh',
                    'api_value': '200000'
                }]
            },
            'projects': {
                'range': [
                    { 
                        'label' : '< 10L',
                        'api_value_min' : '0',
                        'api_value_max' : '1000000',
                    },
                    { 
                        'label' : '10L - 40L',
                        'api_value_min' : '1000000',
                        'api_value_max' : '4000000',
                    },
                    { 
                        'label' : '40L - 80L',
                        'api_value_min' : '4000000',
                        'api_value_max' : '8000000',
                    },
                    { 
                        'label' : '80L - 1.6Cr',
                        'api_value_min' : '8000000',
                        'api_value_max' : '16000000',
                    },
                    { 
                        'label' : '1.6Cr - 2Cr',
                        'api_value_min' : '16000000',
                        'api_value_max' : '20000000',
                    },
                    { 
                        'label' : '> 2Cr',
                        'api_value_min' : '20000000',
                        'api_value_max' : '100000000',
                    }
                ],
                'min': [{
                    'value': '0+',
                    'api_value': '0'
                },
                {
                    'value': '5 Lakh',
                    'api_value': '500000'
                },{
                    'value': '10 Lakh',
                    'api_value': '1000000'
                },{
                    'value': '25 Lakh',
                    'api_value': '2500000'
                },{
                    'value': '50 Lakh',
                    'api_value': '5000000'
                },{
                    'value': '1 Cr+',
                    'api_value': '10000000'
                }],
                'max': [{
                    'value': '5 Lakh',
                    'api_value': '500000'
                },{
                    'value': '10 Lakh',
                    'api_value': '1000000'
                },{
                    'value': '25 Lakh',
                    'api_value': '2500000'
                },{
                    'value': '50 Lakh',
                    'api_value': '5000000'
                },{
                    'value': '1 Cr',
                    'api_value': '10000000'
                },{
                    'value': '5Cr',
                    'api_value': '50000000'
                }]
            }
        },
        'no_of_rooms': [{
            'value': '1',
            'api_value': '1'
        }, {
            'value': '2',
            'api_value': '2'
        }, {
            'value': '3',
            'api_value': '3'
        }, {
            'value': '4',
            'api_value': '4'
        }, {
            'value': '4+',
            'api_value': '5'
        }
        ],
        'posted_date': [{
            'value': 'In 24 Hours',
            'api_value': '0'
        }, {
            'value': 'In a week',
            'api_value': '2'
        }, {
            'value': 'In two weeks',
            'api_value': '3'
        }, {
            'value': 'In a month',
            'api_value': '4'
        }
        ],
        'projectstatus': [{
            'value': 'Ready to Move',
            'api_value': 'completed'
        }, {
            'value': 'Ongoing',
            'api_value': 'ongoing'
        }, {
            'value': 'Upcoming',
            'api_value': 'upcoming'
        }],
        'area': {
            'range': [
                    { 
                        'label' : '0 - 1000 sqft',
                        'api_value_min' : '0',
                        'api_value_max' : '1000'
                    },
                    { 
                        'label' : '1000 - 1500 sqft',
                        'api_value_min' : '1000',
                        'api_value_max' : '1500'
                    },
                    { 
                        'label' : '1500 - 2000 sqft',
                        'api_value_min' : '1500',
                        'api_value_max' : '2000'
                    },
                    { 
                        'label' : '2000 - 2500 sqft',
                        'api_value_min' : '2000',
                        'api_value_max' : '2500'
                    },
                    { 
                        'label' : '2500 - 3000 sqft',
                        'api_value_min' : '2500',
                        'api_value_max' : '3000'
                    },
                    { 
                        'label' : '> 3000 sqft',
                        'api_value_min' : '3000',
                        'api_value_max' : '10000'
                    }
                ],
            'min': [{
                'value': '0+',
                'api_value': '0'
            },
            {
                'value': '500',
                'api_value': '500'
            },{
                'value': '1000',
                'api_value': '1000'
            },{
                'value': '1500',
                'api_value': '1500'
            },{
                'value': '2000',
                'api_value': '2000'
            },{
                'value': '2500',
                'api_value': '2500'
            },{
                'value': '3000',
                'api_value': '3000'
            },{
                'value': '>3000',
                'api_value': '5000'
            }],
            'max': [{
                'value': '500',
                'api_value': '500'
            },{
                'value': '1000',
                'api_value': '1000'
            },{
                'value': '1500',
                'api_value': '1500'
            },{
                'value': '2000',
                'api_value': '2000'
            },{
                'value': '2500',
                'api_value': '2500'
            },{
                'value': '3000',
                'api_value': '3000'
            },{
                'value': '>3000',
                'api_value': '5000'
            }]
        },
        'furnishing': [{
            'value': 'Air condition',
            'api_value': 'ac'
        }, {
            'value': 'Refrigerator',
            'api_value': 'rf'
        }, {
            'value': 'Washing machine',
            'api_value': 'wm'
        }, {
            'value': 'Modular Kitchen',
            'api_value': 'mk'
        }, {
            'value': 'TV',
            'api_value': 'tv'
        }, {
            'value': 'Beds',
            'api_value': 'bd'
        }, {
            'value': 'Wardrobes',
            'api_value': 'wr'
        }, {
            'value': 'Dining table',
            'api_value': 'dt'
        },{
            'value': 'Attached Bathroom',
            'api_value': 'ab'
        },{
            'value': 'Geyzer',
            'api_value': 'gy'
        },{
            'value': 'Power Backup',
            'api_value': 'pb'
        },{
            'value': 'Western Toilet',
            'api_value': 'wt'
        }
        ],
        'gender': [{
            'value': 'Male',
            'api_value': 'm'
        }, {
            'value': 'Female',
            'api_value': 'f'
        }
        ],
        'amenities': [{
            'value': 'Lift',
            'api_value': 'lt'
        }, {
            'value': 'Gym',
            'api_value': 'gy'
        }, {
            'value': 'Swimming pool',
            'api_value': 'sp'
        }, {
            'value': 'Club House',
            'api_value': 'cl'
        }, {
            'value': 'Gated Community',
            'api_value': 'gc'
        }, {
            'value': 'Play Area',
            'api_value': 'pa'
        }, {
            'value': 'Maintenance Staff',
            'api_value': 'ms'
        }, {
            'value': 'CCTV Cameras',
            'api_value': 'cc'
        }, {
            'value': '24Hrs Backup Electricity',
            'api_value': 'be'
        }, {
            'value': 'Gas pipeline',
            'api_value': 'gp'
        }, {
            'value': 'Fire Safety',
            'api_value': 'fs'
        }, {
            'value': 'Wifi Connection',
            'api_value': 'wi'
        }, {
            'value': 'Laundry',
            'api_value': 'laun'
        }, {
            'value': 'Security',
            'api_value': 'sec'
        }, {
            'value': 'Fire Alarm',
            'api_value': 'cfa'
        }, {
            'value': 'Conference room',
            'api_value': 'ccr'
        }, {
            'value': 'Cafeteria',
            'api_value': 'cfc'
        },   {
            'value': 'Bank/ATM',
            'api_value': 'catm'
        }, {
            'value': 'Visitor Parking',
            'api_value': 'cvp'
        }
        ],
        'sortby': [{
            'value': 'Price: Low to High',
            'api_value': 'pa'
        }, {
            'value': 'Price: High to Low',
            'api_value': 'pd'
        },
        {
            'value': 'Recently Added',
            'api_value': 'dd'
        },{
            'value': 'Popularity',
            'api_value': ''
        }
        ],
        'occupancy': [
            {
                'value': 'Individual Room',
                'api_value': 'Individual'
            }, {
                'value': 'Sharing Room',
                'api_value': 'Sharing'
            }
        ],
        available_from,
        furnishingStatusGroupObj
    },
    'commercial': {
        'posted_by': [{
            'value': 'Builder',
            'api_value': 'bu'
        },{
            'value': 'Broker',
            'api_value': 'br'
        },{
            'value': 'Owner',
            'api_value': 'ow'
        }],
        'property_type': [{
            'value': 'Plots',
            'ga_value' : 'plot',
            'api_value': 'cp'
        },{
            'value': 'Offices',
            'ga_value' : 'office',
            'api_value': 'of'
        },{
            'value': 'Shops',
            'ga_value' : 'shop',
            'api_value': 'sh'
        },{
            'value': 'Building/Complex',
            'ga_value' : 'building_complex',
            'api_value': 'bu'
        }],
        'property_types': {
            'cp' : {
                'value': 'Plots',
                'ga_value' : 'plot'
            },
            'of' : {
                'value': 'Offices',
                'ga_value' : 'office',
            },
            'sh' : {
                'value': 'Shops',
                'ga_value' : 'shop',
            },
            'bu' : {
                'value': 'Building/Complex',
                'ga_value' : 'building_complex',
            }
        },
        'ad_type': [{
            'value': 'Buy',
            'api_value': 'b'
        },{
            'value': 'Rent',
            'api_value': 'r'
        }
        ],
        'possession': possessionVar(),
        'price': {
            'rent': {
                'range': [
                    { 
                        'label' : '< 10K',
                        'api_value_min' : '0',
                        'api_value_max' : '10000',
                    },
                    { 
                        'label' : '10K - 40K',
                        'api_value_min' : '10000',
                        'api_value_max' : '40000',
                    },
                    { 
                        'label' : '40K - 80K',
                        'api_value_min' : '40000',
                        'api_value_max' : '80000',
                    },
                    { 
                        'label' : '80K - 1.6L',
                        'api_value_min' : '80000',
                        'api_value_max' : '160000',
                    },
                    { 
                        'label' : '1.6L - 2L',
                        'api_value_min' : '160000',
                        'api_value_max' : '200000',
                    },
                    { 
                        'label' : '> 2L',
                        'api_value_min' : '200000',
                        'api_value_max' : '1000000',
                    }
                ],
                'min': [{
                    'value': '0+',
                    'api_value': '0'
                },
                {
                    'value': '1000',
                    'api_value': '1000'
                },{
                    'value': '10000',
                    'api_value': '10000'
                },{
                    'value': '30000',
                    'api_value': '30000'
                },{
                    'value': '50000',
                    'api_value': '50000'
                },{
                    'value': '70000',
                    'api_value': '70000'
                },{
                    'value': '1 Lakh',
                    'api_value': '100000'
                },{
                    'value': '2 Lakh',
                    'api_value': '200000'
                },{
                    'value': '5 Lakh',
                    'api_value': '500000'
                },{
                    'value': '8 Lakh',
                    'api_value': '800000'
                },{
                    'value': '10 Lakh',
                    'api_value': '1000000'
                },{
                    'value': '15 Lakh',
                    'api_value': '1500000'
                },{
                    'value': '>15 Lakh',
                    'api_value': '1600000'
                }],
                'max': [{
                    'value': '1000',
                    'api_value': '1000'
                },{
                    'value': '10000',
                    'api_value': '10000'
                },{
                    'value': '30000',
                    'api_value': '30000'
                },{
                    'value': '50000',
                    'api_value': '50000'
                },{
                    'value': '70000',
                    'api_value': '70000'
                },{
                    'value': '1 Lakh',
                    'api_value': '100000'
                },{
                    'value': '2 Lakh',
                    'api_value': '200000'
                },{
                    'value': '5 Lakh',
                    'api_value': '500000'
                },{
                    'value': '8 Lakh',
                    'api_value': '800000'
                },{
                    'value': '10 Lakh',
                    'api_value': '1000000'
                },{
                    'value': '15 Lakh',
                    'api_value': '1500000'
                },{
                    'value': '>15 Lakh',
                    'api_value': '1600000'
                }]
            },
            'buy': {
                'range': [
                    { 
                        'label' : '< 10L',
                        'api_value_min' : '0',
                        'api_value_max' : '1000000',
                    },
                    { 
                        'label' : '10L - 40L',
                        'api_value_min' : '1000000',
                        'api_value_max' : '4000000',
                    },
                    { 
                        'label' : '40L - 80L',
                        'api_value_min' : '4000000',
                        'api_value_max' : '8000000',
                    },
                    { 
                        'label' : '80L - 1.6Cr',
                        'api_value_min' : '8000000',
                        'api_value_max' : '16000000',
                    },
                    { 
                        'label' : '1.6Cr - 2Cr',
                        'api_value_min' : '16000000',
                        'api_value_max' : '20000000',
                    },
                    { 
                        'label' : '> 2Cr',
                        'api_value_min' : '20000000',
                        'api_value_max' : '100000000',
                    }
                ],
                'min': [{
                    'value': '0+',
                    'api_value': '0'
                },
                {
                    'value': '5 Lakh',
                    'api_value': '500000'
                },{
                    'value': '10 Lakh',
                    'api_value': '1000000'
                },{
                    'value': '20 Lakh',
                    'api_value': '2000000'
                },{
                    'value': '40 Lakh',
                    'api_value': '4000000'
                },{
                    'value': '60 Lakh',
                    'api_value': '6000000'
                },{
                    'value': '80 Lakh',
                    'api_value': '8000000'
                },{
                    'value': '1 Cr',
                    'api_value': '10000000'
                },{
                    'value': '2 Cr',
                    'api_value': '20000000'
                },{
                    'value': '3 Cr',
                    'api_value': '30000000'
                },{
                    'value': '4 Cr',
                    'api_value': '40000000'
                },{
                    'value': '5 Cr',
                    'api_value': '50000000'
                },{
                    'value': '>5 Cr',
                    'api_value': '60000000'
                }],
                'max': [{
                    'value': '5 Lakh',
                    'api_value': '500000'
                },{
                    'value': '10 Lakh',
                    'api_value': '1000000'
                },{
                    'value': '20 Lakh',
                    'api_value': '2000000'
                },{
                    'value': '40 Lakh',
                    'api_value': '4000000'
                },{
                    'value': '60 Lakh',
                    'api_value': '6000000'
                },{
                    'value': '80 Lakh',
                    'api_value': '8000000'
                },{
                    'value': '1 Cr',
                    'api_value': '10000000'
                },{
                    'value': '2 Cr',
                    'api_value': '20000000'
                },{
                    'value': '3 Cr',
                    'api_value': '30000000'
                },{
                    'value': '4 Cr',
                    'api_value': '40000000'
                },{
                    'value': '5 Cr',
                    'api_value': '50000000'
                },{
                    'value': '>5 Cr',
                    'api_value': '60000000'
                }]
            },
            'projects': {
                'range': [
                    { 
                        'label' : '< 10L',
                        'api_value_min' : '0',
                        'api_value_max' : '1000000',
                    },
                    { 
                        'label' : '10L - 40L',
                        'api_value_min' : '1000000',
                        'api_value_max' : '4000000',
                    },
                    { 
                        'label' : '40 L - 80L',
                        'api_value_min' : '4000000',
                        'api_value_max' : '8000000',
                    },
                    { 
                        'label' : '80L - 1.6Cr',
                        'api_value_min' : '8000000',
                        'api_value_max' : '16000000',
                    },
                    { 
                        'label' : '1.6Cr - 2Cr',
                        'api_value_min' : '16000000',
                        'api_value_max' : '20000000',
                    },
                    { 
                        'label' : '> 2Cr',
                        'api_value_min' : '20000000',
                        'api_value_max' : '100000000',
                    }
                ],
                'min': [{
                    'value': '0+',
                    'api_value': '0'
                },
                {
                    'value': '5 Lakh',
                    'api_value': '500000'
                },{
                    'value': '10 Lakh',
                    'api_value': '1000000'
                },{
                    'value': '25 Lakh',
                    'api_value': '2500000'
                },{
                    'value': '50 Lakh',
                    'api_value': '5000000'
                },{
                    'value': '1 Cr',
                    'api_value': '10000000'
                }],
                'max': [{
                    'value': '5 Lakh',
                    'api_value': '500000'
                },{
                    'value': '10 Lakh',
                    'api_value': '1000000'
                },{
                    'value': '25 Lakh',
                    'api_value': '2500000'
                },{
                    'value': '50 Lakh',
                    'api_value': '5000000'
                },{
                    'value': '1 Cr',
                    'api_value': '10000000'
                },{
                    'value': '5Cr',
                    'api_value': '50000000'
                }]
            }
        },
        'posted_date': [{
            'value': 'In 24 Hours',
            'api_value': '0'
        }, {
            'value': 'In a week',
            'api_value': '2'
        }, {
            'value': 'In two weeks',
            'api_value': '3'
        }, {
            'value': 'In a month',
            'api_value': '4'
        }
        ],
        'area': {
            'range': [
                    { 
                        'label' : '0 - 1000 sqft',
                        'api_value_min' : '0',
                        'api_value_max' : '1000'
                    },
                    { 
                        'label' : '1000 - 1500 sqft',
                        'api_value_min' : '1000',
                        'api_value_max' : '1500'
                    },
                    { 
                        'label' : '1500 - 2000 sqft',
                        'api_value_min' : '1500',
                        'api_value_max' : '2000'
                    },
                    { 
                        'label' : '2000 - 2500 sqft',
                        'api_value_min' : '2000',
                        'api_value_max' : '2500'
                    },
                    { 
                        'label' : '2500 - 3000 sqft',
                        'api_value_min' : '2500',
                        'api_value_max' : '3000'
                    },
                    { 
                        'label' : '> 3000 sqft',
                        'api_value_min' : '3000',
                        'api_value_max' : '10000'
                    }
                ],
            'min': [{
                'value': '0+',
                'api_value': '0'
            },
            {
                'value': '2000',
                'api_value': '2000'
            },{
                'value': '3000',
                'api_value': '3000'
            },{
                'value': '5000',
                'api_value': '5000'
            },{
                'value': '7000',
                'api_value': '7000'
            },{
                'value': '8000',
                'api_value': '8000'
            },{
                'value': '10000',
                'api_value': '10000'
            },{
                'value': '12000',
                'api_value': '12000'
            },{
                'value': '>12000',
                'api_value': '15000'
            }],
            'max': [{
                'value': '2000',
                'api_value': '2000'
            },{
                'value': '3000',
                'api_value': '3000'
            },{
                'value': '5000',
                'api_value': '5000'
            },{
                'value': '7000',
                'api_value': '7000'
            },{
                'value': '8000',
                'api_value': '8000'
            },{
                'value': '10000',
                'api_value': '10000'
            },{
                'value': '12000',
                'api_value': '12000'
            },{
                'value': '>12000',
                'api_value': '15000'
            }]
        },
        'furnishing': [{
            'value': 'Chairs',
            'api_value': 'cc'
        }, {
            'value': 'Air Conditioner',
            'api_value': 'cac'
        }, {
            'value': 'Storage Cupboards',
            'api_value': 'csc'
        }, {
            'value': 'Electrical Fittings',
            'api_value': 'cef'
        }
        ],
        'amenities': [
        {
            'value': 'Lift',
            'api_value': 'lt'
        }, {
            'value': 'Gym',
            'api_value': 'gy'
        }, {
            'value': 'Swimming pool',
            'api_value': 'sp'
        }, {
            'value': 'Club House',
            'api_value': 'cl'
        }, {
            'value': 'Gated Community',
            'api_value': 'gc'
        }, {
            'value': 'Play Area',
            'api_value': 'pa'
        }, {
            'value': 'Maintenance Staff',
            'api_value': 'ms'
        }, {
            'value': 'CCTV Cameras',
            'api_value': 'cc'
        }, {
            'value': '24Hrs Backup Electricity',
            'api_value': 'be'
        }, {
            'value': 'Gas pipeline',
            'api_value': 'gp'
        }, {
            'value': 'Fire Safety',
            'api_value': 'fs'
        }, {
            'value': 'Wifi Connection',
            'api_value': 'wi'
        }, {
            'value': 'Laundry',
            'api_value': 'laun'
        }, {
            'value': 'Security',
            'api_value': 'sec'
        }, {
            'value': 'Fire Alarm',
            'api_value': 'cfa'
        }, {
            'value': 'Conference room',
            'api_value': 'ccr'
        }, {
            'value': 'Cafeteria',
            'api_value': 'cfc'
        },   {
            'value': 'Bank/ATM',
            'api_value': 'catm'
        }, {
            'value': 'Visitor Parking',
            'api_value': 'cvp'
        }
        ],
        'sortby': [{
            'value': 'Price: Low to High',
            'api_value': 'pa'
        }, {
            'value': 'Price: High to Low',
            'api_value': 'pd'
        },
            {
                'value': 'Recently Added',
                'api_value': 'dd'
            }
        ],
        available_from

    },
    'agricultural': {
        'posted_by': [{
            'value': 'Builder',
            'api_value': 'bu'
        },{
            'value': 'Broker',
            'api_value': 'br'
        },{
            'value': 'Owner',
            'api_value': 'ow'
        }],
        'property_type': [],
        'property_types' : [],
        'ad_type': [{
            'value': 'Buy',
            'api_value': 'b'
        }],
        'possession': possessionVar(),
        'price': {
            'buy': {
                'min': [{
                    'value': '0+',
                    'api_value': '0'
                },
                {
                    'value': '5 Lakh',
                    'api_value': '500000'
                },{
                    'value': '10 Lakh',
                    'api_value': '1000000'
                },{
                    'value': '25 Lakh',
                    'api_value': '2500000'
                },{
                    'value': '50 Lakh',
                    'api_value': '5000000'
                },{
                    'value': '1 Cr',
                    'api_value': '10000000'
                }],
                'max': [{
                    'value': '5 Lakh',
                    'api_value': '500000'
                },{
                    'value': '10 Lakh',
                    'api_value': '1000000'
                },{
                    'value': '25 Lakh',
                    'api_value': '2500000'
                },{
                    'value': '50 Lakh',
                    'api_value': '5000000'
                },{
                    'value': '1 Cr',
                    'api_value': '10000000'
                },{
                    'value': '5Cr',
                    'api_value': '50000000'
                }]
            },
            'projects': {
                'range': [
                    { 
                        'label' : '< 10 L',
                        'api_value_min' : '0',
                        'api_value_max' : '1000000',
                    },
                    { 
                        'label' : '10 L - 40 L',
                        'api_value_min' : '1000000',
                        'api_value_max' : '4000000',
                    },
                    { 
                        'label' : '40 L - 80 L',
                        'api_value_min' : '4000000',
                        'api_value_max' : '8000000',
                    },
                    { 
                        'label' : '80 L - 1.6 Cr',
                        'api_value_min' : '8000000',
                        'api_value_max' : '16000000',
                    },
                    { 
                        'label' : '1.6 Cr - 2 Cr',
                        'api_value_min' : '16000000',
                        'api_value_max' : '20000000',
                    },
                    { 
                        'label' : '> 2 Cr',
                        'api_value_min' : '20000000',
                        'api_value_max' : '100000000',
                    }
                ],
                'min': [{
                    'value': '0+',
                    'api_value': '0'
                },
                {
                    'value': '5 Lakh',
                    'api_value': '500000'
                },{
                    'value': '10 Lakh',
                    'api_value': '1000000'
                },{
                    'value': '25 Lakh',
                    'api_value': '2500000'
                },{
                    'value': '50 Lakh',
                    'api_value': '5000000'
                },{
                    'value': '1 Cr',
                    'api_value': '10000000'
                }],
                'max': [{
                    'value': '5 Lakh',
                    'api_value': '500000'
                },{
                    'value': '10 Lakh',
                    'api_value': '1000000'
                },{
                    'value': '25 Lakh',
                    'api_value': '2500000'
                },{
                    'value': '50 Lakh',
                    'api_value': '5000000'
                },{
                    'value': '1 Cr',
                    'api_value': '10000000'
                },{
                    'value': '5Cr',
                    'api_value': '50000000'
                }]
            }
        },
        'posted_date': [{
            'value': 'In 24 Hours',
            'api_value': '86400000'
        }, {
            'value': 'In a week',
            'api_value': '604800000'
        }, {
            'value': 'In two weeks',
            'api_value': '1209600000'
        }, {
            'value': 'In a month',
            'api_value': '2592000000'
        }
        ],
        'area': {
            'range': [
                    { 
                        'label' : '0 - 1000 sqft',
                        'api_value_min' : '0',
                        'api_value_max' : '1000'
                    },
                    { 
                        'label' : '1000 - 1500 sqft',
                        'api_value_min' : '1000',
                        'api_value_max' : '1500'
                    },
                    { 
                        'label' : '1500 - 2000 sqft',
                        'api_value_min' : '1500',
                        'api_value_max' : '2000'
                    },
                    { 
                        'label' : '2000 - 2500 sqft',
                        'api_value_min' : '2000',
                        'api_value_max' : '2500'
                    },
                    { 
                        'label' : '2500 - 3000 sqft',
                        'api_value_min' : '2500',
                        'api_value_max' : '3000'
                    },
                    { 
                        'label' : '> 3000 sqft',
                        'api_value_min' : '3000',
                        'api_value_max' : '10000'
                    }
                ],
            'min': [{
                'value': '0+',
                'api_value': '0'
            },
            {
                'value': '2000',
                'api_value': '2000'
            },{
                'value': '3000',
                'api_value': '3000'
            },{
                'value': '5000',
                'api_value': '5000'
            },{
                'value': '7000',
                'api_value': '7000'
            },{
                'value': '8000',
                'api_value': '8000'
            },{
                'value': '10000',
                'api_value': '10000'
            },{
                'value': '12000',
                'api_value': '12000'
            },{
                'value': '>12000',
                'api_value': '15000'
            }],
            'max': [{
                'value': '2000',
                'api_value': '2000'
            },{
                'value': '3000',
                'api_value': '3000'
            },{
                'value': '5000',
                'api_value': '5000'
            },{
                'value': '7000',
                'api_value': '7000'
            },{
                'value': '8000',
                'api_value': '8000'
            },{
                'value': '10000',
                'api_value': '10000'
            },{
                'value': '12000',
                'api_value': '12000'
            },{
                'value': '>12000',
                'api_value': '15000'
            }]
        },
        'sortby': [{
            'value': 'Price: Low to High',
            'api_value': 'pa'
        }, {
            'value': 'Price: High to Low',
            'api_value': 'pd'
        },
            {
                'value': 'Recently Added',
                'api_value': 'dd'
            }
        ],
        available_from,
        furnishingStatusGroupObj
    },
    'brokers': {
                'posted_by': [{
                    'value': 'Builder',
                    'api_value': 'bu'
                },{
                    'value': 'Broker',
                    'api_value': 'br'
                },{
                    'value': 'Owner',
                    'api_value': 'ow'
                }],
                'property_type': [],
                'property_types' : [],
                'ad_type': [{
                    'value': 'Buy',
                    'api_value': 'b'
                }],
                'price': {
                    'buy': {
                        'min': [
                        {
                            'value': '5 Lakh',
                            'api_value': '500000'
                        },{
                            'value': '10 Lakh',
                            'api_value': '1000000'
                        },{
                            'value': '25 Lakh',
                            'api_value': '2500000'
                        },{
                            'value': '50 Lakh+',
                            'api_value': '5000000'
                        },{
                            'value': '1 Cr',
                            'api_value': '10000000'
                        }],
                        'max': [{
                            'value': '5 Lakh',
                            'api_value': '500000'
                        },{
                            'value': '10 Lakh',
                            'api_value': '1000000'
                        },{
                            'value': '25 Lakh',
                            'api_value': '2500000'
                        },{
                            'value': '50 Lakh',
                            'api_value': '5000000'
                        },{
                            'value': '1 Cr',
                            'api_value': '10000000'
                        },{
                            'value': '5Cr',
                            'api_value': '50000000'
                        }]
                    },
                    'projects': {
                        'min': [
                        {
                            'value': '5 Lakh',
                            'api_value': '500000'
                        },{
                            'value': '10 Lakh',
                            'api_value': '1000000'
                        },{
                            'value': '25 Lakh',
                            'api_value': '2500000'
                        },{
                            'value': '50 Lakh',
                            'api_value': '5000000'
                        },{
                            'value': '1 Cr+',
                            'api_value': '10000000'
                        }],
                        'max': [{
                            'value': '5 Lakh',
                            'api_value': '500000'
                        },{
                            'value': '10 Lakh',
                            'api_value': '1000000'
                        },{
                            'value': '25 Lakh',
                            'api_value': '2500000'
                        },{
                            'value': '50 Lakh',
                            'api_value': '5000000'
                        },{
                            'value': '1 Cr',
                            'api_value': '10000000'
                        },{
                            'value': '5Cr',
                            'api_value': '50000000'
                        }]
                    }
                },
                'posted_date': [{
                    'value': 'In 24 Hours',
                    'api_value': '86400000'
                }, {
                    'value': 'In a week',
                    'api_value': '604800000'
                }, {
                    'value': 'In two weeks',
                    'api_value': '1209600000'
                }, {
                    'value': 'In a month',
                    'api_value': '2592000000'
                }
                ],
                'area': {
                    'min': [
                    {
                        'value': '2000',
                        'api_value': '2000'
                    },{
                        'value': '5000',
                        'api_value': '5000'
                    },{
                        'value': '8000',
                        'api_value': '8000'
                    },{
                        'value': '12000',
                        'api_value': '12000'
                    }],
                    'max': [{
                        'value': '2000',
                        'api_value': '2000'
                    },{
                        'value': '5000',
                        'api_value': '5000'
                    },{
                        'value': '8000',
                        'api_value': '8000'
                    },{
                        'value': '12000',
                        'api_value': '12000'
                    }]
                },
                'sortby': [{
                    'value': 'Price: Low to High',
                    'api_value': 'pa'
                }, {
                    'value': 'Price: High to Low',
                    'api_value': 'pd'
                },
                    {
                        'value': 'Recently Added',
                        'api_value': 'dd'
                    }
                ],
                'ad_type': [{
                    'value': 'Buy',
                    'api_value': 'sell'
                },{
                    'value': 'Rent',
                    'api_value': 'rent'
                }]  
            },
    'filtersList': {
        'buy': [
            'snbFilters',
            'snbReraFilter',
            'snbCityFilter',
            'snbLocalityFilter',
            'snbPOIFilter',
            'snbPropertyTypeFilter',
            'snbPriceFilter',
            'snbPostedbyFilter',
            'snbAvailablefromFilter',
            'snbBedroomFilter',
            'snbPosteddateFilter',
            'snbAreaFilter',
            'snbAmenitiesFilter',
            'snbFurnishingFilter'
        ],
        'rent': [
            'snbFilters',
            'snbCityFilter',
            'snbLocalityFilter',
            'snbPOIFilter',
            'snbPriceFilter',
            'snbPropertyTypeFilter',
            'snbPostedbyFilter',
            'snbBedroomFilter',
            'snbAvailablefromFilter',
            'snbPosteddateFilter',
            'snbAreaFilter',
            'snbAmenitiesFilter',
            'snbFurnishingFilter'
        ],
        'pg': [
            'snbFilters',
            'snbCityFilter',
            'snbLocalityFilter',
            'snbPOIFilter',
            'snbGenderFilter',
            'snbPostedbyFilter',
            'snbPriceFilter',
            'snbAvailablefromFilter',
            'snbOccupancyFilter',
            'snbBedroomFilter',
            'snbPosteddateFilter',
            'snbFurnishingFilter',
            'snbAmenitiesFilter'
        ],
        'projects': [
            'snbFilters',
            'snbCityFilter',
            'snbReraFilter',
            'snbSanitisedFilter',
            'snbLocalityFilter',
            'snbPOIFilter',
            'snbPriceFilter',
            'snbPropertyTypeFilter',
            'snbStatusFilter',
            'snbPossessionFilter',
            'snbBedroomFilter',
            'snbAreaFilter',
            'snbAmenitiesFilter',
            'snbCollectionFilter'
        ],
        'brokers': [
            'snbFilters',
            'snbLocalityFilter',
            'snbAdtypeFilter'
        ]
    },
    'defaultView':'list'
};
export default filters;
import {isSet,connectionCheck,debounce} from '../../utils/common'
import {eventTriggerQH,eventTriggeranalatyic} from '../../services/gtmService'

export const MultiAutosuggest = function () {

    var selectedOptions = [];
    var suggestions = [];
    var key = '';

    var selectInitial = function (initialSelected) {
        selectedOptions = [];
        for (var i = 0; i < initialSelected.length; i++) {
            if (isSet(initialSelected[i].id) && isSet(initialSelected[i].title))
                selectedOptions.push({id: initialSelected[i].id, title: initialSelected[i].title, index: i, city: initialSelected[i].city});
        }
        renderView();
    };
   
    function generateSuggestions(data) {
        var res = Array.isArray(data) ? data.slice() || [] : [];
        for (var i = 0; i < selectedOptions.length; i++) {
            for (var j = 0; j < res.length; j++) {
                if (res[j].id == selectedOptions[i].id) {
                    if (!selectedOptions[i].id) {
                        selectedOptions[i].id = res[j].id;
                        selectedOptions[i].index = i;
                        selectedOptions[i].city = res[j].city;
                    }

                    // Hide the suggestion if already selected
                    res[j].display = 'hide';
                    j--;
                    break;
                }
            }
        }
        for(var i = 0; i < res.length; i++){
            if(isSet(res[i].cityName)){
                res[i].city = res[i].cityName;
            }
            
        }
        suggestions = res;
        renderView();
    }

    function renderView() {
        if(snbFilterController.isMobile()) {
            $("#locspinner").removeClass('hide');
        }
        var htmlData = _.template($('#multiAutoSuggest').html());
        var drpSelector = $(".m-a-wrapper.multias .multiAutoSuggest_container");
        drpSelector.show();
        drpSelector.html(htmlData({
            'suggestions': suggestions,
            'selectedOptions': selectedOptions,
            'key': key
        }));
        if(snbFilterController.isMobile()) {
            $("#locspinner").addClass('hide');
        }
    }

    function hideSuggestion(index) {
        suggestions[index].display = 'hide';
    }

    function showSuggestion(index) {
        suggestions[index].display = 'block';
    }

    var resetAutosuggest = function () {
        selectedOptions = [];
        suggestions.forEach((selection) => (selection.display = 'show'));
        renderView();
    };

    var selectSuggestion = function (e, id) {
        var evt = e || window.event;
        var obj = evt.target.nodeName === "LABEL" ? evt.target.previousElementSibling : evt.target;
        var title = obj.getAttribute('data-title');
        var index = obj.getAttribute('data-index');
        var city = obj.getAttribute('data-city');
        selectedOptions.push({id, title, index, city});

        suggestions.forEach((suggestion) => {
            const isSelected = selectedOptions.findIndex((selection) => (selection.id == suggestion.id)) >= 0;
            suggestion.display = isSelected ? 'hide' : 'show';
        });

        if (evt.toElement && evt.toElement.parentElement.nodeName === 'LI') {
            evt.toElement.parentElement.classList.add('selected');
        }
        
        snbFilterController.selectLocality(selectedOptions, 'select');
        localStorage.setItem('resentSearchLocName',snbFilterController.filter.data.ad_locality[snbFilterController.filter.data.ad_locality.length-1]);
        localStorage.setItem('resentSearchLocId',snbFilterController.filter.data.ad_locids[snbFilterController.filter.data.ad_locids.length-1]);
        if(!snbFilterController.isMobile()) {
            var gtmData = {
                'event': 'Filter_select',
                'Filter_name': 'Locality_id',
                'Filter_value': id
            };
            if(connectionCheck()){
                eventTriggerQH(gtmData);
            } else {
                eventTriggeranalatyic(gtmData);
            }
        } else {
            renderView();
        }
    };

    var unselectSuggestion = function (id) {
        const selectedIndex = selectedOptions.findIndex((selection) => selection.id == id);
        const suggestionIndex = suggestions.findIndex((suggestion) => suggestion.id == id);
        selectedOptions.splice(selectedIndex, 1);
        if (suggestionIndex >= 0) {
            suggestions[suggestionIndex].display = 'show';
        }

        snbFilterController.selectLocality(selectedOptions, 'unselect');

        if(snbFilterController.isMobile()) {
            renderView();
        }
    };

    function debouncedKeypress() {
        var e = document.getElementById('multi_auto');
        if (e) {
            key = e.value;
            if(snbFilterController.isMobile()) {
                $("#locspinner").removeClass('hide');
            }
            var data = snbFilterController.getLocalityData(e.value);
            // data is the returned promise
            if (!!data) {
                data.then(function (data) {
                    generateSuggestions(data);
                     if(snbFilterController.isMobile()) {
                        $("#locspinner").addClass('hide');
                    }
                }, function (error) {
                    // console.log(error);
                });
            }
        }
    }

    var handleKeypress = debounce(function () {
        debouncedKeypress();
    }, 500);
    
    var allLocality = function (){
        if(isSet(globals.all_locality) && (snbFilterController.filter.data.clusterCity && snbFilterController.filter.data.clusterCity.length <=0)) {
            generateSuggestions(globals.all_locality); 
        } else {
            var entityType = (snbFilterController.filter.data.type == "projects")?'Project':'Listing';
            let isPg = snbFilterController.filter.data.type === 'pg' ? '1' : '';
            if(snbFilterController.isMobile()) {
                $("#locspinner").removeClass('hide');
            }
           let cityidval = (snbFilterController.filter.data.clusterCity && snbFilterController.filter.data.clusterCity.length>=1) ? snbFilterController.filter.data.clusterCity[0] : snbFilterController.filter.data.c_id;
           if (snbFilterController.apiReloadMap['snbLocalityFilter']) {
               $.ajax({
                   url: "/homes/re2/getallLocality",
                   method : "POST",
                   data:{
                       "cityId": cityidval,
                       "size" : 5,
                       "source" : "qh", 
                       "entityType" : entityType,
                       "isPg" : isPg,
                       "cityIds" : snbFilterController.filter.data.clusterCity || []
   
                   },
                   success: function(data) {
                       globals.all_locality = data;
                       generateSuggestions(data); 
                       snbFilterController.apiReloadMap['snbLocalityFilter'] = false;
                       if(snbFilterController.isMobile()) {
                           $("#locspinner").addClass('hide');
                       }
                   }
               });
           }
        }
    }
    
    return {
        handleKeypress: handleKeypress,
        unselectSuggestion: unselectSuggestion,
        selectSuggestion: selectSuggestion,
        resetAutosuggest: resetAutosuggest,
        showSuggestion: showSuggestion,
        selectInitial: selectInitial,
        selectedOptions: selectedOptions,
        allLocality: allLocality,
        suggestions: suggestions,
        key: key
    }
}();
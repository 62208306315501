import snbFiltersConstants from '../constants/snbFilterConstants'
import {encode} from '../../services/base64Service'
import {currencyFilter,replaceSpace} from '../../utils/common'
var snbPath = {};
snbPath.filters_all = snbFiltersConstants;

snbPath.getCatText = function (data) {
	if(data.type === 'brokers')return 'real-estate';
	return data.cat;
}

snbPath.getPropertyType = function (data) {
	if(!data.property_type || !data.property_type.length) {
		return '';
	}
	for(var i = 0; i < snbPath.filters_all[data.cat].property_type.length; i++) {
		var property = snbPath.filters_all[data.cat].property_type[i];
		if(data.property_type.indexOf(property.api_value) >= 0) {
			return property.value.replace(/ /g, "");
		}
	}
}

snbPath.getGender = function (data) {
	 if(!data.gender || !data.gender.length) {
	 	return '';
	 }
	 if(data.gender.length == 1){
		if(data.gender.indexOf('male') == 0){
			return 'boys';
		}
		else{
			return 'girls';
		}
	 } else {
		 return '';
	 }
	 
}

snbPath.getOccupancy = function (data) {
	if(!data.occupancy || !data.occupancy.length) {
		return '';
	}
	if(data.occupancy.length == 1){
	   if(data.occupancy.indexOf('Individual') == 0){
		   return 'Individual';
	   }
	   else{
		   return 'Sharing';
	   }
	} else {
		return '';
	}
	
}

snbPath.getFurnishing = function (data) {
	if(!data.furnishing || !data.furnishing.length) {
		return "";
	}
	if(data.cat != "residential") {
		return "";
	}
	if(data.furnishing.indexOf('wr') >= 0 && data.furnishing.indexOf('bd') >= 0) {
		return "fullyfurnished";
	}
	if(data.furnishing.indexOf('wr') >= 0) {
		return "semifurnished";
	}
	return "";
}

snbPath.filterPrice = function (price) {
	if(!price || price * 1 < 0) {
		return -1;
	}
	price = currencyFilter(price);
	if(typeof price == "number") {
		price += "";
	}
	price = price.replace(/\.00 /g, "");
	price = price.replace(/\s/g,'');
	price = price.split(",").join("");
	return price;
}

snbPath.getPrice = function (data) {
	var price_str = "";
	if(data.price) {
		var min = snbPath.filterPrice(data.price.min);
		var max = snbPath.filterPrice(data.price.max);
		if(min < 0 && max < 0) {
			return "";
		}
		if(max < 0) {
			max = "max";
		}
		if(min < 0) {
			min = "0";
		}
		price_str = "Rs_" + min + "_" + max;
	}
	return price_str;
}

snbPath.getArea = function (data) {
	if(data.area) {
		var min = data.area.min ? data.area.min * 1 : -1;
		var max = data.area.max ? data.area.max * 1 : -1;
		if(min < 0 && max < 0) {
			return "";
		}
		if(min < 0) {
			min = 0;
		}
		if(max < 0) {
			max = "max";
		}
		return (min + "_" + max + "sqft");
	}
	return "";
}

snbPath.getType = function (data) {
	if(data.type == "buy") {
		return "sale";
	}
	if(data.type == "pg") {
		return "pg_flatmates"
	}
	if(data.type == "projects") {
		return "projects";
	}
	if(data.type == "brokers") {
		return "brokers";
	}
	return "rent";
}

snbPath.getPostedBy = function (data) {
	if((!data.posted_by || !data.posted_by.length) || data.type == 'brokers') {
		return '';
	}
	for(var i = 0; i < snbPath.filters_all[data.cat].posted_by.length; i++) {
		var posted = snbPath.filters_all[data.cat].posted_by[i];
		if(data.posted_by.indexOf(posted.api_value) >= 0) {
			return posted.value;
		}
	}
}

snbPath.getBhk = function (data) {
	var bhk = "";
	if(data.cat == "residential") {
		if(!data.bedrooms) {
			return "";
		}
		if(data.bedrooms.length>0) {
			bhk = data.bedrooms[0];
			bhk = bhk.replace(/5/, "4+");
			if(bhk) {
				bhk += "bhk";
			}
		}
	}
	return bhk;
}

snbPath.formatPath = function (path) {
	path = path.trim();
	path = path.replace(/\//g, '');
	path = path.replace(/ /g, '-');
	path = path.replace(/\-+/g, "-");
	path = path.toLowerCase();
	return path;
}

snbPath.getCity = function (city) {
	if(!city) {
		return "";
	}
	city = city.replace(/ /g, "_");
	return city;
}

snbPath.getLoc = function (loc) {
	if(!loc.length) {
		return "";
	}
	loc = loc[0].replace(/ /g, "_");
	return loc;
}
snbPath.getLocId = function (locId){
	if(!locId.length) {
		return "";
	}
	return ("lid_" + locId[0]);
}

snbPath.getPoiId = function (poiIds = []){
	if(!poiIds.length) {
		return "";
	}
	return ("pid_" + poiIds[0]);
}

snbPath.getPoiName = function (poiNames = []) {
	if(!poiNames.length) {
		return "";
	}
	return poiNames[0].replace(/ /g, "_");
}

snbPath.getzoneRegionId = function (regZonIdId,type){
	if(!regZonIdId.length) {
		return "";
	}
	return (type+"_" + regZonIdId[0]);
}

snbPath.getRear = function (data){
	if(data.isReraApproved) {
		return ("rera-registered");
	} else {
		return "";
	}
}

snbPath.getSanitized = function (data){
	if(data.type === 'projects' && data.hasCovidMeasures)
		return ("sanitized");
	else
		return "";
}

snbPath.getSaleType = function (data){
	if(data.saleType && +data.saleType === 2)
		return ('bank-auction');
	else
		return '';
}

snbPath.getReraPossession = function (data){
	var reraPath = '';
	if(data.type === 'projects') {
		if(data.isReraApproved && data.projectstatus && data.projectstatus == 'completed') {
			reraPath = 'rera-registered-ready-to-move-in-projects';
		} else if(data.isReraApproved && data.projectstatus && data.projectstatus != 'completed') {
			reraPath = 'rera-registered-'+data.projectstatus+'-projects';
		} else if(data.isReraApproved && !data.projectstatus) {
			reraPath = 'rera-registered-projects';
		} else if(data.projectstatus  && data.projectstatus != 'completed' && !data.isReraApproved) {
			reraPath = data.projectstatus+'-projects';
		} else if(data.projectstatus  && data.projectstatus == 'completed' && !data.isReraApproved) {
			reraPath = 'ready-to-move-in-projects';
		}
	}
	if(data.type === 'buy') {
		if(data.isReraApproved){
			reraPath = 'rera-registered-listing';
		}
	}

	return reraPath;
}

export const buildPath = function (data) {
	var obj = {
		cat: snbPath.getCatText(data),
		type: snbPath.getType(data),
		property_type: snbPath.getPropertyType(data),
		furnishing: snbPath.getFurnishing(data),
		price: snbPath.getPrice(data),
		area: snbPath.getArea(data),
		poster: snbPath.getPostedBy(data),
		bhk: snbPath.getBhk(data),
		loc: snbPath.getLoc(data.ad_locality),
		lid: snbPath.getLocId(data.ad_locids),
		reg: snbPath.getLoc(data.ad_regionName),
		reg_id: snbPath.getzoneRegionId(data.ad_regionid,'rid'),
		zone: snbPath.getLoc(data.ad_zoneName),
		zone_id: snbPath.getzoneRegionId(data.ad_zoneid,'zid'),
		cid: "cid_" + data.c_id,
		co: (data.collection_id) ? "co_" + data.collection_id : '',
		co_name: (data.collection_name && data.collection_name.length) ?  replaceSpace(data.collection_name) : '',
		city: snbPath.getCity(data.c),
		poiId: snbPath.getPoiId(data.poiIds),
		poiName: snbPath.getPoiName(data.poiNames),
		reraPossession: snbPath.getReraPossession(data),
		sanitized: snbPath.getSanitized(data),
		saleType: snbPath.getSaleType(data)
	};
	let gender = '';
	if(data.type == 'pg'){
		gender = obj.gender = snbPath.getGender(data);
	}
	let catType = '';
	var type = obj.type === 'projects' ? 'sale' : obj.type;
	if(obj.type == 'projects'){
		catType = 'projects'
	}
	if(obj.type == 'brokers'){
		catType = 'brokers'
		type = ''
	}

	var loc_reg_zone = '',loc_reg_zone_id = '';
	if(obj.loc && obj.lid) {
		loc_reg_zone = obj.loc;
		loc_reg_zone_id = obj.lid;
	} else if(obj.reg && obj.reg_id) {
		loc_reg_zone = obj.reg;
		loc_reg_zone_id = obj.reg_id;
	} else if(obj.zone && obj.zone_id) {
		loc_reg_zone = obj.zone;
		loc_reg_zone_id = obj.zone_id
	}

	var path = (" " 
		+ obj.cat + " " 
		+ obj.property_type + " "
		+ obj.saleType + " "
		+ obj.furnishing + " "
		+ obj.bhk + " "
        + obj.sanitized + " "
		+ (catType ? (catType + " ") : "")
		+ (type ? ("for " + type) : "") + " "
		+ obj.price + " "
		+ obj.area + " "
		+ (gender ? (obj.gender + " ") : "")
		+ (obj.poster ? ("by " + obj.poster) : "") + " "
		+ "in" + " "
		+ loc_reg_zone + " "
		+ obj.city + " "
		+ (obj.poiName && obj.poiName.trim() ? "near " + obj.poiName : " ") + " "
		);

	var path1='',reraLink='',collectionLink='';
	if(obj.type == 'projects' && obj.co && obj.co_name) {
		path1= snbPath.formatPath(path 
		+ obj.cid + " "
		+ obj.co + " "
		+ loc_reg_zone_id + " "
		+ obj.poiId + " ");

		collectionLink = obj.co_name;

	}else if(obj.reraPossession){
		path1 = snbPath.formatPath(path);
		reraLink= obj.reraPossession+'-'+snbPath.formatPath(" "+obj.cid + " "
		+ loc_reg_zone_id + " "
		+ obj.poiId + " ");

	} else {
		path1= snbPath.formatPath(path 
		+ obj.cid + " "
		+ loc_reg_zone_id + " "
		+ obj.poiId + " ");
	}

	var finalPath = {
		'path' : path1,
		'reraLink' : reraLink,
		'collectionLink' : collectionLink
		};
	return finalPath;
}

export const buildQuery = function (data) {
	var query = {};
	if(data.ad_locality && data.ad_locality.length) {
		query.ad_locality = data.ad_locality;
		query.ad_locids = data.ad_locids;
		query.ad_loccitynames = data.ad_loccitynames;
	}
	if(data.poiIds && data.poiIds.length) {
		query.poiIds = data.poiIds;
		query.poiNames = data.poiNames;
	}
	if(data.property_type && data.property_type.length) {
		query.property_type = data.property_type;
	}
	if(data.possession && data.possession.length) {
		query.possession = data.possession;
	}
	if(data.bedrooms && data.bedrooms.length) {
		query.bedrooms = data.bedrooms;
	}
	if(data.posted_by && data.posted_by.length) {
		query.posted_by = data.posted_by;
	}
	if(data.posted_date && data.posted_date.length) {
		query.posted_date = data.posted_date;
	}
	if(data.furnishing && data.furnishing.length) {
		query.furnishing = data.furnishing;
	}
	if(data.amenities && data.amenities.length) {
		query.amenities = data.amenities;
	}
	if(data.furnishingStatus && data.furnishingStatus.length) {
		query.furnishingStatus = data.furnishingStatus;
	}
	if(data.clusterCity && data.clusterCity.length) {
		query.clusterCity = data.clusterCity;
	}
	if(data.sortby && data.sortby.length) {
		query.sortby = data.sortby;
	}
	if(data.gender && data.gender.length) {
		query.gender = data.gender;
	}
	if(data.occupancy && data.occupancy.length) {
		query.occupancy = data.occupancy;
	}
	if(data.isReraApproved) {
		query.isReraApproved = data.isReraApproved;
	}
	
	if(data.available_from && data.available_from.length) {
		query.available_from = data.available_from;
	}
	if(data.isQAP) {
		query.isQAP = data.isQAP;
	}
	if(data.isGrabHouse) {
		query.isGrabHouse = data.isGrabHouse;
	}

	if(data.ad_type && data.ad_type.length) {
		query.ad_type = data.ad_type;
	}
	if(typeof query === 'object' && Object.keys(query).length == 0) {
		return '';
	}
	if(data.type === 'projects' && data.hasCovidMeasures)
	{
		query.hasCovidMeasures = data.hasCovidMeasures;
	}
	query = JSON.stringify(query);
	query = encode(query);
	return query;
}



import {isSet} from '../utils/common'
import {getCookie} from './cookieService'
export function mobileapplyGtm(){
	var gtmData = {
		'event': 'Filter_select',
	};
	var filterdata = {};
	if (isSet(snbFilterController.filtersLabel.locality)) {
		filterdata['Locality'] = snbFilterController.filtersLabel.locality;
	}
	if (isSet(snbFilterController.filter.data.price.min)) {
		filterdata['Price_min'] = snbFilterController.filter.data.price.min;
	}
	if (isSet(snbFilterController.filter.data.price.min)) {
		filterdata['Price_max'] = snbFilterController.filter.data.price.max;
	}
	if (isSet(snbFilterController.filter.data.area.min)) {
		filterdata['Area_min'] = snbFilterController.filter.data.area.min;
	}
	if (isSet(snbFilterController.filter.data.area.min)) {
		filterdata['Area_max'] = snbFilterController.filter.data.area.max;
	}
	if (isSet(snbFilterController.filtersLabel.no_of_rooms)) {
		filterdata['Bhk'] = snbFilterController.filtersLabel.no_of_rooms;
	}
	if (isSet(snbFilterController.filtersLabel.furnishing)) {
		filterdata['Furnishing'] = snbFilterController.filtersLabel.furnishing;
	}
	if (isSet(snbFilterController.filtersLabel.posted_by)) {
		filterdata['Posted_by'] = snbFilterController.filtersLabel.posted_by;
	}
	if (isSet(snbFilterController.filtersLabel.property_type)) {
		filterdata['Property_type'] = snbFilterController.filtersLabel.property_type;
	}
	if (isSet(snbFilterController.filtersLabel.amenities)) {
		filterdata['Amenity'] = snbFilterController.filtersLabel.amenities;
	}
	if (isSet(snbFilterController.filtersLabel.posted_date)) {
		filterdata['PostedDate'] = snbFilterController.filtersLabel.posted_date;
	}
	if (isSet(snbFilterController.filtersLabel.gender)) {
		filterdata['Gender'] = snbFilterController.filtersLabel.gender;
	}
	if (isSet(snbFilterController.filtersLabel.ad_type)) {
		filterdata['AdType'] = snbFilterController.filtersLabel.ad_type;
	}
	gtmData['filter'] = filterdata;
	gtmData['user_db_id'] = getCookie('__u');
	return gtmData;
}
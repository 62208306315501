import { ComponentResolver } from "./component-resolver";

/**
 * Inflates component with templates
 * 
 * @author Suman Kumar Das
 */
export class ComponentInflater {

    constructor(type) {
        this.resolver = new ComponentResolver(type);
    }

    /**
     * Inflates the component using {@link ComponentResolver}
     */
    inflate() {
        // Resolve the components prior to mount
        this.resolver.resolve();

        // Inflate the component templates
        this.resolver.components.forEach((component) => component.render());
    }

}
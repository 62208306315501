/**
 * Simple implementation for the `EventEmitter` interface
 * 
 * @author Suman Kumar Das
 */
export class EventEmitter {

    constructor() {
        this.listeners = {};
    }

    /**
     * Register event listener for an event 
     * 
     * @param {String} event the event name
     * @param {Function} listener the event listener
     */
    on(event, listener) {
        if (!event || 'string' !== typeof event) {
            throw new TypeError('Invalid event name');
        }

        if (!(listener instanceof Function)) {
            throw new TypeError('Invalid event listener');
        }

        if (!this.listeners.hasOwnProperty(event)) {
            this.listeners[event] = [listener];
        } else {
            this.listeners[event].push(listener);
        }
    }

    /**
     * Unregister an event listener from an event
     * 
     * @param {String} event the event name
     * @param {Function} listener the event listener
     */
    off(event, listener) {
        if (!event || 'string' !== typeof event) {
            throw new TypeError('Invalid event name');
        }

        if (!(listener instanceof Function)) {
            throw new TypeError('Invalid event listener');
        }

        if (this.listeners.hasOwnProperty(event)) {
            const idxListener = this.listeners[event].findIndex((l) => l === listener);
            this.listeners[event].splice(idxListener, 1);
        }
    }

    /**
     * Emits an event along with given payload
     * 
     * @param {String} event the event name
     * @param {any} payload the event payload
     */
    emit(event, payload) {
        if (!event || 'string' !== typeof event) {
            throw new TypeError('Invalid event name');
        }

        if (this.listeners.hasOwnProperty(event)) {
            this.listeners[event].forEach((listener) => listener(payload));
        }
    }

}
import {decode} from '../../services/base64Service'
import {isSet} from '../../utils/common'
import { getH1 } from './snbHelperService'
import { buildQuery,buildPath } from './snbPathService'
var urlHandler = {};

export const getSnbTabPath = (filter) => {
	var path = buildPath(filter.data);
	var query = buildQuery(filter.data);
	var text = filter.data.text;
	var params = "";
	if(text) {
		params += "text=" + text;
	}
	if(query) {
		if(params) {
			params += "&";
		}
		params += "q=" + query;
	}
	if(!path) {
		return;
	}
	if(path.path.indexOf("-cid") === -1) {
		path.path = path.path+'-cid_'+filter.data.c_id;
	} 
	var full_path = "/homes/property/" + path.path;
	if(params) {
		return full_path + '?' + params;
	}
	else {
		return full_path;
	}
}

urlHandler.setSearchParam = function (path, reraLink, collectionLink, query) {
	if(!path) {
		return;
	}
	var full_path;
	if(reraLink) {
		full_path = "/homes/property/" + path + "/"+ reraLink;
	} else if(collectionLink) {
		full_path = "/homes/property/" + path + "/"+collectionLink ;
	} else {
		full_path = "/homes/property/" + path ;
	}
	if(query) {
		urlHandler.push(full_path, query);
	}
	else {
		urlHandler.push(full_path);
	}
}
export const getUrlSearchObj = (q)=> {
	var query = getQueryVariables(q);
	if(query && q!== 'ampredirect') {
		query = decode(query);
		
		try {
			query = JSON.parse(query);
		} catch(e) {
			query = {};
		}
		
		return query;
	}else if(q === 'ampredirect'){
		return query;
	}else{
		return null;
	}
}

export const setSnbUrl = (filter)=> {
	var path = buildPath(filter.data);
	var query = buildQuery(filter.data);
	var text = filter.data.text;
	var params = "";
	if(text) {
		params += "text=" + text;
	}
	if(isSet(query)) {
		if(params) {
			params += "&";
		}
		params += "q=" + query;
	}
	urlHandler.setSearchParam(path.path, path.reraLink, path.collectionLink, params);
}

export const setH1 = (filter)=>{
	var head = getH1(filter);
	var tag = document.getElementById("h1_title");
	if(tag) {
		tag.innerHTML = head;
	}
}

urlHandler.parse = function (str) {
    if (typeof str !== 'string') {
        return {};
    }

    str = str.trim().replace(/^\?/, '');

    if (!str) {
        return {};
    }

    return str.trim().split('&').reduce(function (ret, param) {
        var parts = param.replace(/\+/g, ' ').split('=');
        var key = parts[0];
        var val = parts[1];

        key = decodeURIComponent(key);
        // missing `=` should be `null`:
        // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
        val = val === undefined ? null : decodeURIComponent(val);

        if (!ret.hasOwnProperty(key)) {
            ret[key] = val;
        } else if (Array.isArray(ret[key])) {
            ret[key].push(val);
        } else {
            ret[key] = [ret[key], val];
        }

        return ret;
    }, {});
};

urlHandler.stringify = function (obj) {
    return obj ? Object.keys(obj).map(function (key) {
        var val = obj[key];

        if (Array.isArray(val)) {
            return val.map(function (val2) {
                return encodeURIComponent(key) + '=' + encodeURIComponent(val2);
            }).join('&');
        }

        return encodeURIComponent(key) + '=' + encodeURIComponent(val);
    }).join('&') : '';
};

urlHandler.push = function (pathname, new_params_string) {
    if(new_params_string) {
    	history.pushState({}, "", pathname + '?' + new_params_string);
    }
    else {
    	history.pushState({}, "", pathname);
    }
}
export const getQueryVariables = (variable)=> {
	if(window.location.search) {
		var query = window.location.search.substring(1);
		var vars = query.split('&');
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split('=');
			if (decodeURIComponent(pair[0]) == variable) {
				return decodeURIComponent(pair[1]);
			}
		}
	}
	return false;
}

export function setitleH1(filter){
	return getH1(filter);
}
export default urlHandler;
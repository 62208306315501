import snbFiltersConstants from '../constants/snbFilterConstants'
import {currencyFilter} from '../../utils/common'
var snbHelper = {};
snbHelper.filters_all = snbFiltersConstants;

snbHelper.handleMinusOne = function (input) {
	if(input * 1 < 0) {
		return '';
	}
	return input;
}

snbHelper.getUrlPathQuery = function (filter) {
	var obj = {
		'path': '',
		'query': {}
	};

	if(!filter || !filter.data) {
		return obj;
	}

	obj.query = snbHelper.buildQuery(filter.data);
	obj.path = snbHelper.buildPath(filter.data);

	return obj;
}

snbHelper.getJsonData = function () {
	return {
		"bhk" : "",
		"propertyType" : "",
		"city" : "",
		"locality" : "",
		"price" : "",
		"gender": "",
		"poi": ""
	}
}

snbHelper.getCategory = function (filter) {

	if(filter.data.cat) {
		return " " + snbHelper.capitalizeFirstLetter(filter.data.cat) + " Property";	
	}
	return '';
}

snbHelper.getGender = function (filter) {
	if(!filter.data.gender || !filter.data.gender.length) {
		return '';
	}
	if(filter.data.gender.length == 1){
	   if(filter.data.gender.indexOf('male') == 0){
		   return 'boys';
	   }
	   else{
		   return 'girls';
	   }
	} else {
		return '';
	}
	
}

snbHelper.getPropertyType = function (filter) {

	if(filter.data.property_type.length > 0) {
		var propertyType = [];
		_.each(filter.data.property_type, function (value , key) {
			propertyType.push(snbFiltersConstants[filter.data.cat].property_types[value].value);
		});
		propertyType = " " + propertyType.toString();
	} else {
		var propertyType = snbHelper.getCategory(filter);
	}
	return propertyType;
}

snbHelper.getBhk = function (filter) {
	if(filter.data.bedrooms && filter.data.bedrooms.length) {
		return " " + filter.data.bedrooms.sort().toString() + " BHK";
	}
	return '';
}

snbHelper.getType = function (filter) {
	var type;
	switch(filter.data.type) {
		case 'buy' :
			type = " for Sale";
			break;
		case 'pg' : 
			type = " PG/Flatmates for rent";
			break;
		default : 
			type = " for Rent";
	}
	return type;
}

snbHelper.getLocality = function (filter) {
	if(filter.data.ad_locality && filter.data.ad_locality.length) {
		var multiple_loc = filter.data.ad_locality.reduce(function(prevVal,currVal,idx){
		    return idx == 0 ? currVal : prevVal + ', ' + currVal;
		}, '')
		return multiple_loc+', ';
	}
	return '';
}

snbHelper.getCity = function (filter) {
	if(filter.data.c) {
		return filter.data.c;
	}
	return '';
}

snbHelper.getListingCount = function () {
	if(snbHelper.filter.listingCount) {
		return " " + snbHelper.filter.listingCount;
	}
	return '';
}

snbHelper.getPrice = function (price) {
	if(price * 1 <= 0) {
		return 0;
	}
	price = $filter('currencyf')(price);
	price = price.replace(/\.00/g, "");
	price = price.split(",").join("");
	return price;
}

snbHelper.getPostedBy = function (cat, selected_posted_by) {
	if(!selected_posted_by || !selected_posted_by.length) {
		return '';
	}
	var posted_by = [];
	for(var i = 0; i < snbHelper.filters_all[cat].posted_by.length; i++) {
		var posted = snbHelper.filters_all[cat].posted_by[i];
		if(selected_posted_by.indexOf(posted.api_value) >= 0) {
			posted_by.push(posted.value);
		}
	}
	if(posted_by.length >= 0) {
		return posted_by[0];
	}
	return '';
}

snbHelper.getFurnishing = function (furnishings) {
	if(!furnishings || !furnishings.length) {
		return '';
	}
	if(furnishings.indexOf('wr') >= 0 || furnishings.indexOf('mk') >= 0) {
		if(furnishings.indexOf('wr') >= 0 && furnishings.indexOf('mk') >= 0) {
			if(furnishings.length > 2) {
				return 'fully-furnished';
			}
			return 'semi-furnished';
		}
		else if(furnishings.length >= 2) {
			return 'fully-furnished';
		}
		return 'semi-furnished';
	}
	return '';
}

snbHelper.buildQuery = function (data) {
	var query = {};
	query.c = data.c;
	query.c_id = data.c_id;
	if(data.loc) {
		query.loc = data.loc;
	}
	if(data.loc_id) {
		query.loc_id = data.loc_id;
	}
	if(data.type) {
		query.type = data.type;
	}
	if(data.cat) {
		query.cat = data.cat;
	}
	if(data.text) {
		query.text = data.text;
	}
	if(data.amenities && data.amenities.length) {
		query.amenities = data.amenities;
	}
	if(data.furnishing && data.furnishing.length) {
		query.furnishing = data.furnishing;
	}
	if(data.bedrooms && data.bedrooms.length) {
		query.bedrooms = data.bedrooms;
	}
	if(data.property_type && data.property_type.length) {
		query.property_type = data.property_type;
	}
	if(data.gender && data.gender.length) {
		query.gender = data.gender;
	}
	if(data.posted_by && data.posted_by.length) {
		query.posted_by = data.posted_by;
	}
	if(data.posted_date && data.posted_date.length) {
		query.posted_date = data.posted_date;
	}
	if(data.isReraApproved && data.isReraApproved.length) {
		query.isReraApproved = data.isReraApproved;
	}
	if(data.price && data.price.min) {
		query.price = {};
		query.price.min = data.price.min;
	}
	if(data.price && data.price.max) {
		query.price.max = data.price.max;
	}
	if(data.area && data.area.min) {
		query.area = {};
		query.area.min = data.area.min;;
	}
	if(data.area && data.area.max) {
		query.area.max = data.area.max;
	}
	return query;
}

snbHelper.getListingUrl = function (city, listings) {
	if(!listings || !listings.length) {
		return [];
	}
	listings.forEach(function (l) {
		if(l.entityType && l.entityType == 'project') {
			 l.seoUrl = seoService.constructPapUrl(l, 'project');
		}else {
			 l.seoUrl = seoService.constructVapUrl(l, 'listing');
		}

	});
	return listings;
}

snbHelper.getApiCompatObj = function (filter, page, no, map, nodeId) {
	var obj = {};
	obj.map = map;
	if(filter.data.cat == 'residential') {
		obj.c = 'r';
	}
	else if(filter.data.cat == 'commercial') {
		obj.c = 'c';
	}
	else {
		obj.c = 'a';
	}

	if(filter.data.type == 'buy' || filter.data.type == 'projects') {
		obj.t = 'b';
	}
	else if(filter.data.type == 'rent') {
		obj.t = 'r';
	}
	else {
		obj.t = 'p';
	}

	obj.pb = filter.data.posted_by.toString();

	obj.p = filter.data.pics;

	obj.s = filter.data.sortby;

	if(obj.t == 'p') {
		obj.pt = 'pg,fl';
	}
	else {
		if(obj.c == 'r') {
			if(!filter.data.property_type 
				|| !filter.data.property_type.length) {
				obj.pt = "ap,vi,rp,bf";
			}
			else {
				obj.pt = filter.data.property_type.toString();
			}
		}
		else if(obj.c == 'c') {
			if(!filter.data.property_type 
				|| !filter.data.property_type.length) {
				obj.pt = "cp,bf,sh,bu,of";
			}
			else {
				obj.pt = filter.data.property_type.toString();
			}
		} else if (obj.c == 'a') {
			obj.pt = "ag";
		}
	}

	obj.r = filter.data.bedrooms.toString();

	obj.am = filter.data.amenities.toString();

	obj.f = filter.data.furnishing.toString();

	obj.g = filter.data.gender;

	obj.pl = snbHelper.handleMinusOne(filter.data.price.min);

	obj.ph = snbHelper.handleMinusOne(filter.data.price.max);

	obj.alo = filter.data.ad_locality.toString();

	obj.locs = filter.data.ad_locids.toString();

	obj.l = filter.data.loc;

	obj.ci = filter.data.c_id;

	obj.ct = filter.data.c;

	obj.al = snbHelper.handleMinusOne(filter.data.area.min);

	obj.ah = snbHelper.handleMinusOne(filter.data.area.max);

	obj.d = filter.data.posted_date.toString();

	if(filter.data.text) {
		obj.ft = filter.data.text;
	}

	obj.page = page;

	obj.sz = no;

	obj.collectionIds = filter.data.collection_id || null;
	obj.nodeId = nodeId || null;

	return obj;
}

export const getH1 = function (filter) {
	var filterVal = "";
	if (!filter || !filter.data) {
		return;
	}
	var head = '';
	var intent = filter.data.type;
	if(intent.toUpperCase() === 'BUY'){
		intent = 'Sale';
	}
	var title = "",title1 = "", title2 = "",
	json = snbHelper.getJsonData();

	if(typeof filter.data.price === 'object'){
		 if(parseInt(filter.data.price.min) >0 && parseInt(filter.data.price.max)>0){
			json.price =  ' within budget of '+currencyFilter(filter.data.price.min) + ' to ' + currencyFilter(filter.data.price.max);
			filterVal += "1";
		} else if (parseInt(filter.data.price.min)>0){
			filterVal += "1";
			json.price =  ' starting from ' +currencyFilter(filter.data.price.min);
		} else if (parseInt(filter.data.price.max)>0){
			json.price =  ' within '+currencyFilter(filter.data.price.max);
			filterVal += "1";
		} else{
			filterVal += "0";
		}
	}
	if(Array.isArray(filter.data.ad_locality) && filter.data.ad_locality.length > 0) {
		var locality = [];
		_.each(filter.data.ad_locality, function (value) {
			locality.push(value)
		});
		json.locality = ""+  locality.join(', ');
	}
	else if(filter.data.ad_locality != undefined && filter.data.ad_locality != null && filter.data.ad_locality != ''){
		locality.push(filter.data.ad_locality);
		json.locality = ""+  locality.join(', ');
	}

	if(json.locality != null && json.locality != ''){
		filterVal += "1";
	}
	else{
		filterVal += "0";
	}

	if(filter.data.property_type.length > 0) {
		var propertyType = [];
		_.each(filter.data.property_type, function (value , key) {
			if(value === 'ap'){
				propertyType.push('Apartments/Flats');
			}
			else{
				propertyType.push(snbFiltersConstants[filter.data.cat].property_types[value].value);
			}
		});
		propertyType = " " + propertyType.toString();
	}

	json.propertyType = propertyType;
	if(json.propertyType != null && json.propertyType != ''){
		filterVal += "1";
	}
	else{
		filterVal += "0";
	}

	if(filter.data.cat === 'residential' || filter.data.cat === 'Residential'){
		json.bhk = snbHelper.getBhk(filter);
		if(json.bhk != null && json.bhk != ''){
			filterVal += "1";
		}
		else{
			filterVal += "0";
		}
	}
	
	if (filter.data.type == 'pg') {
		json.gender = snbHelper.getGender(filter);
		if (json.gender != "") {
			json.gender = (json.gender === 'boys') ? "for boys" : "for girls";
		} else {
			json.gender = '';
		}
	}

	// POI on H1
	if(Array.isArray(filter.data.poiNames) && filter.data.poiNames.length > 0) {
		json.poi = filter.data.poiNames.join(', ');
	}
	filterVal += (json.poi && json.poi.trim()) ? "1" : "0";
	
	json.city = capitalizeFirstLetter(snbHelper.getCity(filter));

	json.localityorCity = (json.locality && json.locality != "") ? capitalizeFirstLetter(json.locality) : json.city;

	var seoObj = {
		"residential" : {
			"00000" : "Residential Properties for " + intent + " in " + json.city,
			"10000" : "Residential Properties for " + intent + json.price+" in " + json.city,
			"00010" : json.bhk + " Residential Properties for " + intent + " in " + json.city,
			"10010" : json.bhk + " Residential Properties for " + intent+ "" +json.price+ " in " + json.city,
			"00110" : json.bhk.trim() + " Residential " + json.propertyType +  " for " + intent + " in " + json.city,
			"10110" : json.bhk.trim() + " Residential " + json.propertyType +  " for " + intent + "" +json.price+ " in " + json.city,
			"00100" : " Residential " +json.propertyType + " for " + intent + " in " + json.city,
			"10100" : " Residential " +json.propertyType + " for " + intent + "" +json.price+ " in " + json.city,
			"01000" : "Residential Properties for " + intent + " in " + json.locality + ", " + json.city,
			"11000" : "Residential Properties for " + intent + "" +json.price+ " in " + json.locality + ", " + json.city,
			"01010" : json.bhk + " Residential Properties for " + intent + " in " + json.locality + ", " + json.city ,
			"11010" : json.bhk + " Residential Properties for " + intent + "" +json.price+ " in " + json.locality + ", " + json.city ,
			"01100" : " Residential " + json.propertyType + " for " + intent + " in " +json.locality + ", " +json.city,
			"11100" : " Residential " + json.propertyType + " for " + intent + "" +json.price+ " in " + json.locality + ", " + json.city,
			"01110" : json.bhk + " Residential " + json.propertyType + " for " + intent + " in " + json.locality + ", " + json.city,
			"11110" : json.bhk + " Residential " +json.propertyType + " for " + intent + "" +json.price+ " in " + json.locality + ", " + json.city,

			// With POI
			"00001" : "Residential Properties for " + intent + " in " + json.city + " near " + json.poi,
			"10001" : "Residential Properties for " + intent + json.price+" in " + json.city + " near " + json.poi,
			"00011" : json.bhk + " Residential Properties for " + intent + " in " + json.city + " near " + json.poi,
			"10011" : json.bhk + " Residential Properties for " + intent+ "" +json.price+ " in " + json.city + " near " + json.poi,
			"00111" : json.bhk.trim() + " Residential " + json.propertyType +  " for " + intent + " in " + json.city + " near " + json.poi,
			"10111" : json.bhk.trim() + " Residential " + json.propertyType +  " for " + intent + "" +json.price+ " in " + json.city + " near " + json.poi,
			"00101" : " Residential " +json.propertyType + " for " + intent + " in " + json.city + " near " + json.poi,
			"10101" : " Residential " +json.propertyType + " for " + intent + "" +json.price+ " in " + json.city + " near " + json.poi,
			"01001" : "Residential Properties for " + intent + " in " + json.locality + ", " + json.city + " near " + json.poi,
			"11001" : "Residential Properties for " + intent + "" +json.price+ " in " + json.locality + ", " + json.city + " near " + json.poi,
			"01011" : json.bhk + " Residential Properties for " + intent + " in " + json.locality + ", " + json.city + " near " + json.poi ,
			"11011" : json.bhk + " Residential Properties for " + intent + "" +json.price+ " in " + json.locality + ", " + json.city + " near " + json.poi ,
			"01101" : " Residential " + json.propertyType + " for " + intent + " in " +json.locality + ", " +json.city,
			"11101" : " Residential " + json.propertyType + " for " + intent + "" +json.price+ " in " + json.locality + ", " + json.city + " near " + json.poi,
			"01111" : json.bhk + " Residential " + json.propertyType + " for " + intent + " in " + json.locality + ", " + json.city + " near " + json.poi,
			"11111" : json.bhk + " Residential " +json.propertyType + " for " + intent + "" +json.price+ " in " + json.locality + ", " + json.city + " near " + json.poi,

			"pg"   : "Rental Pg " + json.gender +" in " + json.localityorCity

		},
		"commercial" : {
			"0000" : "Commercial Properties for " + intent + " in " + json.city,
			"1000" : "Commercial Properties for " + intent + "" +json.price+ " in " + json.city,
			"0010" : json.propertyType + " for " + intent + " in " + json.city,
			"1010" : json.propertyType + " for " + intent + "" +json.price+ " in " + json.city,
			"0100" : "Commercial Properties for " + intent + " in " + json.locality + ", " + json.city,
			"1100" : "Commercial Properties for " +  intent + "" +json.price+ " in " + json.locality + ", " + json.city,
			"0110" : json.propertyType + " for " + intent + " in " + json.locality + ", " + json.city,
			"1110" : json.propertyType + " for " + intent + "" +json.price+ " in " + json.locality + ", " + json.city,

			// With POI
			"0001" : "Commercial Properties for " + intent + " in " + json.city + " near " + json.poi,
			"1001" : "Commercial Properties for " + intent + "" +json.price+ " in " + json.city + " near " + json.poi,
			"0011" : json.propertyType + " for " + intent + " in " + json.city + " near " + json.poi,
			"1011" : json.propertyType + " for " + intent + "" +json.price+ " in " + json.city + " near " + json.poi,
			"0101" : "Commercial Properties for " + intent + " in " + json.locality + ", " + json.city + " near " + json.poi,
			"1101" : "Commercial Properties for " +  intent + "" +json.price+ " in " + json.locality + ", " + json.city + " near " + json.poi,
			"0111" : json.propertyType + " for " + intent + " in " + json.locality + ", " + json.city + " near " + json.poi,
			"1111" : json.propertyType + " for " + intent + "" +json.price+ " in " + json.locality + ", " + json.city + " near " + json.poi
		},
		"agricultural" : {
			"0000" :"Agricultural Properties for "+ intent + " in " + json.city,
			"1000" :"Agricultural Properties for "+ intent + "" +json.price+ " in " + json.city,
			"0010" :"Agricultural Properties for "+ intent + " in " + json.city,
			"1010" :"Agricultural Properties for "+ intent+ "" +json.price + " in " + json.city,
			"0100" :"Agricultural Properties for "+ intent + " in " + json.city,
			"1100" :"Agricultural Properties for "+ intent + "" +json.price+ " in " + json.city,
			"0110" :"Agricultural Properties for "+ intent + " in " + json.city,
			"1110" :"Agricultural Properties for "+ intent + "" +json.price+ " in " + json.city,

			// With POI
			"0001" :"Agricultural Properties for "+ intent + " in " + json.city + " near " + json.poi,
			"1001" :"Agricultural Properties for "+ intent + "" +json.price+ " in " + json.city + " near " + json.poi,
			"0011" :"Agricultural Properties for "+ intent + " in " + json.city + " near " + json.poi,
			"1011" :"Agricultural Properties for "+ intent+ "" +json.price + " in " + json.city + " near " + json.poi,
			"0101" :"Agricultural Properties for "+ intent + " in " + json.city + " near " + json.poi,
			"1101" :"Agricultural Properties for "+ intent + "" +json.price+ " in " + json.city + " near " + json.poi,
			"0111" :"Agricultural Properties for "+ intent + " in " + json.city + " near " + json.poi,
			"1111" :"Agricultural Properties for "+ intent + "" +json.price+ " in " + json.city + " near " + json.poi
		},
		"brokers" : {
			"000" :"Property Dealers in " + json.city
		}
	};

	if(filter.data.type === 'pg'){
		filterVal = 'pg';
	}
	
	head = seoObj[filter.data.cat][filterVal];
	

	if(filter.data.type =='projects'){
		var possession = '';
		if(filter.data.possession){
			if(filter.data.possession == 'Ready to move'){
				possession = filter.data.possession;
			}else{
				possession = 'Upcoming';
			}
		}
		head ='';
		head += (filter.data.isReraApproved)?'Rera Registered ':'';
		head += (possession)?possession:'';
		head += ' ';
		head += (json.bhk)?json.bhk.trim():'';
		head += ' ';
		head += capitalizeFirstLetter(filter.data.cat);
		head +=(json.propertyType)?json.propertyType:'';
		head += ' Projects for sale ';
		head += (json.price)?json.price+' ':'';
		head += ' in '+snbHelper.getLocality(filter);
		head +=capitalizeFirstLetter(snbHelper.getCity(filter));
		head += Array.isArray(filter.data.poiNames) &&
			filter.data.poiNames.length > 0 ? (' near ' + filter.data.poiNames.join(', ')) : '';
	}

	if (head) {
		head = capitalizeFirstLetter(head);
	}
	return listingData.totalListings+' ' +head;
}

export const capitalizeFirstLetter = function(string) {
	if(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}
	return;
};
import { Component } from "./component";

export const EventDelegationMixin = {

    /**
     * Delegates event to the specified handler
     * 
     * @param {String} eventName the UI event name
     * @param {HTMLElement} el the element on with the event should be listened on
     * @param {Object} handlers the reference name to handler mapping
     */
    delegate(eventName, el, handlers) {
        if (!(this instanceof Component)) {
            throw new TypeError('EventDelagationMixin can only be used with a component.');
        }

        if (('string' !== typeof eventName) || !(/^[a-zA-Z]+$/.test(eventName))) {
            throw new TypeError('Invalid event name provided');
        }

        if (!(el instanceof HTMLElement)) {
            throw new TypeError('Invalid source element provided');
        }
        
        // Change the event name to all lower cases
        eventName = eventName.toLowerCase();

        if (!(`on${eventName}` in el)) {
            throw new TypeError(`The specfified event ${eventName} is not associated to element type ${el.tagName.toLowerCase()}`);
        }

        if (!(handlers instanceof Object)) {
            throw new TypeError('Handler mapping must be an Object');
        }

        // Register the event on the source element
        el.addEventListener(eventName, (event) => {
            const {target} = event;
            const refName = target.getAttribute(this.constructor.attrReference);

            if (handlers[refName] instanceof Function) {
                handlers[refName](event, {...target.dataset});
            }
        });
    }

}
import { debounce } from "../../utils/common";
import { Component, register } from "../../core";
import {addEvent} from '../../utils/gaService'

/**
 * Partial templates for this component
 */
const partials = {
    // Suggestions template
    suggestions: (suggestions = []) => suggestions.reduce((html, poi) => html + `
        <li class="show ${poi.selected ? 'selected' : ''}" title="${poi.poiName}" data-ref="suggestion" data-key="${poi.id}">
            <input type="checkbox" class="checkbox" id="poi-${poi.id}" ${poi.selected ? 'checked' : ''}>
            <label class="no-event" for="poi-${poi.id}">${poi.poiName}</label>
        </li>
    `, ''),
};

/**
 * Template for the component
 * 
 * @param {Object} state the state of this component
 */
const template = (state) => `
    <input type="text" class="m-a-input" data-ref="search" placeholder="Search Nearby" />
    <div class="suggestions-container mnhgt124">
        <ul class="suggested-wrapper" data-ref="suggestions">
            ${partials.suggestions(state.topSuggestions)}
        </ul>
    </div>
`;

/**
 * The initial state of this component
 */
const initialState = {
    cityId: null,
    callApi: true,
    suggestions: [],
    topSuggestions: [],
    selectedPois: [], 
    cityIds: []
};

/**
 * SnB "Places Of Interest" filter component
 * 
 * @extends Component
 * @author Suman Kumar Das
 */
export class POIFilterComponent extends Component {
    
    static alias = 'poi-filter';
    static template = template;
 
    constructor(el, state) {
        super(el, {...initialState, ...state});
    }

    /**
     * Lifecycle hook that is trigerred after component has loaded
     */
    onComponentLoaded() {
        // The event handlers has to be explicitly bound to `this` scope
        this.onClickSelectSuggestion = this.onClickSelectSuggestion.bind(this);
        this.onChangeLoadSuggestions = this.onChangeLoadSuggestions.bind(this);

        // Initialize the event handlers
        this.refs.search.addEventListener('input', debounce(this.onChangeLoadSuggestions, 300));
        this.refs.suggestions.addEventListener('click', this.onClickSelectSuggestion);

        // Load the top places of interest for the current city
        if (this.state.callApi) {
            this.loadTopSuggestions(this.state.cityId, this.state.cityIds);
        } else {
            this.renderSuggestions(this.state.topSuggestions);
        }
    }

    /**
     * Event handler to load POI suggestions against the query
     * 
     * @param {KeyboardEvent} event the `input` event
     */
    onChangeLoadSuggestions(event) {
        const {target} = event;
        const query = target.value.trim();

        if (query) {
            // Load the suggestions and render them
            this.loadSuggestions(query, this.state.cityId, this.state.cityIds);
            addEvent('RealEstate', 'QH_snb_' + this.state.type + '_poi_filters' , 'QH_snb_'+this.state.type+'_poi_filters_search_click');
        } else {
            // Render the top suggestions if nothing to search
            this.renderSuggestions(this.state.topSuggestions);
        }
    }

    /**
     * Event handler to that delegates `click` event on the suggestions list
     * 
     * @param {MouseEvent} event the `click` event
     * @param {Object} data the attribute dataset
     */
    onClickSelectSuggestion({target: {dataset: {value, key}}}) {

        // Check if the POI is already selected
        const selectedPois = [...this.state.selectedPois];
        const idxSelectedPoi = selectedPois.findIndex((poi) => poi.id == key);

        // Unselect if already selected
        if (idxSelectedPoi >= 0) {
            // Remove the POI from the selected list
            selectedPois.splice(idxSelectedPoi, 1);
            addEvent('RealEstate', 'QH_snb_' + this.state.type + '_poi_filters' , 'QH_snb_'+this.state.type+'_poi_filters_deselect_click');
        } else {
            // Find the selected POI id from the suggestions
            const selectedPoi = this.state.suggestions.find((poi) => poi.id == key);

            // Add the POI to the list of selected ids
            if (selectedPoi) {
                selectedPoi.selected = true;
                selectedPois.push(selectedPoi);
                addEvent('RealEstate', 'QH_snb_' + this.state.type + '_poi_filters' , 'QH_snb_'+this.state.type+'_poi_filters_select_click');
            } 
        }

        // Update the state
        this.setState({
            ...this.state,
            selectedPois: selectedPois,
            suggestions: this.mapToSelectedPois(this.state.suggestions, selectedPois)
        });

        // Render the suggestions and emit an event to notify about this selection 
        this.renderSuggestions(this.state.suggestions);
        this.emit('poi.selected', this.state.selectedPois);
    }

    /**
     * Load the top suggestions for the provided city
     * 
     * @param {number} cityId realestate city id
     */
    loadTopSuggestions(cityId, cityIds) {
        this.fetchTopSuggestions(cityId, cityIds)
            .then((topSuggestions) => this.mapToSelectedPois(topSuggestions))
            .then((topSuggestions) => {
                // Update the state
                this.setState({
                    ...this.state, 
                    topSuggestions,
                    suggestions: topSuggestions
                });
                this.emit('poi.topsuggestion', topSuggestions);
                // Render the top suggestions
                this.renderSuggestions(this.state.topSuggestions);
            });
    }

    /**
     * Loads the suggestions for the given query
     * 
     * @param {string} query the POI name query
     * @param {string} cityId realestate city id
     */
    loadSuggestions(query, cityId, cityIds) {
        this.fetchSuggestions(query, cityId, cityIds)
            .then((suggestions) => this.mapToSelectedPois(suggestions))
            .then((suggestions) => {
                // Update the state
                this.setState({...this.state, suggestions});
                
                // Render suggestions
                this.renderSuggestions(this.state.suggestions);
            });
    }

    /**
     * Fetches the top suggestions for the given city
     * 
     * @param {string} cityId realestate city id 
     */
    fetchTopSuggestions(cityId, cityIds) {
        return fetch(`/homes/re2/poi/top?cityId=${cityId}&cityIds=${cityIds.toString()}`).then((res) => res.json());
    }

    /**
     * Fetches the suggestions for the given query
     * 
     * @param {string} query the POI name query
     * @param {string} cityId realestate city id
     */
    fetchSuggestions(query, cityId, cityIds) {
        return fetch(`/homes/re2/poi/suggest?query=${query}&cityId=${cityId}&cityIds=${cityIds.toString()}`).then((res) => res.json());
    }

    renderSpinner(show = true) {
        if (show) {
            this.refs.spinner.classList.remove('hide');
        } else {
            this.refs.spinner.classList.add('hide');
        }
    }

    /**
     * Renders the places of interest partial
     * 
     * @param {Array} suggestions 
     */
    renderSuggestions(suggestions = []) {
        this.refs.suggestions.innerHTML = partials.suggestions(suggestions);
        this.refs.suggestions.addEventListener('click', this.onClickSelectSuggestion);
    }

    /**
     * Renders the selected POIs
     * 
     * @param {Array} selectedPois the selected POIs
     */
    renderSelectedPois(selectedPois = []) {
        this.refs.selection.innerHTML = partials.suggestions(selectedPois);
    }

    /**
     * Maps the pois to selected POIs
     * 
     * @param {Array} pois the places of interest
     * @param {Array} selectedPois the selected POIs
     */
    mapToSelectedPois(pois = [], selectedPois = this.state.selectedPois) {
        // Filter all the POIs 
        const topPois = pois.map((poi) => ({...poi, selected: selectedPois.findIndex((sPoi) => sPoi.id == poi.id) >= 0}));
        const restPois = selectedPois.filter((sPoi) => sPoi.poiName).filter((sPoi) => -1 === pois.findIndex((poi) => sPoi.id == poi.id));

        // Merge both the lists
        return [...restPois, ...topPois];
    }

}

// Register this component
register(POIFilterComponent);
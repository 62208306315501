import "../../assets/sass/web/modules/_market-talk-popup.scss";
import { addEvent } from "../utils/gaService";

const MARKET_TALK_POPUP_CONFIG = {
  sessionShowCount: 3,
  sessionKey: "marketTalkPopupManager",
  enabled: true,
  redirectUrl: window.location.protocol + "//www.quikr.com/genie?source=RE_SNB",
};

const fireGa = (label) => {
  addEvent("RealEstate", globals.pageType, label);
};

const controller = {
  init: () => {
    let session = controller.getStorage();
    if (!session) {
      session = {
        home: 1
      };
      controller.setStorage(session);
    }

    const mktTlkPopup = document.getElementById("mktTlkPopup");

    if (!mktTlkPopup || !controller.shouldShowBanner()) {
      return;
    }

    const btnMktTlkCloseEl = document.querySelector(
      "#mktTlkPopup #btnMktTlkClose"
    );

    btnMktTlkCloseEl.addEventListener("click", controller.handleCloseClick);

    const modalContentClickEl = document.querySelector(
      "#mktTlkPopup .modal-content"
    );

    if (modalContentClickEl) {
      modalContentClickEl.addEventListener("click", controller.handleClick);
    }

    $("#mktTlkPopup").on("hidden.bs.modal", controller.onClose);

    controller.handleOpen();
  },
  setStorage: (session = null) => {
    if (session)
      sessionStorage.setItem(
        MARKET_TALK_POPUP_CONFIG.sessionKey,
        JSON.stringify(session)
      );
  },
  getStorage: () => {
    let session = sessionStorage.getItem(MARKET_TALK_POPUP_CONFIG.sessionKey);
    session = session ? JSON.parse(session) : null;
    return session;
  },
  incrementSessionCount: (source) => {
    let session = controller.getStorage();

    if (session && "undefined" !== typeof session[source]) {
      session[source] = ++session[source];
    } else if (session) {
      session[source] = 1;
    }
    controller.setStorage(session);
  },
  shouldShowBanner: (source = "home") => {
    let session = controller.getStorage();
    if (session && "undefined" !== typeof session[source]) {
      return session[source] % MARKET_TALK_POPUP_CONFIG.sessionShowCount === 0;
    }
  },
  isEnabled: () => {
    return MARKET_TALK_POPUP_CONFIG.enabled;
  },
  onClose: (e) => {
    e.stopPropagation();
    e.preventDefault();
    fireGa(`QH_marketTalkPopup_${globals.pageType}_close`);
  },
  handleOpen: () => {
    fireGa(`QH_marketTalkPopup_${globals.pageType}_show`);
    $("#mktTlkPopup").modal("show");
  },
  handleCloseClick: (event) => {
    controller.onClose(event);
    $("#mktTlkPopup").modal("hide");
  },
  handleClick: () => {
    fireGa(`QH_marketTalkPopup_${globals.pageType}_click`);
    window.location.href = MARKET_TALK_POPUP_CONFIG.redirectUrl;
  },
};

export default controller;

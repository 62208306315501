
export const addEvent = function (cat, action, label) {
    if (typeof gtag !== "undefined") {
        let config = {
            'event_category': cat,
            'event_label': label.replace(/\s|&/g, '')
        }
        if(LOAD_CLSFD_PAP) {
            config.qh_postad_new = 3
        }
        gtag('event', action, config);
    }
};
export const addPageView = function (pgTitle, category, label = null, tag = null, page = null) {
    if (typeof gtag !== "undefined") {
        let config = {
            page_title: pgTitle,
            event_category: category,
            event_label: label,
            event_tag: tag,
            page
        }
        if(LOAD_CLSFD_PAP) {
            config.qh_postad_new = 3
        }
        gtag('event', 'page_view', config);
    }
};

window.addEventListener("DOMContentLoaded", () => {

    let elements = document.querySelectorAll('[ga-directive]');
    
    elements.forEach((element) => {
        let gaOptions = eval('(' + element.getAttribute('ga-options') + ')');
        switch (element['nodeName'].toLowerCase()) {
            case 'button':
            case 'li':
            case 'span':
            case 'a':
            case 'b':
            case 'small':
            case 'textsarea':
            case 'div':
                element.addEventListener('click',() =>{
                    if ("undefined" != typeof gtag) {
                        gtag('event', gaOptions.action, {
                            'event_category': gaOptions.category,
                            'event_label': gaOptions.label
                        });
                    }
                });
                break;

            case 'input':
                element.addEventListener('click',() =>{
                    if (element['checked']) {
                        if ("undefined" != typeof gtag) {
                            gtag('event', gaOptions.action, {
                                'event_category': gaOptions.category,
                                'event_label': gaOptions.label
                            });
                        }
                    }
                    else
                    {
                        if ("undefined" != typeof gtag) {
                            gtag('event', gaOptions.action, {
                                'event_category': gaOptions.category,
                                'event_label': gaOptions.label
                            });
                        }

                    }
                });
                break;
        }
    })
    
})

import { _vapTplRenderPromiss, source } from './../utils/common';
import { getterSetter, isSet, debounce } from './../utils/common'
import { getLoggedInUser, login } from '../loginService';
import { getCookie, setCookieWithExpirationHours } from './../services/cookieService';
import { otpService } from './otpService';

require('./gaService.js')

export var submitLeadFn = (submitLeadFn) => {
    qhmrSnbPopUp.submit();
}


var qhmrSnbPopUp = {
    document: document,
    loginStatus: false,
    loginData: null,
    mobile: '',
    email: '',
    emailList: [],
    mobileList: [],
    errorMessage: '',
    cityName: getCookie('new_prefer_city') && (getCookie('new_prefer_city').charAt(0).toUpperCase() + getCookie('new_prefer_city').slice(1)),
    gender: 'male',
    budget: '0k-5k',
    locality: '',
    movinoption: '',
    otpVerified: false,
    templ: _.template(''),
    invalidPayload: true
};

qhmrSnbPopUp.validate = () => {
    if (qhmrSnbPopUp.cityName && qhmrSnbPopUp.locality != "" && document.getElementById('qhmrSnbPopUp_mobile').value && qhmrSnbPopUp.movinoption != "" && document.getElementById('qhmrSnbPopUp_email').value && qhmrSnbPopUp.otpVerified) {
        $('#submitSnbLead').removeAttr("disabled");
        document.getElementById('fieldValidateError').classList.add('hide');
        qhmrSnbPopUp.invalidPayload = true;
    }
}


qhmrSnbPopUp.init = function () {
    let qhmrSnbPopUpDom = document.getElementById('qhmrSnbPopUp');
    otpService.setOtpVariable('', 'otpId');
    _vapTplRenderPromiss('qhmr-snb-pop-up').then(function (template) {
        if (getterSetter.get('loginstatus')) {
            const loggedData = getterSetter.get('loginData');
            qhmrSnbPopUp.loginStatus = true;
            qhmrSnbPopUp.loginData = loggedData;
            qhmrSnbPopUp.otpVerified = true;
            qhmrSnbPopUp.emailList = (loggedData.data.user.userEmail && loggedData.data.user.userEmail.length > 0) ? loggedData.data.user.userEmail : [];
            qhmrSnbPopUp.mobileList = (loggedData.data.user.userMobile && loggedData.data.user.userMobile.length > 0) ? loggedData.data.user.userMobile : [];
            qhmrSnbPopUp.email = (loggedData.data.user.userEmail && loggedData.data.user.userEmail.length > 0) ? qhmrSnbPopUp.emailList[0].emailId : '';
            qhmrSnbPopUp.mobile = (loggedData.data.user.userMobile && loggedData.data.user.userMobile.length > 0) ? qhmrSnbPopUp.mobileList[0].mobileNumber : '';
        } else {
            qhmrSnbPopUp.loginStatus = false;
            qhmrSnbPopUp.loginData = null;
            qhmrSnbPopUp.emailList = [];
            qhmrSnbPopUp.mobileList = [];
        }
        let tp = _.template(template);
        qhmrSnbPopUp.templ = tp;
        document.getElementById('autoSnbPopUp').innerHTML = tp({ 'qhmrSnbPopUp': qhmrSnbPopUp });
        qhmrSnbPopUpDom.click();
        sessionStorage.setItem('snbPopUp', true)
        document.getElementById('snbPopUpCitySelected').innerHTML = getCookie('new_prefer_city');
    });

}

qhmrSnbPopUp.qhmrSnbPopUpChangeMobile = function (ev) {
    document.getElementById('qhmrSnbPopUpSelectedMobileNo').value = ev.target.dataset.mobile;
    document.getElementById('qhmrSnbPopUp_mobile').value = ev.target.dataset.mobile;
    qhmrSnbPopUp.mobile = document.getElementById('qhmrSnbPopUp_mobile').value;
    qhmrSnbPopUp.validate();
}

qhmrSnbPopUp.qhmrSnbPopUpChangeEmail = function (ev) {
    document.getElementById('qhmrSnbPopUpSelectedEmailId').value = ev.target.dataset.email;
    document.getElementById('qhmrSnbPopUp_email').value = ev.target.dataset.email;
    qhmrSnbPopUp.email = document.getElementById('qhmrSnbPopUp_email').value
    qhmrSnbPopUp.validate();
}

qhmrSnbPopUp.selectAnotherCity = function (ev) {
    document.getElementById('snbPopUpCitySelected').innerHTML = ev.target.dataset.city;
    qhmrSnbPopUp.cityName = ev.target.dataset.city;
    document.getElementById('snbPopUpLocalitySelect').value = '';
    document.getElementById('localityDiv').classList.add('error');
    qhmrSnbPopUp.locality = '';
    qhmrSnbPopUp.validate();

}

qhmrSnbPopUp.mobileHandler = function (ev) {
    if (document.getElementById('qhmrSnbPopUp_mobile') != qhmrSnbPopUp.mobile) {
        $('#otpField').add("disabled", true);
        document.getElementById('otpAlert').innerHTML = '';
        document.getElementById('sendOtp').innerHTML = "Send OTP";
    }
    if (ev.target.value.length === 10) {
        document.getElementById('sendOtp').classList.remove('hide')
    }
    qhmrSnbPopUp.mobile = ev.target.value;
    qhmrSnbPopUp.validate();
}

qhmrSnbPopUp.emailHandler = function (ev) {
    qhmrSnbPopUp.mobile = ev.target.value;
    qhmrSnbPopUp.validate();
}

qhmrSnbPopUp.selectLocality = function (ev) {
    if (ev.target.value.length > 3) {
        debounce(qhmrSnbPopUp.getLocalites(qhmrSnbPopUp.cityName.toLowerCase(), ev.target.value), 500)
    }
}

qhmrSnbPopUp.selectBudget = function (ev) {
    let listElms = document.getElementsByClassName('js-select-range');
    Array.from(listElms).forEach(function (elm) {
        elm.classList.remove('active');
    });
    this.classList.add('active');
    qhmrSnbPopUp.budget = ev.target.dataset.budget;
}

qhmrSnbPopUp.selectGender = function (ev) {
    let listElms = document.getElementsByClassName('js-select-gender');
    Array.from(listElms).forEach(function (elm) {
        elm.classList.remove('active');
    });

    this.classList.add('active');
    qhmrSnbPopUp.gender = ev.target.dataset.gender;
}

qhmrSnbPopUp.selectDropDownLocality = function (ev) {
    document.getElementById('snbPopUpLocalitySelect').value = ev.target.dataset.locality;
    document.getElementById('localityDiv').classList.remove('error');
    document.getElementById('localityDiv').classList.add('open_dropdown');
    qhmrSnbPopUp.locality = ev.target.dataset.locality;
    qhmrSnbPopUp.validate();
}

qhmrSnbPopUp.selectMoveInOption = function (ev) {
    document.getElementById('snbPopUpMovInOptionSelected').innerHTML = ev.target.dataset.movinoption;
    qhmrSnbPopUp.movinoption = ev.target.dataset.movinoption;
    qhmrSnbPopUp.validate();
}

qhmrSnbPopUp.generateOtp = function (ev) {
    if (document.getElementById('sendOtp').innerHTML === 'Send OTP') {

        var payload = {
            "email": document.getElementById('qhmrSnbPopUp_email').value,
            "mobile": document.getElementById('qhmrSnbPopUp_mobile').value,
            "firstName": '',
            "source": source(),
            "page": 'REPLY'
        }
        otpService.generateOTP(payload).then(function (result) {
            otpService.setOtpVariable(result.clientId, 'otpClientId');
            result = JSON.parse(result.response);
            document.getElementById('otpAlert').innerHTML = 'OTP sent successfully to ' + document.getElementById('qhmrSnbPopUp_mobile').value + '.';
            document.getElementById('sendOtp').innerHTML = "Resend OTP";
            $("#otpDiv").addClass('open');
            $('#otpField').removeAttr("disabled");
            if (result && result.GenerateOTPApplicationResponse && result.GenerateOTPApplicationResponse.GenerateOTPApplication) {
                let OtpId = result.GenerateOTPApplicationResponse.GenerateOTPApplication.otpId;
                otpService.setOtpVariable(OtpId, 'otpId');
            }
        });
    } else {
        var resendPayload = {
            "otpId": otpService.getOtpVariable().otpId,
            "otpClientId": otpService.getOtpVariable().otpClientId ? otpService.getOtpVariable().otpClientId : '',
            "source": source(),
            "page": "REPLY"
        }
        let alertbox = document.querySelector("#whatsAppFormChange #alertmsgbox");
        otpService.resendOTP(resendPayload).then(function (result) {
            $(".otp_invalid").addClass('hide');
            document.getElementById('otpField').value = '';
            document.getElementById('otpAlert').innerHTML = 'OTP sent successfully to ' + document.getElementById('qhmrSnbPopUp_mobile').value + '.';
            if (result && result.ResendOTPApplicationResponse && result.ResendOTPApplicationResponse.ResendOTPApplication) {
            }
        });
    }
}

qhmrSnbPopUp.verifyOtp = function (ev) {
    if (ev.target.value.length === 4) {
        var otpData = otpService.getOtpVariable();
        var payload = {
            "GreetingName": document.getElementById('qhmrSnbPopUp_mobile').value,
            "password": '',
            "otpRequest": 1,
            "otpNumber": document.getElementById('otpField').value,
            "otpId": otpData.otpId,
            "page": 'REPLY',
            "source": source()
        };
        login(payload).then((result) => {
            if (result.error == false && result.status == 'AUTH_SUCCESS') {
                document.getElementById('otpAlert').innerHTML = "OTP verified."
                document.getElementById('otpField').value = "XXXX"
                $('#qhmrSnbPopUp_mobile').attr("disabled", true);
                $('#sendOtp').addClass('hide');
                $('#otpError').addClass('hide');
                $("#otpDiv").addClass('open');
                $('#otpField').attr("disabled", true);
                $('#otpSuccess').addClass('hide_otp');
                qhmrSnbPopUp.otpVerified = true;
                qhmrSnbPopUp.validate();
                if (result && result.data && result.data.user) {
                    getterSetter.set('loginData', result);
                    getterSetter.set('userId', result.data.user.id);
                    getterSetter.set('loginstatus', true);
                    getterSetter.set('otpFlag', true);
                } else {
                    getterSetter.set('loginstatus', false);
                }
                if ('undefined' !== typeof PUB_SUB) {
                    PUB_SUB.emit('do_login', { name: getterSetter.get('name') });
                }
            } else {
                $('#otpError').removeClass('hide');
            }
        });
    };
}


qhmrSnbPopUp.getLocalites = function (city, query) {
    let data = {
        'city': city,
        'q': query
    }
    $.ajax({
        url: "/homes/re2/qhmrGetLocalities",
        method: "POST",
        data: data,
        success: function (data) {
            let str = '';
            data.data && data.data.forEach((el) => {
                str += `<li data-locality="${el.locality}" >${el.locality}</li>`;
            });
            snbPopUpLocality.innerHTML = str;
        }
    });
}

qhmrSnbPopUp.getLoggedInData = function () {
    getLoggedInUser.then(response => {
        let details = response;
        if (details && details.data && details.data.user) {
            if (!details.error) {
                qhmrSnbPopUp.loginStatus = true;
                qhmrSnbPopUp.otpVerified = true;
                getterSetter.set('loginstatus', true);
                getterSetter.set('otpFlag', true);
            } else {
                qhmrSnbPopUp.loginStatus = false;
            }
        } else {
            qhmrSnbPopUp.loginStatus = false;
        }
    }).catch(() => {
        qhmrSnbPopUp.loginStatus = false;
    });
}

qhmrSnbPopUp.submit = function () {
    if (!qhmrSnbPopUp.invalidPayload) {
        return false;
    } else {
        const requirement = {
            locality: qhmrSnbPopUp.locality,
            budget: qhmrSnbPopUp.budget,
            relocate: qhmrSnbPopUp.movinoption,
            source: qhmrSnbPopUp.pageType = "VAP" ? 'QH_PG_VAP' : 'QH_PG_SNB',
            gender: qhmrSnbPopUp.gender,
            city: qhmrSnbPopUp.cityName
        };
        const loggedInData = getterSetter.get('loginData');
        const data = {
            "quikrUserId": loggedInData ? loggedInData.data.user.id : null,
            "email": document.getElementById("qhmrSnbPopUp_email").value,
            "mobile": document.getElementById("qhmrSnbPopUp_mobile").value,
            ...requirement
        }
        $.ajax({
            url: "/homes/re2/qhmrSnbLead",
            method: "POST",
            data: data,
            success: function (data) {
                if (data.success) {
                    document.getElementById('qhmrSnbModalHeader').innerHTML = '';
                    document.getElementById('qhmrSnbModalHeaderP').innerHTML = '';
                    document.getElementById('popUpBody').innerHTML = '';
                    document.getElementById('thankYouPopUp').classList.remove('hide');
                }
            }
        });
        const eventType = qhmrSnbPopUp.pageType = "VAP" ? 'QH_PG_VAP' : 'QH_PG_SnB';
        const eventName = qhmrSnbPopUp.pageType = "VAP" ? 'QH_PG_VAP_leadForm_enrichmentForm_getInTouch_click' : 'QH_PG_SnB_leadForm_enrichmentForm_getInTouch_click';
        gaService.addEvent('QH_PG',eventType,eventName);
        return false;
    }
}

export const popup = function () {
    qhmrSnbPopUp.getLoggedInData();
    qhmrSnbPopUp.pageType = globals.pageType;
    qhmrSnbPopUp.init();
    $(document).on('click', '#snbPopUpCityName li', qhmrSnbPopUp.selectAnotherCity);
    $(document).on('keyup', '#snbPopUpLocalitySelect', qhmrSnbPopUp.selectLocality);
    $(document).on('keyup', '#otpField', qhmrSnbPopUp.verifyOtp);
    $(document).on('keyup', '#qhmrSnbPopUp_mobile', qhmrSnbPopUp.mobileHandler);
    $(document).on('keyup', '#qhmrSnbPopUp_email', qhmrSnbPopUp.emailHandler);
    $(document).on('click', '#snbPopUpLocality li', qhmrSnbPopUp.selectDropDownLocality);
    $(document).on('click', '#qhmrSnbPopUpSelectMobile li', qhmrSnbPopUp.qhmrSnbPopUpChangeMobile);
    $(document).on('click', '#qhmrSnbPopUpSelectEmail li', qhmrSnbPopUp.qhmrSnbPopUpChangeEmail);
    $(document).on('click', '#sendOtp', qhmrSnbPopUp.generateOtp);
    $(document).on('click', '#budgetSelect li', qhmrSnbPopUp.selectBudget)
    $(document).on('click', '#genderSelect li', qhmrSnbPopUp.selectGender)
    $(document).on('click', '#selectMovInOption li', qhmrSnbPopUp.selectMoveInOption)
}

import lozad from 'lozad';
import AdImpressions from '@quikr/snbimpressions';
import { isMobile } from '../utils/common';
import { getCookie } from '../services/cookieService';

const config = {
    domain: 'Quikr',
    isMobile: isMobile(),
    jkId: getCookie('_jk_id'),
    referer: location.href,
    browserAgent: navigator.userAgent
};

const manager = AdImpressions(config);

const getData = el => {
    const adObject = el.dataset['adObject'];
    return adObject && JSON.parse(atob(adObject));
};

const initObserver = className => {
    const el = document.querySelectorAll(className);

    if (!el.length) return;

    const observer = lozad(el, {
      threshold: 1,
      load: el => {
        const data = getData(el);
        data && manager.addImpression(data);
      }
    });
    observer.observe();
};

export default {
    add: ad => manager.addImpression(ad),
    publish: () => manager.publishToAnalytics(),
    getData,
    initObserver
};

var snbData = {};
var GET_AUTO_SUGGESTED_LOCALITY_SEARCH_API = '/homes/re2/locality_auto_suggest';
export const getListings = function (obj) {
	var deferred = $.Deferred();
	$.ajax({
		url: "/homes/adsdata",
		method: 'POST',
		data: {params: obj},
		success: function (data) {
			deferred.resolve(data);
		},
		error: function (err) {
			deferred.reject(err);
		}
	});
	return deferred.promise();

}

export const getProjects = function (obj) {
	var deferred = $.Deferred();
	$.ajax({
		url: "/homes/projectsdata",
		method: 'POST',
		data: {params: obj},
		success: function (data) {
			deferred.resolve(data);
		},
		error: function (err) {
			deferred.reject(err);
		}
	});
	return deferred.promise();

}

export const pushPremiumAdEvent = (premiumObj)=>{
	var deferred = $.Deferred();
	$.ajax({
		url: '/homes/re2/pushPremiumAdEvents?aj=1',
		method: 'POST',
		data: {'premiumObj': premiumObj},
		success: function (data) {
			deferred.resolve(data);
		},
		error: function (err) {
			deferred.reject(err);
		}
	});
	return deferred.promise();
};

export const getLocalityAutoSuggest = function(params){
	var deferred = $.Deferred();
	$.ajax({
		url: GET_AUTO_SUGGESTED_LOCALITY_SEARCH_API + '?aj=1',
		method: 'POST',
		data: params,
		success: function (data) {
			deferred.resolve(data);
		},
		error: function (err) {
			deferred.reject(err);
		}
	});
	return deferred.promise();
}

export const trackPremiumAds = function(){
    var premAds = [];
    var ads  = JSON.parse(atob(listingData.data));
    _.each(ads, function (ad) {
        if(ad.adStyle != 'B'){
            var impressionObj = getPremiumAdImpressionObj(ad);
            premAds.push(impressionObj);
        }
    });
    pushPremiumAdEvent(premAds);
};

function getPremiumAdImpressionObj(ad){
    var source = "Desktop";
    if(window.innerWidth < 768) {
        source = "Msite";
    }
    var data = {"adId":ad.id,"adStyle":ad.adStyle,"categoryId":ad.type,"cityId":snbFilterController.filter.data.c_id,"titleId":"","source":source,"pageType":"SNB"};
    var obj = {"event":"PremiumAdImpressionLogs","data":data};
    return obj;
}

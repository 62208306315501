import { EventDelegationMixin } from "./event-mixins";
import { EventEmitter } from "./event-emitter";
import { toCamelCase } from "../utils/common";

export function renderTemplate(template, el, state = {}) {
    if ('string' === typeof template) {
        // Load the template
        el.innerHTML = template;
    } else if (template instanceof Function) {
        // Resolve the template
        el.innerHTML = template.call(el, state);
    }
}

/**
 * Base class that has to be implemented by a component
 *
 * @abstract
 * @author Suman Kumar Das
 */
export class Component extends EventEmitter {

    /**
     * The component alias name
     */
    static alias = null;

    /**
     * The template for this component
     */
    static template = null;

    /**
     * The attribute to use for components
     */
    static attrComponent = 'data-component';

    /**
     * The attribute to use for references
     */
    static attrRefs = 'data-ref';

    /**
     * The mixins to be used in this component
     */
    static mixins = [EventDelegationMixin];

    /**
     * Creates a new component with the root element and an initial state
     * 
     * @param {HTMLElement | String} el the root element
     * @param {Object} state the component initial state
     * @param {Boolean} [render] should the component render immediately
     */
    constructor(el, state = {}, render = true) {
        super();

        /**
         * The root element for this component
         * 
         * Note: Use `select` and `selectAll` to query for an element using refs or CSS selectors. This keeps
         * the scope to the root element.
         * 
         * @type {HTMLElement}
         */
        this.root = null;

        /**
         * Element references that are resolved after a component is rendered
         * 
         * Note: The references are resolved using the attribute name provided in `attrRefs`, default is `data-attr`
         * 
         * @type {Object}
         */
        this.refs = {};

        /**
         * The current state of the component
         * 
         * Note: Use `setState` on component to take advantage of data binding features coming up
         * 
         * @type {Object}
         */
        this.state = state;

        // Component class should be inherited and not directly instantiated
        if (this.constructor === Component) {
            throw new Error("Can't instantiate abstract class!");
        }

        // Resolve the component mount point
        if (el) {
            if (el instanceof HTMLElement) {
                this.root = el; 
            } else if ('string' === typeof el) {
                this.root = document.querySelector(el);
            } else {
                throw new Error(`No root element or selector provided for component ${this.constructor.alias}`);
            }
        } else {
            // Try finding a component with the alias name
            this.root = document.querySelector(`[${this.constructor.attrComponent}="${this.constructor.alias}"]`);
        }

        // Check if a mount point is available
        if (!this.root) {
            throw new Error(`No root element provided for component ${this.constructor.alias}`);
        }

        // Render the component once initialized
        if (render) {
            this.render();
        }
    }

    /**
     * Renders the component inside the root element
     */
    render() {
        // Render the component template
        renderTemplate(this.constructor.template, this.root, this.state);

        // Resolve the references
        this.resolveRefs();

        // Trigger the component loaded hook
        this.onComponentLoaded();
    }

    /**
     * Updates the component state, updating the state invokes the onStateChange
     * lifecycle hook
     * 
     * @param {Object} state the updated state
     */
    setState(state) {
        this.state = state;
        this.onStateChange();
    }

    /**
     * Lifecycle hook that is triggered after the component is mounted 
     */
    onComponentLoaded() {
        // To be implemented by sub classes
    }

    /**
     * Lifecycle hook that is triggered before the component is unmounted
     */
    onComponentDestroy() {
        // To be implemented by sub classes
    }

    /**
     * Lifecycle hook that is triggered whenever the state is updated
     */
    onStateChange() {
        // To be implemented by sub classes
    }

    /**
     * Check if the element has a reference by the reference name
     * 
     * @param {HTMLElement} el the element reference
     * @param {String} ref the reference name
     */
    hasRef(el, ref) {
        return el && this.select(ref);
    }

    /**
     * Get the child element with the reference name
     * 
     * @param {String} ref the reference name
     * @param {String} isSelector is the reference name a CSS selector
     */
    select(ref, isSelector = false) {
        // Resolve the CSS selector name if it is a reference
        const selector = isSelector ? ref : `[${this.constructor.attrRefs}="${ref}"]`;
        return ref && 'string' === typeof ref ? this.root.querySelector(selector) : null;
    }

    /**
     * Get all the children with the reference name
     * 
     * @param {String} ref the reference name
     * @param {String} isSelector is the reference name a CSS selector
     */
    selectAll(ref, isSelector = false) {
        // Resolve the CSS selector name if it is a reference
        const selector = isSelector ? ref : `[${this.constructor.attrRefs}="${ref}"]`;
        return ref && 'string' === typeof ref ? [...this.root.querySelectorAll(selector)] : [];
    }

    /**
     * Resolves the references
     */
    resolveRefs() {
        this.selectAll(`[${this.constructor.attrRefs}]`, true)
            .filter((el) => {
                // Filter the valid reference names
                const refName = el.getAttribute(this.constructor.attrRefs);
                return refName && refName.trim() && /^([a-zA-Z0-9]+)([-|_]([a-zA-Z0-9]+))*$/.test(refName);
            })
            .forEach((el) => {
                const refName = toCamelCase(el.getAttribute(this.constructor.attrRefs));
                this.refs[refName] = el;
            });
    }

}

/**
 * Registers a component
 * 
 * @param {Component} type the component class
 * @param {Object} options the options for registering this component 
 */
export function register(type, options = {}) {
    if (!(type instanceof Function) || !(type.prototype instanceof Component)) {
        throw new TypeError(`Component cannot be of type ${type}. It must extend the Component base class.`);
    }

    // Register the mixins
    if (type.mixins instanceof Array) {
        type.mixins.forEach((mixin) => {
            Object.assign(type.prototype, mixin);
        });
    }
}